import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import styles from './CancelPlanModal.module.scss';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import CancelIcon from '../../assets/cancel-icon.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    width: 600,
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '30px',
    position: 'relative'
  }
};

class CancelPlanModal extends PureComponent {
  render() {
    const { toggleModal, isOpen, error, isLoading, cancelSubscription, lastDate } = this.props;
    return (
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Alert" ariaHideApp={false}>
        <div className={styles.container}>
          <span className={styles.closeBtn} onClick={() => toggleModal()} aria-hidden="true">
            ×
          </span>
          <div className={styles.content}>
            <div className={styles.contentWrapper}>
              <div className={styles.imgContain}>
                <img src={CancelIcon} className={styles.icon} />
              </div>
              <p className={styles.headerText}>Review of Cancelation</p>
              <p className={styles.descriptionText}>
                By pressing this button your plan will be canceled.
              </p>
              <br />
              <p className={styles.descriptionText}>
                You can continue using our service until the end of your current billing period on{' '}
                {moment.unix(lastDate).format('MMMM D, YYYY')}.
              </p>

              <div className={styles.btnContainer}>
                <Button
                  onClick={() => cancelSubscription()}
                  disabled={isLoading}
                  title="Confirm Cancellation Of Plan"
                  color="blue "
                />
              </div>
              {error && <ErrorBox error={error} />}
              {/* <div className={styles.btnContainer}>
                <a href="#/" onClick={() => toggleModal()}>
                  Do Not Cancel
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

CancelPlanModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  toggleModal: PropTypes.func,
  cancelSubscription: PropTypes.func,
  error: PropTypes.any,
  lastDate: PropTypes.number,
  isBooster: PropTypes.bool
};

export default CancelPlanModal;

import moment from 'moment-timezone';
import firebase from '../../config/firebase-config';
import store from '../Store';
import * as ActionTypes from '../ActionTypes';
import API_URL from '../../config/Api';
import Account from './Account';

let authStateSubscriber = null;
let fetchingUser = false;

const signOut = async () => {
  try {
    await firebase.auth().signOut();
    localStorage.removeItem('start_date');
    localStorage.removeItem('end_date');
    localStorage.removeItem('duration');
    localStorage.removeItem('active_domain');
    window.Intercom('shutdown');
    console.log('Sign out success');
    store.dispatch({ type: ActionTypes.RESET_ACCOUNTS });
    store.dispatch({ type: ActionTypes.RESET_BLOCKLIST });
    store.dispatch({ type: ActionTypes.RESET_DOMAIN_ACTIVE });
  } catch (error) {
    console.log(error);
  }
};

const getUser = loggedInUser => {
  return async dispatch => {
    if (!fetchingUser) {
      fetchingUser = true;
      dispatch({ type: ActionTypes.FETCHING_USER });
      try {
        const settings = {
          method: 'GET',
          headers: {}
        };
        const idToken = await firebase.auth().currentUser.getIdToken(false);
        settings.headers.token = idToken;
        const response = await fetch(`${API_URL}/user/${loggedInUser.id}`, settings);
        if (response.ok) {
          const responseJson = await response.json();
          console.log(responseJson);
          moment.tz.setDefault(
            responseJson.timezone
              ? responseJson.timezone.substr(responseJson.timezone.indexOf(') ') + 2)
              : 'America/Los_Angeles'
          );
          console.log(moment().format());
          if (responseJson.deleted) {
            dispatch({ type: ActionTypes.SET_USER_DELETED, payload: true });
            await signOut();
            return;
          }
          fetchingUser = false;
          // Fetch User Accounts
          dispatch(Account.getUserAccounts(responseJson.account_id));
          dispatch({ type: ActionTypes.FETCHING_USER_SUCCESS, payload: responseJson });
        }
      } catch (error) {
        fetchingUser = false;
        console.log('Fetch User Error: ', error);
        dispatch({ type: ActionTypes.FETCHING_USER_FAIL, payload: error });
        store.dispatch({
          type: ActionTypes.FETCHING_ACCOUNTS_FAIL,
          payload: error
        });
        store.dispatch({
          type: ActionTypes.FETCHING_ACCOUNTS_SUBSCRIPTION_FAIL,
          payload: error
        });
      }
    }
  };
};

const startAuthListener = () => {
  if (!authStateSubscriber) {
    store.dispatch({ type: ActionTypes.AUTHENTICATING });
    authStateSubscriber = firebase.auth().onAuthStateChanged(
      user => {
        if (user) {
          window.Intercom('update', {
            email: user.email.toLowerCase(),
            user_id: user.uid
          });
          const loggedInUser = {
            id: user.uid,
            email: user.email.toLowerCase()
          };
          // Fetch User from DB here
          console.log('User: ', loggedInUser);
          store.dispatch(getUser(loggedInUser));
        } else {
          store.dispatch({
            type: ActionTypes.AUTHENTICATION_FAIL,
            payload: 'No User Authed'
          });
          store.dispatch({
            type: ActionTypes.FETCHING_ACCOUNTS_FAIL,
            payload: 'No User Authed'
          });
          store.dispatch({
            type: ActionTypes.FETCHING_ACCOUNTS_SUBSCRIPTION_FAIL,
            payload: 'No User Authed'
          });
        }
      },
      error => {
        console.log('Error Authenticating: ', error);
        store.dispatch({ type: ActionTypes.AUTHENTICATION_FAIL, payload: error.message });
        store.dispatch({
          type: ActionTypes.FETCHING_ACCOUNTS_FAIL,
          payload: error.message
        });
        store.dispatch({
          type: ActionTypes.FETCHING_ACCOUNTS_SUBSCRIPTION_FAIL,
          payload: error.message
        });
      }
    );
  }
};

const stopAuthListener = () => {
  if (authStateSubscriber) {
    console.log('Unsubscribing from Auth State');
    authStateSubscriber();
  }
};

const createUser = userData => {
  return async dispatch => {
    console.log('Creating User -> ', userData);

    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    };

    try {
      const response = await fetch(`${API_URL}/user`, settings);
      const responseJson = await response.json();
      console.log(responseJson);
      // User redux reducer - store responseJson as user state
      if (response.status === 200) {
        dispatch({ type: ActionTypes.CREATING_USER_SUCCESS, payload: responseJson });
        window.Intercom('update', {
          email: userData.email.toLowerCase(),
          user_id: responseJson.id,
          business_domain: userData.domain,
          notif_discount_and_promotions: true,
          notif_real_time_alerts: true,
          notif_company_announcements: true,
          notif_fraud_industry_news: true
        });
        window.Intercom('trackEvent', 'register-step-1', {
          domain: userData.domain
        });
        return responseJson;
      }

      dispatch({ type: ActionTypes.CREATING_USER_FAIL, payload: responseJson.message });
      throw new Error(responseJson.message);
    } catch (error) {
      console.error(error);
      dispatch({ type: ActionTypes.CREATING_USER_FAIL, payload: error.message });
      throw error;
    }
  };
};

const updateUser = (id, data) => {
  console.log('UPDATING USER WITH: ', data);
  const settings = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  return async dispatch => {
    dispatch({ type: ActionTypes.UPDATING_USER });
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(false);
      settings.headers.token = idToken;

      const response = await fetch(`${API_URL}/user/${id}`, settings);
      const responseJson = await response.json();
      console.log(responseJson);
      if (response.status === 200) {
        dispatch({ type: ActionTypes.UPDATE_USER_SUCCESS, payload: responseJson });
      }
      return responseJson;
    } catch (error) {
      console.error(error);
      dispatch({ type: ActionTypes.UPDATE_USER_FAIL, payload: error });
      throw error;
    }
  };
};

const completeAppSumoUser = (userData, planId) => {
  return async dispatch => {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    };

    try {
      const response = await fetch(`${API_URL}/appsumo/user-registration`, settings);
      const responseJson = await response.json();
      console.log(responseJson);
      // User redux reducer - store responseJson as user state
      if (response.status === 200) {
        dispatch({ type: ActionTypes.CREATING_USER_SUCCESS, payload: responseJson });
        window.Intercom('update', {
          email: userData.email,
          user_id: responseJson.id,
          business_domain: userData.domain,
          notif_discount_and_promotions: true,
          notif_real_time_alerts: true,
          notif_company_announcements: true,
          notif_fraud_industry_news: true,
          appsumo: true
        });
        window.Intercom('trackEvent', 'appsumo-register', {
          domain: userData.domain,
          appsumo: true,
          email: userData.email,
          user_id: responseJson.id,
          plan: planId
        });
        await dispatch(Account.getUserAccounts(responseJson.account_id));
        return responseJson;
      }

      dispatch({ type: ActionTypes.CREATING_USER_FAIL, payload: responseJson.message });
      throw new Error(responseJson.message);
    } catch (error) {
      console.error(error);
      dispatch({ type: ActionTypes.CREATING_USER_FAIL, payload: error.message });
      throw error;
    }
  };
};

const createUserWithEmailAndPassword = async (email, password) => {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email.toLowerCase(), password);
    if (result) {
      console.log(result);
      return result;
    }
    throw new Error('Result is undefined');
  } catch (error) {
    console.log('Error creating user: ', error);
    throw error;
  }
};

const signInWithEmailAndPassword = async (email, password) => {
  try {
    const result = await firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), password);
    if (result) {
      console.log(result);
      return result;
    }
    throw new Error('Result is undefined');
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateEmailFirebase = async newEmail => {
  try {
    await firebase.auth().currentUser.updateEmail(newEmail.toLowerCase());
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const checkCurrentPassword = async (password, email) => {
  try {
    const credential = firebase.auth.EmailAuthProvider.credential(email.toLowerCase(), password);
    const res = await firebase
      .auth()
      .currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updatePasswordFirebase = async password => {
  try {
    await firebase.auth().currentUser.updatePassword(password);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createLead = data => {
  const settings = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  fetch(`${API_URL}/leads`, settings);
};

const uploadPhoto = async (id, file) => {
  try {
    const imageRef = firebase
      .storage()
      .ref()
      .child(`/user/${id}/`);
    const name = 'profile_picture';
    const uploadTask = await imageRef.child(name).put(file);
    console.log(uploadTask);
    const downloadUrl = `https://storage.googleapis.com/fraud-blocker.appspot.com/user/${id}/profile_picture`;
    return downloadUrl;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const verifyPasswordResetCode = async oobCode => {
  try {
    const result = await firebase.auth().verifyPasswordResetCode(oobCode);
    return result;
  } catch (error) {
    throw error;
  }
};

const confirmPasswordReset = async (oobCode, newPassword) => {
  try {
    await firebase.auth().confirmPasswordReset(oobCode, newPassword);
    return true;
  } catch (error) {
    throw error;
  }
};

export default {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  startAuthListener,
  stopAuthListener,
  getUser,
  createUser,
  updateUser,
  updateEmailFirebase,
  checkCurrentPassword,
  updatePasswordFirebase,
  createLead,
  uploadPhoto,
  verifyPasswordResetCode,
  confirmPasswordReset,
  completeAppSumoUser
};

import React, { PureComponent } from 'react';
import AnalyticsDashboard from '../../containers/Dashboard/Dashboard';

class Dashboard extends PureComponent {
  componentDidMount = () => {
    document.getElementById('favicon').href = 'favicon.ico';
  };

  render() {
    return <AnalyticsDashboard />;
  }
}

export default Dashboard;

// Initialize Firebase
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'fraud-blocker.firebaseapp.com',
  databaseURL: 'https://fraud-blocker.firebaseio.com',
  projectId: 'fraud-blocker',
  storageBucket: 'fraud-blocker.appspot.com',
  messagingSenderId: '861365078961'
};

firebase.initializeApp(config);
firebase.auth();
firebase.storage();

export default firebase;

import React, { PureComponent } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import RouteConfig from '../../router/RouteConfig';
import AuthedRoute from '../../router/AuthedRoute';
import styles from './DefaultLayout.module.scss';
import Navigation from '../Navigation/Navigation';
import subMenu from '../SubMenu/submenu-options';
import SubMenu from '../SubMenu/SubMenu';
import ActiveDomain from '../../redux/actions/ActiveDomain';
import Account from '../../redux/actions/Account';

class DefaultLayout extends PureComponent {
  constructor(props) {
    super(props);

    const { accounts, checkSubscription } = props;
    checkSubscription(accounts);

    this.state = {
      showSubNav: false,
      actOnInvalidSubscription: false,
      actOnNoDomain: false
    };
  }

  componentDidMount = () => {
    const { location } = this.props;
    if (RouteConfig.routesWithSubNav.includes(location.pathname)) {
      // Route needs SubNav - onload
      this.setState({ showSubNav: true });
    }
  };

  componentDidUpdate = prevProps => {
    const { showSubNav } = this.state;
    const { location, accounts, checkSubscription } = this.props;
    const { location: prevLocation } = prevProps;

    // Makes sure that a user's data is fully loaded before determining whether
    // to show the invalid subscription upgrade modal or not.
    // Fixes a quirk where being redirected to the login page sets subscriptionValid
    // and that state sticks through login even though the state is updated
    if (
      this.props.accounts &&
      !this.props.accounts.isFetching &&
      this.props.accounts.data &&
      this.props.accounts.data.stripe_token &&
      !this.props.accounts.subscriptionValid
    ) {
      this.setState({ actOnInvalidSubscription: true });
    }

    if (
      this.props.accounts &&
      !this.props.accounts.isFetching &&
      this.props.accounts.data &&
      this.props.accounts.data.domains &&
      !this.props.accounts.data.domains.filter(item => item.is_deleted === false).length
    ) {
      this.setState({ actOnNoDomain: true });
    }

    if (
      this.props.accounts &&
      !this.props.accounts.isFetching &&
      this.props.accounts.data &&
      this.props.accounts.data.domains &&
      this.props.accounts.data.domains.filter(item => item.is_deleted === false).length
    ) {
      this.setState({ actOnNoDomain: false });
    }

    if (RouteConfig.routesWithSubNav.includes(location.pathname)) {
      // Route needs SubNav, only set state if nav is already not visible
      if (!showSubNav) {
        this.setState({ showSubNav: true });
      }
    } else {
      // Route does not have subNav
      this.setState({ showSubNav: false });
    }

    // Check if subscription is valid on navigation changes
    if (prevLocation.pathname !== location.pathname) {
      checkSubscription(accounts);
    }
  };

  getSubMenuOptions = () => {
    const { location } = this.props;
    if (location.pathname.includes('/customizations')) {
      return subMenu.customizationMenu;
    }
    if (location.pathname.includes('/integrations')) {
      return subMenu.integrationsMenu;
    }

    if (location.pathname.includes('domain')) {
      return subMenu.dashboardMenu;
    }

    if (location.pathname.includes('account')) {
      return subMenu.accountMenu;
    }

    return [];
  };

  getGroupName = () => {
    const { location } = this.props;
    if (location.pathname.includes('/customizations')) {
      return 'Customizations';
    }
    if (location.pathname.includes('/integrations')) {
      return 'Set Up Your Website';
    }

    if (location.pathname.includes('domain')) {
      return 'Website Menu';
    }

    if (location.pathname.includes('account')) {
      return 'Your Account';
    }

    return '';
  };

  /**
   * Set Active domain to selected domain, check if selected domain has valid subscription
   */
  setDomain = domain => {
    const { setDomain, accounts, checkSubscription } = this.props;
    setDomain(domain);
    checkSubscription(accounts);
  };

  render() {
    const { location, history, auth, accounts, activeDomain } = this.props;
    const { showSubNav } = this.state;
    return (
      <div className={styles.all}>
        <Helmet>
          <title>Fraud Blocker</title>
        </Helmet>
        <Navigation setDomain={this.setDomain} location={location} history={history} />
        {showSubNav && (
          <SubMenu
            location={location}
            menu={this.getSubMenuOptions()}
            accounts={accounts}
            hasMetaAccess={true}
            activeDomain={activeDomain}
            setDomain={this.setDomain}
            userRole={auth.user.role}
            group={this.getGroupName()}
          />
        )}
        <Switch>
          {RouteConfig.routes.map(route => {
            if (
              this.state.actOnNoDomain &&
              location.pathname === route.path &&
              route.requiresDomain &&
              !location.pathname.includes('/account/billing/subscription')
            ) {
              return (
                <Redirect
                  key={route.path}
                  to={{
                    pathname: '/account/billing/subscription',
                    state: { forceAddDomain: true }
                  }}
                />
              );
            }

            /**
             * Check if subscription is valid and if route requires a subscription (ie. stats, dashboard, advertising)
             * If invalid Check if user is Viewer, as viewers do not have access to  Billing screen
             * If Viewer - redirect to account edit profile
             * If Manager / Owner - redirect to Billing Subscriptions screen
             */
            if (
              this.state.actOnInvalidSubscription &&
              location.pathname === route.path &&
              route.requiresSubscription
            ) {
              if (auth.user.role === 'Viewer') {
                // Check if location is not already on edit-profile, otherwise causes multiple redirects and errors.
                if (location.pathname !== '/account/settings/edit-profile') {
                  return (
                    <Redirect
                      key={route.path}
                      to={{
                        pathname: '/account/settings/edit-profile'
                      }}
                    />
                  );
                }
                // Check if location is not already on billing/subscription otherwise causes multiple redirects and errors
              } else if (!location.pathname.includes('/account/billing/subscription')) {
                return (
                  <Redirect
                    key={route.path}
                    to={{
                      pathname: '/account/billing/subscription',
                      state: { invalidSubscription: true, showPlansPopup: true }
                    }}
                  />
                );
              }
            }

            // Route contains redirect - (header Nav links redirect to first child)
            if (route.redirect) {
              return (
                <Route
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                  name={route.name}
                  render={() => <Redirect to={route.redirect} />}
                />
              );
            }

            if (
              route.path === location.pathname &&
              route.protected &&
              route.path === '/account/settings/user-management' &&
              (['Viewer', 'Manager', 'Client'].includes(auth.user.role))
            ) {
              return <Redirect key={route.path} to={'/account/settings/edit-profile'} />;
            }

            // If a Viewer tries to navigate to protected route directly from URL bar - redirect to dashboard.
            if (
              route.path === location.pathname &&
              route.protected &&
              (auth.user.role === 'Viewer' || (route.blockForRoles && route.blockForRoles.includes(auth.user.role)))
            ) {
              return <Redirect key={route.path} to={'/dashboard'} />;
            }

            // Usual Case - Go to authed route
            return route.component ? (
              <AuthedRoute
                key={route.path}
                auth={auth}
                activeDomain={activeDomain}
                accounts={accounts}
                exact={route.exact}
                path={route.path}
                name={route.name}
                component={route.component}
              />
            ) : null;
          })}
          {/* Default route redirect to dashboard */}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  accounts: PropTypes.object,
  activeDomain: PropTypes.object,
  setDomain: PropTypes.func,
  checkSubscription: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  activeDomain: state.activeDomain
});

const mapDispatchToProps = dispatch => {
  return {
    setDomain: domain => dispatch(ActiveDomain.setDomainActive(domain)),
    checkSubscription: accounts => dispatch(Account.checkSubscription(accounts))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);

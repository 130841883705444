/* eslint-disable react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './DomainLimitModal.module.scss';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import SuccessBox from '../../components/SuccessBox/SuccessBox';
import Account from '../../redux/actions/Account';
import Domains from '../../api/Domains';
import TooltipIcon from '../../assets/tooltip.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    position: 'relative',
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    width: 400,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '40px'
  },
  setLimitBtn: {
    width: '55%',
    height: '38px',
    color: '#286CFF',
    fontWeight: 400
  },
  inputStyle: {
    width: '40%'
  },
  fieldError: {
    // alignSelf: 'center'
  }
};

class DomainLimitModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clicksLimit: props.currentLimit,
      loading: false,
      apiSuccess: false,
      errors: {}
    };
  }

  handleCloseModal = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      this.setState({ clicksLimit: '' });
      onCancel();
    }
  };

  componentDidUpdate = () => {
    ReactTooltip.rebuild();
  };

  onInputChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onClickSetLimit = async () => {
    this.setState({ errors: { apiError: '', apiSuccess: false } });
    const { clicksLimit } = this.state;
    const {
      fetchLatestAccount,
      accounts,
      sid,
      availableClicks,
      currentClicks,
      currentLimit
    } = this.props;
    let limit = null;
    if (clicksLimit) {
      limit = parseInt(clicksLimit, 10);
      if (limit > availableClicks && limit > currentLimit + availableClicks) {
        this.setState({
          errors: {
            apiError:
              '<strong>Whoops!</strong> The total clicks you entered goes beyond the available clicks.'
          }
        });
        return;
      }

      if (limit < currentClicks) {
        this.setState({
          errors: {
            apiError:
              '<strong>Whoops!</strong> The current usage has already passed the total clicks you entered.'
          }
        });
        return;
      }
    }

    this.setState({ loading: true });
    try {
      await Domains.updateDomain(sid, { clicks_limit: limit });

      await fetchLatestAccount(accounts.data.id);

      this.setState({ loading: false });
      if (this.props.onSuccess) {
        this.setState({ clicksLimit: '' });
        this.props.onSuccess();
      }
    } catch (error) {
      this.setState({ errors: { apiError: error.message }, loading: false });
    }
  };

  render() {
    const { clicksLimit, loading, errors, apiSuccess } = this.state;
    const { isOpen, totalClicks, availableClicks } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Domain click limit modal"
        ariaHideApp={false}
      >
        <div className={styles.container}>
          <ReactTooltip id="availableClicks" className={styles.tooltipContent}>
            <div>
              This is your total purchased clicks less any limits you've set on other websites.
            </div>
          </ReactTooltip>
          <span className={styles.closeBtn} onClick={this.handleCloseModal} aria-hidden="true">
            ×
          </span>
          <div className={styles.content}>
            <p className={styles.setLimitText}>Set a Click Limit</p>
            <p className={styles.limitLabel}>
              Enter a click total, not to exceed your available clicks
            </p>
            <div className={styles.limitInputWrapper}>
              <Input
                onChange={this.onInputChange}
                value={clicksLimit}
                type="number"
                name="clicksLimit"
                containerStyle={customStyles.inputStyle}
                errorStyle={customStyles.fieldError}
              />
              <Button
                onClick={this.onClickSetLimit}
                title="Save And Close"
                loading={loading}
                color="outline"
                style={customStyles.setLimitBtn}
              />
            </div>
            <div className={styles.clicksCount}>
              <div className={styles.section}>
                <div className={styles.countLabel}>Purchased Clicks</div>
                <div>
                  {totalClicks && totalClicks.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.countLabel}>
                  Available Clicks
                  <img src={TooltipIcon} data-tip data-for="availableClicks" alt="tooltip" />
                </div>
                <div>
                  {availableClicks &&
                    availableClicks.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                </div>
              </div>
            </div>
          </div>

          {errors.apiError && <ErrorBox error={errors.apiError} />}
          {apiSuccess && <SuccessBox message="You've added the limit successfully." />}
        </div>
      </Modal>
    );
  }
}

DomainLimitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  accounts: PropTypes.object,
  fetchLatestAccount: PropTypes.func,
  totalClicks: PropTypes.number,
  availableClicks: PropTypes.number,
  sid: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  currentLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentClicks: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const mapStateToProps = state => ({
  accounts: state.accounts
});

const mapDispatchToProps = dispatch => {
  return {
    fetchLatestAccount: accountId => dispatch(Account.fetchLatestAccount(accountId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainLimitModal);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import styles from './Account.module.scss';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import SuccessBox from '../../components/SuccessBox/SuccessBox';
import InviteUserModal from '../../containers/InviteUserModal/InviteUserModal';
import Users from '../../api/Users';
import Roles from '../../api/Roles';
// import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/ok-icon.svg';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as CaretDown } from '../../assets/caret_down.svg';
import Dropdown from '../../components/Dropdown/Dropdown';

const customStyles = {
  userEmail: {
    fontSize: 10,
    color: '#898989'
  },
  nameContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  userPhotoContainer: {
    width: 40,
    height: 40,
    marginRight: 20,
    borderRadius: 20,
    backgroundColor: '#5289ff'
  },
  userPhoto: {
    width: 40,
    height: 40,
    borderRadius: 20
  },
  userName: {
    fontWeight: '400',
    marginBottom: 8,
    color: '#2B2C33'
  },
  initials: {
    fontSize: 20
  },
  accessText: {
    fontWeight: '600',
    color: '#2B2C33'
  },
  iconAction: {},
  addUserBtn: {
    width: 'auto',
    maxWidth: 125,
    marginRight: 15,
    border: 'none',
    fontWeight: 'normal'
  },
  saveBtn: {
    width: 'auto',
    maxWidth: 125
  },
  resendBtn: {
    marginRight: 10,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '14px',
    color: 'darkgray',
    lineHeight: '12px',
    marginLeft: '20px'
  },
  removeBtn: {
    minWidth: 0,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer'
  },
  lockBtn: {
    minWidth: 0,
    paddingLeft: 10,
    paddingRight: 10
  },
  saveBtnWrapper: {
    display: 'flex'
  },
  dropdownContainer: {
    pointerEvents: 'none'
  }
};

const DEFAULT_DOMAIN_SELECTION = { value: null, label: 'All Websites' };

class UserManagement extends PureComponent {
  state = {
    roles: [],
    removingIndex: '',
    roleDropdownOptions: { values: [] },
    allUsers: [],
    originalUsers: [],
    showAddUserModal: false,
    loading: false,
    errors: {},
    showSuccess: {},
    activeIndex: -1,
    selectedDomain: DEFAULT_DOMAIN_SELECTION,
    domainOptions: []
  };

  componentDidMount() {
    this.getAllRoles();
    this.getUsersInAccount();
    if (
      this.props.accounts &&
      this.props.accounts.data &&
      this.props.accounts.data.domains &&
      this.props.accounts.data.domains.length
    ) {
      const optionsDefault = this.props.accounts.data.domains
        .sort((a, b) => {
          if (a.domain_name.toLowerCase() < b.domain_name.toLowerCase()) {
            return -1;
          }
          if (a.domain_name.toLowerCase() > b.domain_name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .filter(item => item.is_deleted === false)
        .map(item => ({
          value: item.id,
          label: item.domain_name
        }));
      this.setState({ domainOptions: [DEFAULT_DOMAIN_SELECTION, ...optionsDefault] });
      // this.props.setDomain(this.props.accounts.data.domains[0]);
    }
  }

  getAllRoles = async () => {
    const { roleDropdownOptions } = this.state;
    try {
      const allRoles = await Roles.getAllRoles();
      allRoles.forEach(role => {
        if (role.name !== 'Owner') {
          roleDropdownOptions.values.push({ value: role.name, label: role.name });
        }
      });
      this.setState({ roles: allRoles, roleDropdownOptions });
    } catch (error) {
      console.log(error);
    }
  };

  getUsersInAccount = async () => {
    const { accounts, auth } = this.props;
    try {
      const allUsers = await Users.getAllUsersInAccount(accounts.data.id);
      const filteredUsers = [
        allUsers.find(user => user.id === auth.user.id),
        ...allUsers.filter(user => user.id !== auth.user.id)
      ];
      console.log('allUsers', filteredUsers);
      const originalUsers = JSON.parse(JSON.stringify(filteredUsers));
      this.setState({
        allUsers: filteredUsers,
        originalUsers,
        errors: {},
        loading: false
      });
    } catch (error) {
      console.log(error);
    }
  };

  onStatusChange = (selectedOption, index) => {
    const { allUsers } = this.state;
    const newUsers = allUsers.slice();
    newUsers[index].status = selectedOption.value.toUpperCase();

    this.setState({ allUsers: newUsers });
  };

  onRoleChange = (selectedOption, index) => {
    console.log(22);
    const { allUsers, roles } = this.state;
    const newUsers = allUsers.slice();
    const selectedRole = roles.find(role => role.name === selectedOption);
    newUsers[index].role_id = selectedRole.id;

    this.setState({ allUsers: newUsers, selectedDomain: DEFAULT_DOMAIN_SELECTION });
  };

  addDomainAccess = (domain, index) => {
    const { allUsers } = this.state;
    const newUsers = allUsers.slice();
    if (domain.value === null) {
      newUsers[index].accessible_domains = null;
    } else {
      newUsers[index].accessible_domains = newUsers[index].accessible_domains
        ? [...newUsers[index].accessible_domains, { id: domain.value }]
        : [{ id: domain.value }];
    }
    this.setState({ allUsers: newUsers, selectedDomain: domain });
  };

  removeDomainAccess = (domainId, index) => {
    const { allUsers } = this.state;
    const newUsers = allUsers.slice();
    newUsers[index].accessible_domains = [
      ...newUsers[index].accessible_domains.filter(item => item.id !== domainId)
    ];
    this.setState({ allUsers: newUsers, selectedDomain: null });
  };

  updateUsers = async (selectedIndex = null) => {
    const { allUsers, roles } = this.state;

    const promises = [
      Users.updateAccountUser(allUsers[selectedIndex].id, {
        role_id: allUsers[selectedIndex].role_id,
        accessible_domains:
          roles.find(role => allUsers[selectedIndex].role_id === role.id).name !== 'Admin' &&
            allUsers[selectedIndex].accessible_domains &&
            allUsers[selectedIndex].accessible_domains.length
            ? allUsers[selectedIndex].accessible_domains
            : null
      })
    ];

    if (promises.length > 0) {
      const result = await Promise.all(promises);
      return result;
    }

    return undefined;
  };

  updateUserAccess = async index => {
    this.setState({ loading: true, errors: {}, showSuccess: {} });

    try {
      const result = await this.updateUsers(index);
      if (result) {
        const usersCopy = this.state.originalUsers.slice();
        usersCopy[index].role_id = this.state.allUsers[index].role_id;
        usersCopy[index].accessible_domains = this.state.allUsers[index].accessible_domains;
        this.setState({ originalUsers: usersCopy, showSuccess: { message: 'Update Successful' } });
      }
      this.setState({ loading: false, activeIndex: -1 });
    } catch (error) {
      this.setState({ errors: { saveError: error.message }, showSuccess: {}, loading: false });
    }
  };

  resendInvite = async index => {
    const { allUsers } = this.state;
    const selectedUser = allUsers[index];

    const data = {
      email: selectedUser.email,
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name
    };

    try {
      const result = await Users.resendInvite(data);
      window.Intercom('trackEvent', 'resend-user-invite', { ...data, invite_id: result.id });
      if (result) {
        this.setState({ showSuccess: { message: 'Invitation Resent!' } });
      }
    } catch (error) {
      this.setState({ showSuccess: {}, errors: { resendError: 'Error Resending Invitation.' } });
    }
  };

  removeUser = async index => {
    const { allUsers } = this.state;
    const selectedUser = allUsers[index];
    console.log(selectedUser);
    try {
      await Users.removeUser(selectedUser.id);
      if (selectedUser.status === 'AWAITING_RESPONSE') {
        await Users.removeInvitation(selectedUser.email);
      }
      this.setState({ removingIndex: index });
      await this.getUsersInAccount();
      this.setState({
        removingIndex: '',
        showSuccess: { message: `${selectedUser.email} has been removed.` }
      });
    } catch (error) {
      this.setState({ errors: { removeError: error.message } });
    }
  };

  toggleAddUserModal = () => {
    const { showAddUserModal } = this.state;
    this.setState({ showAddUserModal: !showAddUserModal });
  };

  toogleUserList(index) {
    const { allUsers } = this.state;
    this.setState({
      activeIndex: this.state.activeIndex === index ? -1 : index,
      selectedDomain:
        allUsers[index].accessible_domains && allUsers[index].accessible_domains.length
          ? null
          : DEFAULT_DOMAIN_SELECTION
    });
  }

  formatStatus = status => {
    if (status === 'AWAITING_RESPONSE') {
      return 'Awaiting Response';
    }
    if (status.length) {
      return `${status[0]}${status.slice(1).toLowerCase()}`;
    }
    return '';
  };

  render() {
    const {
      allUsers,
      showAddUserModal,
      showSuccess,
      roleDropdownOptions,
      roles,
      loading,
      removingIndex,
      errors,
      activeIndex,
      selectedDomain,
      domainOptions,
      originalUsers
    } = this.state;
    const { accounts, auth } = this.props;
    return (
      <div className={styles.content}>
        <h1 className={styles.title}>User Management</h1>
        <p>
          {/* Invite others to join your workspace and manage their access. A <i>Manager</i> can add or
          remove domains, adjust subscription settings and change billing information while a{' '}
          <i>Viewer</i> can only access the analytics and reports areas. */}
          Invite teammates to join your account.
        </p>

        <section>
          <header className={styles.tableRow}>
            <div className={styles.tableColumn}>
              <h3>Name</h3>
            </div>
            <div className={styles.tableColumn}>
              <h3>Last Login</h3>
            </div>
            <div className={styles.tableColumn}>
              <h3>Access</h3>
            </div>
            <div className={styles.tableColumn} />
          </header>
          {allUsers.map((user, index) => {
            const userRole = roles.find(role => role.id === user.role_id);
            const savedUserRole = roles.find(role => role.id === originalUsers[index].role_id);
            const userRoleDropdown = userRole ? { value: userRole.name, label: userRole.name } : '';
            const userStatus = this.formatStatus(user.status);

            return (
              <div
                key={index}
                className={`${styles.tableRow} ${styles.userManagementRow} ${removingIndex === index ? styles.removeUserManagementRow : ''
                  }`}
              >
                <div className={styles.tableColumn}>
                  <div style={customStyles.nameContainer}>
                    <div>
                      <p style={customStyles.userName}>{`${user.first_name ||
                        'Your'} ${user.last_name || 'Name'}`}</p>
                      <p style={customStyles.userEmail}>{user.email}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.tableColumn}>
                  {user.status === 'AWAITING_RESPONSE' ? (
                    <p style={customStyles.lastSignInTime}>{userStatus}</p>
                  ) : (
                    <p style={customStyles.lastSignInTime}>
                      {user.lastSignInTime
                        ? moment(user.lastSignInTime).format('M/DD/YY LT')
                        : this.formatStatus(user.status)}
                    </p>
                  )}
                </div>
                <div className={styles.tableColumn}>
                  <p style={customStyles.accessText}>{savedUserRole && savedUserRole.name}</p>
                </div>
                <div className={styles.tableColumn}>
                  {userRoleDropdown && userRoleDropdown.value !== 'Owner' ? (
                    <div className={styles.actionsColumn}>
                      <CaretDown
                        index={index}
                        style={customStyles.removeBtn}
                        className={`${styles.toggleBtn} ${index === activeIndex ? styles.active : ''
                          }`}
                        onClick={() => this.toogleUserList(index)}
                      />
                      {/* {user.status === 'AWAITING_RESPONSE' && (
                        <span
                          style={customStyles.resendBtn}
                          onClick={() => this.resendInvite(index)}
                        >
                          Resend email
                        </span>
                      )} */}
                    </div>
                  ) : (
                    <p style={customStyles.iconAction}>
                      <LockIcon style={customStyles.lockBtn} />
                    </p>
                  )}
                </div>
                {userRoleDropdown.value !== 'Owner' && activeIndex === index ? (
                  <div
                    className={`${styles.toogleItems} ${activeIndex === index ? styles.expandedUserRow : ''
                      }`}
                  >
                    <div className={styles.accountAuth}>
                      <div className={styles.leftCol}>
                        <div className={styles.tabHeading}>
                          <div className={styles.heading}>Account Level</div>
                          <div className={styles.heading}>
                            <div className={styles.radioItem}>
                              <input
                                type="radio"
                                name="roles"
                                id={`viewer${index}`}
                                value="Viewer"
                                onChange={e => this.onRoleChange(e.target.value, index)}
                                checked={userRoleDropdown.value === 'Viewer'}
                              />
                            </div>
                            Viewer
                          </div>
                          <div className={styles.heading}>
                            <div className={styles.radioItem}>
                              <input
                                type="radio"
                                name="roles"
                                id={`client${index}`}
                                value="Client"
                                onChange={e => this.onRoleChange(e.target.value, index)}
                                checked={userRoleDropdown.value === 'Client'}
                              />
                            </div>
                            Client
                          </div>
                          <div className={styles.heading}>
                            <div className={styles.radioItem}>
                              <input
                                type="radio"
                                name="roles"
                                value="Manager"
                                id={`manager${index}`}
                                onChange={e => this.onRoleChange(e.target.value, index)}
                                checked={userRoleDropdown.value === 'Manager'}
                              />
                            </div>
                            Manager
                          </div>
                          <div className={styles.heading}>
                            <div className={styles.radioItem}>
                              <input
                                type="radio"
                                name="roles"
                                value="Admin"
                                id={`admin${index}`}
                                onChange={e => this.onRoleChange(e.target.value, index)}
                                checked={userRoleDropdown.value === 'Admin'}
                              />
                            </div>
                            Admin
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>View Reporting</div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>Connect Ad Networks</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>Change Customizations</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>Add & Delete Websites</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>Invite Users</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>Set Click Limits</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>View Plan Subscription</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>Edit User Access</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                        <div className={styles.tabItems}>
                          <div className={styles.items}>Change Plan Subscription</div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}></div>
                          <div className={styles.items}>
                            <CheckIcon />
                          </div>
                        </div>
                      </div>
                      {userRoleDropdown && (
                        <div className={styles.rightCol}>
                          <div className={styles.selectDomain}>
                            <div className={styles.selectWrapper}>
                              <div className={styles.selectHeading}>Select Website Access</div>
                              <Dropdown
                                index={index}
                                placeholder="Select"
                                containerStyle={
                                  userRoleDropdown.value === 'Admin'
                                    ? customStyles.dropdownContainer
                                    : null
                                }
                                selectClass={styles.userDomainDropdown}
                                isSearchable={false}
                                target={document.body}
                                disabled={userRoleDropdown.value === 'Admin'}
                                name="user_domains_access"
                                onOptionChange={val => this.addDomainAccess(val, index)}
                                value={selectedDomain}
                                options={domainOptions}
                              />
                              {userRoleDropdown.value === 'Admin' && (
                                <div className={styles.lockedDomainWrap}>
                                  <LockIcon style={styles.lockedDomain} />
                                </div>
                              )}
                            </div>
                            {userRoleDropdown.value !== 'Admin' &&
                              user.accessible_domains &&
                              user.accessible_domains.length > 0 && (
                                <div className={styles.domainList}>
                                  <ul>
                                    {user.accessible_domains.map(domain => (
                                      <li key={domain.id}>
                                        {domainOptions.find(item => item.value === domain.id) &&
                                          domainOptions.find(item => item.value === domain.id)
                                            .label}
                                        <a
                                          href="#"
                                          onClick={() => this.removeDomainAccess(domain.id, index)}
                                        >
                                          ×
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                          <div className={styles.btnDomain}>
                            <Button
                              title="Save & Close"
                              onClick={() => this.updateUserAccess(index)}
                              color="blue"
                              loading={loading}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.btnGroup}>
                      {userRoleDropdown.value !== 'Owner' && (
                        // <DeleteIcon
                        //   index={index}
                        //   style={customStyles.removeBtn}
                        //   onClick={() => this.removeUser(index)}
                        // />
                        <span
                          index={index}
                          className={styles.removeBtn}
                          onClick={() => this.removeUser(index)}
                        >
                          Delete User
                        </span>
                      )}
                      {user.status === 'AWAITING_RESPONSE' && (
                        <span className={styles.removeBtn} onClick={() => this.resendInvite(index)}>
                          Resend email
                        </span>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </section>
        {(errors.saveError || errors.resendError || errors.removeError) && (
          <ErrorBox error={errors.saveError || errors.resendError || errors.removeError} />
        )}
        {showSuccess.message && <SuccessBox message={showSuccess.message} />}
        <div style={customStyles.saveBtnWrapper}>
          <Button
            title="+ Add User"
            color="outline"
            style={customStyles.addUserBtn}
            onClick={this.toggleAddUserModal}
          />
        </div>

        <InviteUserModal
          isOpen={showAddUserModal}
          getUsers={this.getUsersInAccount}
          toggleModal={this.toggleAddUserModal}
          account={accounts.data}
          allRoles={roles}
          auth={auth}
          roleDropdownOptions={roleDropdownOptions}
        />
      </div>
    );
  }
}

UserManagement.propTypes = {
  auth: PropTypes.object,
  accounts: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Account.module.scss';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import User from '../../redux/actions/User';
import Validation from '../../utils/Validation';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import SuccessBox from '../../components/SuccessBox/SuccessBox';

const customStyles = {
  title: {
    marginBottom: 60
  },
  editTitle: {
    marginBottom: 10
  },
  editInput: {},
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30
  },
  saveBtn: {
    maxWidth: 110
  }
};
class Password extends PureComponent {
  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordRetyped: '',
    errors: {},
    loading: false,
    showSuccess: false
  };

  onInputChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onSaveClick = async () => {
    this.setState({ loading: true, showSuccess: false });
    const { currentPassword, newPassword, newPasswordRetyped } = this.state;
    const { auth } = this.props;

    if (newPassword !== newPasswordRetyped) {
      this.setState({
        errors: { passwordRetyped: 'Passwords entered do not match' },
        loading: false
      });
      return;
    }

    const requiredData = {
      password: newPassword
    };

    const newErrors = Validation.validateForm(requiredData);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }

    try {
      await User.checkCurrentPassword(currentPassword, auth.user.email);
      await User.updatePasswordFirebase(requiredData.password);
      this.setState({ errors: {}, showSuccess: true, loading: false });
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/wrong-password') {
        this.setState({ errors: { currentPassword: 'Wrong Password' } });
        return;
      }
      this.setState({
        errors: { save: error.message },
        loading: false
      });
    }
  };

  render() {
    const {
      currentPassword,
      newPassword,
      loading,
      newPasswordRetyped,
      showSuccess,
      errors
    } = this.state;
    return (
      <div className={styles.content}>
        <h1 style={customStyles.title} className={styles.title}>
          Change Password
        </h1>

        <Input
          containerStyle={customStyles.inputContainer}
          style={customStyles.editInput}
          onChange={this.onInputChange}
          label={'Current Password'}
          value={currentPassword}
          name="currentPassword"
          type="password"
          error={errors.currentPassword}
          showEye
        />

        <Input
          containerStyle={customStyles.inputContainer}
          style={customStyles.editInput}
          onChange={this.onInputChange}
          value={newPassword}
          label="New Password"
          name="newPassword"
          type="password"
          error={errors.password}
          showEye
        />

        <Input
          containerStyle={customStyles.inputContainer}
          style={customStyles.editInput}
          onChange={this.onInputChange}
          value={newPasswordRetyped}
          label="Re-type Password"
          name="newPasswordRetyped"
          type="password"
          error={errors.passwordRetyped}
          showEye
        />
        {errors.save && (
          <div>
            <ErrorBox error={errors.save} />
          </div>
        )}
        {showSuccess && (
          <div>
            <SuccessBox message="Successfully Updated Password!" />
          </div>
        )}
        <Button
          title="Save"
          loading={loading}
          color="blue"
          style={customStyles.saveBtn}
          onClick={this.onSaveClick}
        />
      </div>
    );
  }
}

Password.propTypes = {
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Password);

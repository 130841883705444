import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './Account.module.scss';
import Switch from '../../components/Switch/Switch';
import User from '../../redux/actions/User';

const customStyles = {
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30
  },
  switchText: {
    marginLeft: 10,
    fontWeight: '600'
  }
};

const intercomNotificationMapping = {
  notification_promos: 'notif_discount_and_promotions',
  notification_alerts: 'notif_real_time_alerts',
  notification_announcements: 'notif_company_announcements',
  notification_news: 'notif_fraud_industry_news'
};
class NotificationSettings extends PureComponent {
  state = {
    error: undefined
  };

  onSwitchChange = async name => {
    const { auth, updateUser } = this.props;

    const updateData = {
      [name]: !auth.user[name]
    };

    try {
      const result = await updateUser(auth.user.id, updateData);
      console.log(result);
      window.Intercom('update', {
        user_id: auth.user.id,
        [intercomNotificationMapping[name]]: updateData[name]
      });
    } catch (error) {
      console.log(error);
    }

    this.setState({ [name]: !this.state[name] });
  };

  render() {
    const { auth } = this.props;
    const {
      notification_promos: promos,
      notification_alerts: alerts,
      notification_announcements: announcements,
      notification_news: news
    } = auth.user;

    return (
      <div className={styles.content}>
        <h1 className={styles.title}>App Notifications</h1>
        <p>Adjust your email notification settings here.</p>
        <div style={customStyles.switchContainer}>
          <Switch checked={promos} name="notification_promos" onChange={this.onSwitchChange} />
          <p style={customStyles.switchText}>Discount and Promotions</p>
        </div>

        <div style={customStyles.switchContainer}>
          <Switch checked={alerts} name="notification_alerts" onChange={this.onSwitchChange} />
          <p style={customStyles.switchText}>Real-time alerts when a fraudelent IP is detected</p>
        </div>

        <div style={customStyles.switchContainer}>
          <Switch
            checked={announcements}
            name="notification_announcements"
            onChange={this.onSwitchChange}
          />
          <p style={customStyles.switchText}>Company announcements</p>
        </div>

        <div style={customStyles.switchContainer}>
          <Switch checked={news} name="notification_news" onChange={this.onSwitchChange} />
          <p style={customStyles.switchText}>Fraud industry news</p>
        </div>
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  updateUser: PropTypes.func,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (id, data) => dispatch(User.updateUser(id, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);

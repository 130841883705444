import React, { PureComponent } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import styles from './Loading.module.scss';

class Loading extends PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <LoadingSpinner />
      </div>
    );
  }
}

export default Loading;

import React, { PureComponent } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import styles from './Account.module.scss';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
// import Switch from '../../components/Switch/Switch';
import SuccessBox from '../../components/SuccessBox/SuccessBox';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Account from '../../redux/actions/Account';
import Validation from '../../utils/Validation';
import Utils from '../../utils/Utils';
import Constants from '../../utils/Constants';
import Dropdown from '../../components/Dropdown/Dropdown';
import Payments from '../../api/Payments';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';
import AddressForm from './AddressForm';

const customStyles = {
  title: {
    marginBottom: 50
  },
  subTitle: {
    marginBottom: 30
  },
  editInput: {},
  inputContainer: {
    flex: 1,
    marginRight: 38,
    marginBottom: 30
  },
  emailInputContainer: {
    flex: 1,
    marginBottom: 20
  },
  inputsContainer: {
    display: 'flex',
    alignItems: 'stretch'
  },
  taxInputsContainer: {
    display: 'flex',
    alignItems: 'stretch',
    maxWidth: '700px'
  },
  singleField: {
    maxWidth: '662px'
  },
  saveBtn: {
    maxWidth: 110,
    marginTop: 20
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#eaedf3',
    marginTop: 30,
    marginBottom: 30
  },
  dividerShort: {
    maxWidth: '662px'
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  switchText: {
    marginLeft: 10
  },
  table: {
    marginTop: 0
  },
  link: {
    color: '#1660ff',
    fontWeight: 'normal',
    textDecoration: 'underline'
  },
  taxCountry: {
    fontSize: '14px',
    marginLeft: '10px',
    color: '#4a4a4a'
  },
  addTaxIdBtn: {
    minWidth: 140,
    marginRight: 15,
    border: 'none',
    fontWeight: 'normal',
    color: '#286cff',
    marginBottom: '20px'
  },
  deleteAction: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteBtn: {
    cursor: 'pointer',
    marginTop: '-5px'
  }
};

const stripeFormAppearance = {
  rules: {
    '.Input': {
      boxSizing: 'border-box',
      padding: '10px 18px',
      border: 'solid 1px #c9cdd8',
      boxShadow: 'none'
    },
    '.Label': {
      marginBottom: '10px',
      textTransform: 'capitalize'
    },
    '.Input:hover': {
      outline: '#279cf8 auto 1px',
      boxShadow: '0 0 0.6pt 0.6pt #c9cdd8'
    },
    '.Input:focus': {
      boxShadow: 'none'
    }
  },
  labels: 'above',
  variables: {
    fontSizeSm: '14px',
    colorPrimary: '#0570de',
    colorBackground: '#F0F4F8',
    colorText: '#4a4a4a',
    colorDanger: '#df1b41',
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    borderRadius: '4px',
    spacingGridRow: '20px'
  }
};

const TAX_ID_TYPE_OPTIONS = Constants.countryTaxIdOptions.map(option => ({
  label: (
    <span style={{ color: '#2b2c33' }}>
      {option.code} {option.enum.toUpperCase()}
      <span style={customStyles.taxCountry}>{option.country}</span>
    </span>
  ),
  value: `${option.code}_${option.enum}`,
  icon: `../../flags/${option.code.toLowerCase()}.svg`
}));

const stripe = loadStripe(Constants.stripePublicKey);

class Invoices extends PureComponent {
  state = {
    billingName: this.props.accounts.subscription ? this.props.accounts.subscription.name : '',
    billingEmail: this.props.accounts.data.billing_email,
    billingAddress: this.props.accounts.data.billing_address,
    billingAddress2: this.props.accounts.data.billing_address_2,
    billingCountry:
      this.props.accounts.subscription && this.props.accounts.subscription.address
        ? this.props.accounts.subscription.address.country
        : 'US',
    city: this.props.accounts.data.billing_city,
    state: this.props.accounts.data.billing_state,
    zip: this.props.accounts.data.billing_zip,
    newTaxIds:
      !this.props.accounts.subscription ||
        !this.props.accounts.subscription.tax_ids ||
        !this.props.accounts.subscription.tax_ids.data.length
        ? [{ type: '', value: '' }]
        : [],
    taxIdsToBeDeleted: [],
    sendNotifications: true,
    invoices: [],
    errors: {},
    invoicesError: {},
    loading: false,
    showSuccess: false
  };

  componentDidMount() {
    this.getAllInvoices();
    ReactTooltip.rebuild();
  }

  componentDidUpdate(preProps) {
    if (
      !preProps.accounts.subscription &&
      this.props.accounts.subscription &&
      this.props.accounts.data
    ) {
      this.setState({
        billingName: this.props.accounts.subscription.name || '',
        billingEmail: this.props.accounts.data.billing_email,
        billingAddress: this.props.accounts.subscription.address.line1,
        billingAddress2: this.props.accounts.subscription.address.line2,
        billingCountry: this.props.accounts.subscription.address.country || 'US',
        city: this.props.accounts.subscription.address.city,
        state: this.props.accounts.subscription.address.state,
        zip: this.props.accounts.subscription.address.postal_code,
        newTaxIds:
          !this.props.accounts.subscription ||
            !this.props.accounts.subscription.tax_ids ||
            !this.props.accounts.subscription.tax_ids.data.length
            ? [{ type: '', value: '' }]
            : []
      });
    }
  }

  getAllInvoices = async () => {
    const { accounts } = this.props;
    const customerId = accounts.data.stripe_token;
    const subscription = Utils.getSingleSubscription(accounts, accounts.data.id);
    if (subscription) {
      try {
        const result = await Payments.getAllCustomerInvoices(customerId, subscription.id);
        if (result) {
          this.setState({ invoices: result.data, invoicesError: {} });
          return;
        }
      } catch (error) {
        console.log(error);
        this.setState({ invoicesError: { error: error.message } });
        return;
      }
    }

    this.setState({ invoicesError: { error: 'No subscription found' } });
  };

  onInputChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onSwitchChange = name => {
    this.setState({ [name]: !this.state[name] });
  };

  onBillingStateChange = selectedOption => {
    this.setState({ state: { value: selectedOption.value, label: selectedOption.label } });
  };

  onTaxIdTypeChange = (selectedOption, index) => {
    const newTaxIds = JSON.parse(JSON.stringify(this.state.newTaxIds));
    newTaxIds[index].type = selectedOption.value;
    this.setState({ newTaxIds });
  };

  onTaxIdValueChange = (event, index) => {
    const { value } = event.target;
    const newTaxIds = JSON.parse(JSON.stringify(this.state.newTaxIds));
    newTaxIds[index].value = value;
    this.setState({ newTaxIds });
  };

  onAddressChange = event => {
    if (event.complete) {
      // Extract potentially complete address
      const { address, name } = event.value;
      console.log(address);
      this.setState({
        billingAddress: address.line1,
        billingAddress2: address.line2,
        city: address.city,
        state: address.state,
        zip: address.postal_code,
        billingName: name,
        billingCountry: address.country
      });
    }
  };

  onClickSaveBtn = async () => {
    this.setState({ showSuccess: false, loading: true });
    const {
      billingEmail,
      billingAddress,
      billingAddress2,
      city,
      state,
      zip,
      billingName,
      billingCountry
    } = this.state;
    const { updateUserAccount, accounts } = this.props;

    const requiredData = {
      billingEmail,
      billingAddress,
      billingCountry,
      city,
      state,
      zip
    };

    // const newErrors = Validation.validateForm(requiredData);
    // if (newErrors) {
    //   this.setState({ errors: newErrors, loading: false });
    //   console.log('invalidForm: ', newErrors);
    //   return;
    // }

    const updateData = {
      billing_email: requiredData.billingEmail || '',
      billing_address: requiredData.billingAddress || '',
      billing_address_2: billingAddress2 || '',
      billing_city: requiredData.city || '',
      billing_state: requiredData.state || '',
      billing_zip: requiredData.zip || ''
    };

    const stripeUpdateData = {
      name: billingName || '',
      address: {
        line1: billingAddress || '',
        city: city || '',
        line2: billingAddress2 || '',
        postal_code: zip || '',
        state: state || '',
        country: billingCountry || ''
      }
    };

    try {
      const result = await updateUserAccount(accounts.data.id, updateData);
      const stripeResult = await Payments.updateCustomer(
        accounts.data.stripe_token,
        stripeUpdateData
      );
      if (result && stripeResult) {
        this.fetchLatestSubscriptionInfo();
        this.setState({
          errors: {},
          showSuccess: true,
          loading: false
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ errors: { save: error.message }, loading: false });
    }
  };

  onAddTaxIdClick = () => {
    const newTaxIds = JSON.parse(JSON.stringify(this.state.newTaxIds));
    newTaxIds.push({ type: '', value: '' });
    this.setState({ newTaxIds });
  };

  onRemoveTaxId = id => {
    this.setState(pre => ({ taxIdsToBeDeleted: [...pre.taxIdsToBeDeleted, id] }));
  };

  validateNewTaxIds = () => {
    const taxErrors = [];
    for (let i = 0; i < this.state.newTaxIds.length; i += 1) {
      const newError = Validation.validateForm(this.state.newTaxIds[i]);
      if (newError) {
        taxErrors.push(newError);
      }
    }
    if (taxErrors.length) {
      return false;
    }
    return true;
  };

  onClickTaxSaveBtn = async () => {
    const { newTaxIds, taxIdsToBeDeleted } = this.state;
    const { accounts } = this.props;
    const requiredData = {};

    if (newTaxIds.length) {
      if (!this.validateNewTaxIds()) {
        return;
      }
      requiredData.newTaxIds = newTaxIds.map(taxId => ({
        type: `${taxId.type.split('_')[1]}_${taxId.type.split('_')[2]}`,
        value: taxId.value
      }));
    }

    if (taxIdsToBeDeleted.length) {
      requiredData.deleteTaxIds = taxIdsToBeDeleted;
    }

    if (!Object.keys(requiredData).length) {
      return;
    }

    this.setState({ showSuccess: false, loading: true });

    try {
      const existingTaxIds =
        accounts.subscription &&
          accounts.subscription.tax_ids &&
          accounts.subscription.tax_ids.data.length
          ? accounts.subscription.tax_ids.data.map(taxId => ({
            id: taxId.id,
            type: `${taxId.country}_${taxId.type}`,
            value: taxId.value
          }))
          : [];
      const stripeResult = await Payments.updateCustomer(accounts.data.stripe_token, requiredData);
      if (stripeResult) {
        await this.fetchLatestSubscriptionInfo();
        this.setState({
          errors: {},
          showTaxSuccess: true,
          loading: false,
          taxIdsToBeDeleted: [],
          newTaxIds:
            (!requiredData.newTaxIds || !requiredData.newTaxIds.length) &&
              (!existingTaxIds.length || existingTaxIds.length === taxIdsToBeDeleted.length)
              ? [{ type: '', value: '' }]
              : []
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ errors: { saveTax: error.message }, loading: false });
    }
  };

  fetchLatestSubscriptionInfo = async () => {
    const { getUserSubscriptions, accounts } = this.props;

    try {
      await getUserSubscriptions(accounts.data.stripe_token);
    } catch (error) {
      console.log(error);
    }
  };

  getSelectedTaxIdTypeObject = type => {
    if (type) {
      return TAX_ID_TYPE_OPTIONS.find(option => option.value === type);
    }
    return '';
  };

  getTaxIdValuePlaceHolder = index => {
    if (this.state.newTaxIds[index].type) {
      return Constants.countryTaxIdOptions.find(
        option => `${option.code}_${option.enum}` === this.state.newTaxIds[index].type
      ).pattern;
    }
    return '';
  };

  isTaxIdChangeDisabled = () => {
    return (
      !this.state.taxIdsToBeDeleted.length &&
      (!this.state.newTaxIds.length || !this.validateNewTaxIds())
    );
  };

  render() {
    const {
      billingName,
      billingAddress,
      billingAddress2,
      billingEmail,
      billingCountry,
      city,
      state,
      zip,
      newTaxIds,
      // sendNotifications,
      invoices,
      invoicesError,
      errors,
      showSuccess,
      showTaxSuccess,
      loading,
      taxIdsToBeDeleted
    } = this.state;

    const existingTaxIds =
      this.props.accounts.subscription &&
        this.props.accounts.subscription.tax_ids &&
        this.props.accounts.subscription.tax_ids.data.length
        ? this.props.accounts.subscription.tax_ids.data.map(taxId => ({
          id: taxId.id,
          type: `${taxId.country}_${taxId.type}`,
          value: taxId.value
        }))
        : [];
    return (
      <div className={styles.content}>
        <h1 style={customStyles.title} className={styles.title}>
          Invoices
        </h1>
        <ReactTooltip
          effect="solid"
          delayHide={500}
          delayUpdate={500}
          className={styles.emailChangeTooltip}
          id="emailChangeInvoice"
        >
          To change your account email, please{' '}
          <a href="mailto:info@fraudblocker.com?subject=Change Account Email Address">email</a> our
          support team.
        </ReactTooltip>
        <h3 style={customStyles.subTitle} className={styles.subTitle}>
          Billing Information
        </h3>
        <p style={{ marginBottom: '10px' }}>
          Account Email
          <TooltipIcon className={styles.emailChangeTip} data-tip data-for="emailChangeInvoice" />
        </p>
        <div style={{ ...customStyles.taxInputsContainer, ...customStyles.singleField }}>
          <Input
            containerStyle={customStyles.emailInputContainer}
            onChange={this.onInputChange}
            value={billingEmail}
            name="billingEmail"
            disabled={true}
          />
        </div>
        <div className={styles.stripeWrapper}>
          <Elements stripe={stripe} options={{ appearance: stripeFormAppearance }}>
            <AddressForm
              onAddressChange={this.onAddressChange}
              values={{
                address: {
                  line1: billingAddress,
                  line2: billingAddress2,
                  city,
                  state,
                  postal_code: zip,
                  country: billingCountry
                },
                name: billingName
              }}
            />
          </Elements>
        </div>
        {/* <div style={customStyles.inputsContainer}>
          <Input
            containerStyle={customStyles.inputContainer}
            style={customStyles.editInput}
            onChange={this.onInputChange}
            label="Billing Address"
            value={billingAddress}
            name="billingAddress"
            error={errors.billingAddress}
          />

          <Input
            containerStyle={customStyles.inputContainer}
            style={customStyles.editInput}
            onChange={this.onInputChange}
            label="Billing Address 2"
            value={billingAddress2}
            name="billingAddress2"
          />
        </div>

        <div style={customStyles.inputsContainer}>
          <Input
            containerStyle={customStyles.inputContainer}
            style={customStyles.editInput}
            onChange={this.onInputChange}
            label="City"
            value={city}
            name="city"
            error={errors.city}
          />

          <Dropdown
            containerStyle={customStyles.inputContainer}
            options={Constants.stateOptions.values}
            onOptionChange={this.onBillingStateChange}
            label="State"
            value={state}
          />

          <Input
            containerStyle={customStyles.inputContainer}
            style={customStyles.editInput}
            onChange={this.onInputChange}
            label="Zip Code"
            value={zip}
            name="zip"
            type="number"
            error={errors.zip}
          />
        </div> */}

        {/* <div style={customStyles.switchContainer}>
          <Switch
            checked={sendNotifications}
            name="sendNotifications"
            onChange={this.onSwitchChange}
          />
          <p style={customStyles.switchText}>
            Send notifications of new invoices to {billingEmail}
          </p>
        </div> */}
        {showSuccess && (
          <div>
            <SuccessBox message="Billing information updated" />
          </div>
        )}

        {errors.save && (
          <div>
            <ErrorBox error={errors.save} />
          </div>
        )}

        <Button
          title="Save"
          color="lt-blue-auto"
          style={customStyles.saveBtn}
          loading={loading}
          onClick={this.onClickSaveBtn}
        />
        <div style={{ ...customStyles.divider, ...customStyles.dividerShort }} />

        <h3 style={customStyles.subTitle} className={styles.subTitle}>
          Value-Added Tax (VAT) Information
        </h3>

        {existingTaxIds
          .filter(taxId => !taxIdsToBeDeleted.includes(taxId.id))
          .map((taxId, index) => {
            return (
              <div key={index} style={customStyles.taxInputsContainer}>
                <Dropdown
                  containerStyle={customStyles.inputContainer}
                  options={TAX_ID_TYPE_OPTIONS}
                  onOptionChange={() => { }}
                  label="Billing Country Or Region"
                  value={this.getSelectedTaxIdTypeObject(taxId.type)}
                  disabled={true}
                />

                <Input
                  containerStyle={customStyles.inputContainer}
                  style={customStyles.editInput}
                  onChange={() => { }}
                  label="VAT Number"
                  value={taxId.value}
                  disabled={true}
                  name="taxIdValue"
                  error={errors.taxIdValue}
                />
                <p style={customStyles.deleteAction}>
                  <DeleteIcon
                    index={index}
                    style={customStyles.deleteBtn}
                    onClick={() => this.onRemoveTaxId(taxId.id)}
                  />
                </p>
              </div>
            );
          })}
        {newTaxIds.map((newTaxId, index) => {
          return (
            <div key={index} style={customStyles.taxInputsContainer}>
              <Dropdown
                containerStyle={customStyles.inputContainer}
                options={TAX_ID_TYPE_OPTIONS}
                onOptionChange={val => this.onTaxIdTypeChange(val, index)}
                label="Billing Country Or Region"
                value={this.getSelectedTaxIdTypeObject(newTaxId.type)}
              />

              <Input
                containerStyle={customStyles.inputContainer}
                style={customStyles.editInput}
                onChange={e => this.onTaxIdValueChange(e, index)}
                label="VAT Number"
                value={newTaxId.value}
                name="value"
                error={errors.taxIdValue}
                placeholder={this.getTaxIdValuePlaceHolder(index)}
              />
            </div>
          );
        })}
        <div className={styles.taxIdsBtnContainer}>
          <Button
            onClick={this.onAddTaxIdClick}
            style={customStyles.addTaxIdBtn}
            color="outline"
            title="+ Add Tax ID"
          />
          <Button
            title="Save"
            color="lt-blue-auto"
            loading={loading}
            disabled={this.isTaxIdChangeDisabled()}
            onClick={this.onClickTaxSaveBtn}
          />
        </div>
        {showTaxSuccess && (
          <div>
            <SuccessBox message="VAT information has been updated" />
          </div>
        )}

        {errors.saveTax && (
          <div>
            <ErrorBox error={errors.saveTax} />
          </div>
        )}

        {/* <div style={customStyles.switchContainer}>
          <Switch
            checked={sendNotifications}
            name="sendNotifications"
            onChange={this.onSwitchChange}
          />
          <p style={customStyles.switchText}>
            Send notifications of new invoices to {billingEmail}
          </p>
        </div> */}

        <div style={customStyles.divider} />

        <h3 className={styles.subTitle}>Invoice History</h3>

        <section style={customStyles.table}>
          <header className={styles.tableRow}>
            <div className={styles.tableColumn}>
              <h3>ID</h3>
            </div>
            <div className={styles.tableColumn}>
              <h3>Date</h3>
            </div>
            <div className={styles.tableColumn}>
              <h3>Amount</h3>
            </div>

            <div className={styles.tableColumn}>
              <h3>Status</h3>
            </div>

            <div className={styles.tableColumn} />
          </header>
          {invoicesError.error && <ErrorBox error={invoicesError.error} />}
          {!invoicesError.error && invoices.length < 1 ? (
            <p>Sorry no invoice history found</p>
          ) : (
            invoices.map((invoice, index) => {
              return (
                <div key={index} className={styles.tableRow}>
                  <div className={styles.tableColumn}>
                    <p>{invoice.id}</p>
                  </div>
                  <div className={styles.tableColumn}>
                    <p>{`${moment.unix(invoice.created).format('L')}`}</p>
                  </div>
                  <div className={styles.tableColumn}>
                    <p>{`$${invoice.total / 100}`}</p>
                  </div>
                  <div className={styles.tableColumn}>
                    <p>{`${invoice.status.toUpperCase()}`}</p>
                  </div>
                  <div className={styles.tableColumn}>
                    <a
                      href={invoice.invoice_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      name={index}
                      style={customStyles.link}
                    >
                      Download Invoice
                    </a>
                  </div>
                </div>
              );
            })
          )}
        </section>
      </div>
    );
  }
}

Invoices.propTypes = {
  updateUserAccount: PropTypes.func,
  getUserSubscriptions: PropTypes.func,
  accounts: PropTypes.object,
  activeDomain: PropTypes.object
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  activeDomain: state.activeDomain
});

const mapDispatchToProps = dispatch => {
  return {
    updateUserAccount: (accountId, data) => dispatch(Account.updateUserAccount(accountId, data)),
    getUserSubscriptions: customerId => dispatch(Account.getUserSubscriptions(customerId, true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

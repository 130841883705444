import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressBar.module.scss';

class ProgressBar extends PureComponent {
  state = {
    fillStyle: { width: 0 }
  };

  componentDidMount() {
    if (this.props.color) {
      this.setState({ fillStyle: { ...this.state.fillStyle, backgroundColor: this.props.color } });
    }
    if (this.props.percentage)
      setTimeout(() => {
        this.setState({
          fillStyle: { ...this.state.fillStyle, width: `${this.props.percentage}%` }
        });
      }, 1000);
  }

  componentDidUpdate() {
    if (this.props.color && this.props.color !== this.state.fillStyle.backgroundColor) {
      this.setState({ fillStyle: { ...this.state.fillStyle, backgroundColor: this.props.color } });
    }
    if (this.props.percentage && `${this.props.percentage}%` !== this.state.fillStyle.width)
      setTimeout(() => {
        this.setState({
          fillStyle: { ...this.state.fillStyle, width: `${this.props.percentage}%` }
        });
      }, 1000);
  }

  render() {
    const { style } = this.props;

    return (
      <div className={styles.bar} style={style}>
        <div className={styles.fill} style={this.state.fillStyle} />
      </div>
    );
  }
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  style: PropTypes.object,
  color: PropTypes.string
};

ProgressBar.defaultProps = {
  style: {}
};

export default ProgressBar;

import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './RegisterNew.module.scss';
import LOGO from '../../assets/small-logo.svg';
import { ReactComponent as MAINLOGO } from '../../assets/main-logo.svg';
import SignUpForm from '../../containers/Register/components/StepForms/SignUpForm';
import User from '../../redux/actions/User';
import Account from '../../redux/actions/Account';
import Validation from '../../utils/Validation';
import Payments from '../../api/Payments';
import Constants from '../../utils/Constants';
import Utils from '../../utils/Utils';
import RegisterSteps from '../../containers/Register/RegisterSteps';
import RegisterTestimonial from '../../containers/Register/components/RegisterTestimonial/RegisterTestimonial';

let leadHandled = false;
class RegisterNew extends PureComponent {
  state = {
    email: '',
    discount: 0,
    couponError: '',
    activeForm: 0,
    toDashboard: false,
    toSignIn: false
  };

  componentDidMount = async () => {
    console.log('PKDEBUG', process.env.REACT_APP_STRIPE_KEY);
    console.log('PKNEW', Constants.stripePublicKey);
    window.addEventListener('beforeunload', () => {
      this.handleLeads();
    });

    // For browsers other than Chrome;
    window.addEventListener('unload', () => {
      this.handleLeads();
    });

    document.getElementById('favicon').href = 'signup-favicon.ico';

    const { offer_id: couponIdFromQuery, afmc: otherCouponIdFromQuery } = qs.parse(
      window.location.search,
      {
        ignoreQueryPrefix: true
      }
    );

    const afmcCoupon = otherCouponIdFromQuery || Utils.getCookie('afmc');
    const offerCoupon = couponIdFromQuery || Utils.getCookie('offer_id');

    const couponRes = { error: '', discount: 0 };

    if (afmcCoupon) {
      try {
        const coupon = await Payments.getCouponDetails(afmcCoupon);
        if (coupon) {
          Utils.setCookie('offer_id', '', -1);
          couponRes.discount = coupon.percent_off;
          this.setState({ discount: coupon.percent_off });
        }
      } catch (error) {
        Utils.setCookie('afmc', '', -1);
        couponRes.error = error.message;
      }
    }

    if (offerCoupon && !couponRes.discount) {
      try {
        const otherCoupon = await Payments.getCouponDetails(offerCoupon);
        if (otherCoupon) {
          Utils.setCookie('afmc', '', -1);
          couponRes.discount = otherCoupon.percent_off;
          this.setState({ discount: otherCoupon.percent_off });
        }
      } catch (err) {
        Utils.setCookie('offer_id', '', -1);
        couponRes.error = err.message;
      }
    }

    if ((afmcCoupon || offerCoupon) && !couponRes.discount) {
      this.setState({ couponError: couponRes.error });
    }

    const { accounts, auth } = this.props;
    console.log('componentDidMount', auth.user, accounts.data);
    if (auth.user && accounts.data) {
      console.log('Checking for current reg step');
      if (
        Validation.userHasAllRequiredFields(auth.user, accounts.data, accounts.subscription) &&
        Validation.userHasSubscription(accounts.subscription)
      ) {
        console.log('STEP - to dashboard');
        this.setState({ toDashboard: true });
      } else if (
        accounts.data.initial_plan &&
        this.state.activeForm !== 4 &&
        !Validation.userHasSubscription(accounts.subscription)
      ) {
        console.log('STEP - to 4');
        this.setState({ activeForm: 4 });
      }
      // else if (
      //   !accounts.data.initial_plan &&
      //   auth.user.first_name === null &&
      //   this.state.activeForm !== 1
      // ) {
      //   console.log('STEP - to 1');
      //   this.setState({ activeForm: 1 });
      // } else if (
      //   !accounts.data.initial_plan &&
      //   auth.user.work_for_agency === null &&
      //   this.state.activeForm !== 2
      // ) {
      //   console.log('STEP - to 2');
      //   this.setState({ activeForm: 2 });
      // } else if (
      //   (!accounts.data.initial_plan || accounts.data.initial_plan.plan.includes('Enterprise')) &&
      //   auth.user.work_for_agency !== null &&
      //   this.state.activeForm !== 3
      // ) {
      //   console.log('STEP - to 3');
      //   this.setState({ activeForm: 3 });
      // }
      else if (this.state.activeForm !== 3) {
        console.log('STEP - to 3');
        this.setState({ activeForm: 3 });
      } else {
        console.log('STEP - No step move to perform');
      }
    }
  };

  handleLeads = () => {
    const { email, activeForm } = this.state;
    // User has left Registration on SignUpForm
    if (activeForm === 0 && !leadHandled) {
      leadHandled = true; // Check to make sure its not called twice.
      const data = {
        email
      };

      const invalidEmail = Validation.validateForm(data);
      if (invalidEmail) {
        return;
      }

      User.createLead(data);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.handleLeads);
    window.removeEventListener('unload', this.handleLeads);
  };

  componentDidUpdate = prevProps => {
    const { accounts, auth } = this.props;
    const { accounts: prevAccounts } = prevProps;
    console.log('componentDidUpdate', auth.user, accounts.data);
    if (!prevAccounts.data && auth.user && accounts.data && this.state.activeForm === 0) {
      console.log('Checking for current reg step');
      if (
        Validation.userHasAllRequiredFields(auth.user, accounts.data, accounts.subscription) &&
        Validation.userHasSubscription(accounts.subscription)
      ) {
        console.log('STEP - to dashboard');
        this.setState({ toDashboard: true });
      } else if (
        accounts.data.initial_plan &&
        this.state.activeForm !== 4 &&
        !Validation.userHasSubscription(accounts.subscription)
      ) {
        console.log('STEP - to 4');
        this.setState({ activeForm: 4 });
      }
      // else if (
      //   !accounts.data.initial_plan &&
      //   auth.user.first_name === null &&
      //   this.state.activeForm !== 1
      // ) {
      //   console.log('STEP - to 1');
      //   this.setState({ activeForm: 1 });
      // } else if (
      //   !accounts.data.initial_plan &&
      //   auth.user.work_for_agency === null &&
      //   this.state.activeForm !== 2
      // ) {
      //   console.log('STEP - to 2');
      //   this.setState({ activeForm: 2 });
      // } else if (
      //   (!accounts.data.initial_plan || accounts.data.initial_plan.plan.includes('Enterprise')) &&
      //   auth.user.work_for_agency !== null &&
      //   this.state.activeForm !== 3
      // ) {
      //   console.log('STEP - to 3');
      //   this.setState({ activeForm: 3 });
      // }
      else if (this.state.activeForm !== 3) {
        console.log('STEP - to 3');
        this.setState({ activeForm: 3 });
      } else {
        console.log('STEP - No step move to perform');
      }
    }
  };

  setEmail = email => {
    this.setState({ email });
  };

  onClickNextSignupClick = () => {
    this.setState({ activeForm: 1 });
  };

  updateActiveForm = step => {
    this.setState({ activeForm: step });
  };

  render() {
    const { activeForm, email, discount, couponError } = this.state;
    const { createUser } = this.props;

    if (this.state.toDashboard === true) {
      console.log('going to dashboard!');
      return <Redirect to="/integrations/fraud-blocker-tracker" />;
    }

    if (this.state.toSignIn === true) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={styles.all}>
        <Helmet>
          <title>Register | Fraud Blocker</title>
        </Helmet>
        <>
          {activeForm === 0 ? (
            <div className={styles.content}>
              <div className={styles.rightContentContainer}>
                <div className={styles.regForm}>
                  <img src={LOGO} className={styles.logo} />
                  <SignUpForm
                    setEmail={this.setEmail}
                    email={email}
                    discount={discount}
                    createUser={createUser}
                    onClickNext={this.onClickNextSignupClick}
                  />
                </div>
              </div>
              <div className={styles.leftContentContainer}>
                <RegisterTestimonial />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.stepContent}>
                <div className={styles.logoContainer}>
                  <div className={styles.mHide}>
                    <MAINLOGO />
                  </div>

                  <div className={styles.mShow}>
                    <img src={LOGO} className={styles.logo} />
                  </div>
                </div>
                <div className={styles.stepsContent}>
                  <div className={styles.stepsContentInner}>
                    <RegisterSteps
                      discount={discount}
                      couponError={couponError}
                      activeForm={activeForm}
                      updatedActiveForm={this.updateActiveForm}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.footerSec}>
                <span>©2024 All Rights Reserved.</span> Fraud Blocker™ is a registered trademark of
                Fraud Blocker LLC.
                <div>
                  <a
                    className="blue"
                    href="https://fraudblocker.com/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    className="blue"
                    href="https://fraudblocker.com/terms/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms
                  </a>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    );
  }
}

RegisterNew.propTypes = {
  auth: PropTypes.object,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  accounts: PropTypes.object,
  updateUserAccount: PropTypes.func,
  getUserAccounts: PropTypes.func,
  fetchLatestAccount: PropTypes.func,
  activeDomain: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  activeDomain: state.activeDomain
});

const mapDispatchToProps = dispatch => {
  return {
    createUser: data => dispatch(User.createUser(data)),
    updateUser: (id, data) => dispatch(User.updateUser(id, data)),
    updateUserAccount: (accountId, data) => dispatch(Account.updateUserAccount(accountId, data)),
    getUserAccounts: id => dispatch(Account.getUserAccounts(id)),
    fetchLatestAccount: id => dispatch(Account.fetchLatestAccount(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNew);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from '../../RegisterSteps.module.scss';
import Button from '../../../../components/Button/Button';
import Payments from '../../../../api/Payments';
import Constants from '../../../../utils/Constants';
import PRO from '../../../../assets/pro.svg';
import STATER from '../../../../assets/stater.svg';
import CREDITCARD from '../../../../assets/credit-card.svg';
import PAYPAL from '../../../../assets/paypal-gray.svg';
import ENTERPRISE from '../../../../assets/enterprise.svg';
import BRANDON from '../../../../assets/brandon-headshot.svg';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import RangeSlider from '../../../../components/RangeSlider/RangeSlider';
import { ReactComponent as UnlockDiscountIcon } from '../../../../assets/unlock-discount.svg';
import Utils from '../../../../utils/Utils';
import ErrorBox from '../../../../components/ErrorBox/ErrorBox';

const { currencyOptions, clicksValueMap } = Constants;

const planOptions = ['Pro', 'Starter', 'Enterprise'];

class AccountCreationForm extends PureComponent {
  constructor(props) {
    super(props);

    const { accounts } = props;
    const { data = {} } = accounts;
    this.state = {
      city: data.billing_city || '',
      state: data.billing_state ? { value: data.billing_state, label: data.billing_state } : '',
      zip: data.billing_zip || '',
      errors: {},
      loading: false,
      formHasBeenSubmitted: false,
      currency: currencyOptions[0],
      selectedPlan: '',
      selectedBilling: {},
      billingOptions: [],
      paymentMethod: 'CreditCard',
      allPlans: [],
      proClicks: 10000
    };
  }

  componentWillUnmount() {
    const { formHasBeenSubmitted } = this.state;
    /**
     * If user leaves form without hitting next,
     * update User with every non empty field in DB
     */
    if (!formHasBeenSubmitted) {
      // this.updateUserWithNonEmptyFields();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 100);
    console.log(window.gtag);
    this.fetchAllPlans();
    if (this.props.currency) {
      this.setState({
        currency: currencyOptions.find(item => item.value === this.props.currency)
      });
    }
    if (window.gtag) {
      const script = document.createElement('script');
      const inlineScript = document.createTextNode(
        `gtag('event', 'conversion', {'send_to': 'AW-743398152/qC2mCIn-wa0DEIi2veIC'});`
      );
      script.appendChild(inlineScript);
      document.head.appendChild(script);
    }
  }

  fetchAllPlans = async () => {
    try {
      const { accounts } = this.props;
      const result = await Payments.getAllPlans();
      const billingOptions = [];
      result.data.forEach(plan => {
        const option = plan.nickname.split(' ')[0];
        const billingOption = {
          id: plan.id,
          plan: option,
          nickname: plan.nickname,
          trial_period_days: plan.trial_period_days || 30,
          interval: plan.interval,
          interval_count: plan.interval_count,
          price: plan.amount / 100,
          clicks: parseInt(plan.metadata.clicks, 10),
          domains: plan.metadata.domains
        };
        billingOptions.push(billingOption);
      });

      if (result) {
        const defaultValues = {
          proClicks: billingOptions.find(
            item =>
              item.plan === planOptions[0] && item.interval === 'month' && item.interval_count === 1
          ).clicks,
          selectedPlan: planOptions[1],
          selectedBilling: billingOptions.find(
            item =>
              item.plan === planOptions[1] && item.interval === 'month' && item.interval_count === 1
          )
        };
        if (
          !billingOptions.find(
            item =>
              item.plan === planOptions[0] &&
              item.interval === 'year' &&
              item.clicks === defaultValues.proClicks
          )
        ) {
          defaultValues.proClicks = 10000;
        }
        this.setState({
          allPlans: result.data,
          selectedPlan:
            accounts.data && accounts.data.initial_plan
              ? accounts.data.initial_plan.plan || defaultValues.selectedPlan
              : defaultValues.selectedPlan,
          selectedBilling:
            accounts.data && accounts.data.initial_plan
              ? billingOptions.find(
                item =>
                  item.plan === accounts.data.initial_plan.plan &&
                  item.interval === accounts.data.initial_plan.interval &&
                  item.interval_count === accounts.data.initial_plan.interval_count &&
                  item.clicks === accounts.data.initial_plan.clicks
              ) || defaultValues.selectedBilling
              : defaultValues.selectedBilling, // could prioritize auto selecting defaults here by adding to comparisons.
          billingOptions,
          proClicks:
            accounts.data && accounts.data.initial_plan && accounts.data.initial_plan.plan === 'Pro'
              ? accounts.data.initial_plan.clicks || defaultValues.proClicks
              : defaultValues.proClicks
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onRangeSliderLoaded = () => {
    // const rangeInput = document.getElementById('proClicksInput');
    // const progress =
    //   ((this.state.proClicks - rangeInput.min) / (rangeInput.max - rangeInput.min)) * 100;
    // // eslint-disable-next-line no-param-reassign
    // rangeInput.style.background = `linear-gradient(to right, #17d384 ${progress}%, #c8d0da ${progress}%)`;
  };

  onSelectOption = (type, value) => {
    this.setState({ [type]: value });
  };

  handleChange = change => {
    // console.log('[change]', change);
    if (change.complete) {
      this.setState({ [change.elementType]: true });
    }
  };

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  changePlan = plan => {
    this.setState({
      selectedPlan: plan.plan,
      selectedBilling: plan
    });
  };

  getPlanClicks = plan => {
    const { selectedBilling = {}, billingOptions } = this.state;
    if (billingOptions.length && selectedBilling.id) {
      if (plan === 'Starter' && selectedBilling.interval === 'month') {
        const found = billingOptions.find(
          item =>
            item.plan.includes(plan) &&
            item.interval === 'month' &&
            item.interval_count === selectedBilling.interval_count
        );
        if (found) {
          return found.clicks;
        }
      } else if (plan === 'Starter' && selectedBilling.interval === 'year') {
        const found = billingOptions.find(
          item => item.plan.includes(plan) && item.interval === 'year'
        );
        if (found) {
          return found.clicks;
        }
      } else if (plan === 'Pro') {
        return this.state.proClicks;
      }
    }
    return '';
  };

  updateClicks = val => {
    const { billingOptions, selectedBilling = {} } = this.state;
    let clicks = val;
    if (clicks > 10000 && clicks <= 20000) {
      clicks = 25000;
    } else if (clicks > 20000 && clicks <= 30000) {
      clicks = 50000;
    } else if (clicks > 30000 && clicks <= 40000) {
      clicks = 75000;
    } else if (clicks > 40000) {
      clicks = 100000;
    }
    this.setState({
      proClicks: clicks,
      selectedPlan: planOptions[0],
      selectedBilling: billingOptions.find(
        item =>
          item.plan === 'Pro' &&
          item.clicks === clicks &&
          item.interval === selectedBilling.interval &&
          item.interval_count === selectedBilling.interval_count
      )
    });
  };

  onClickNextAccountCreation = async (planSelected, currency = 'USD', scheduleCall = false) => {
    console.log(1, planSelected, currency);
    const { user, updateUser, updateUserAccount, accounts, onClickNext } = this.props;
    let createCustomerResult = null;
    const updateAccountBody = {};
    try {
      if (user.currency !== currency) {
        await updateUser(user.id, { currency });
      }
      if (!accounts.data || !accounts.data.stripe_token) {
        const createCustomerBody = {
          account_id: user.account_id,
          email: user.email,
          name: user.email,
          metadata: {
            account_id: user.account_id
          },
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            postal_code: ''
          }
        };
        createCustomerResult = await Payments.createCustomer(createCustomerBody);
      }

      if (createCustomerResult) {
        updateAccountBody.stripe_token = createCustomerResult.id;
      }

      if (
        !accounts.data ||
        !accounts.data.initial_plan ||
        planSelected.id !== accounts.data.initial_plan.id
      ) {
        updateAccountBody.initial_plan = JSON.stringify(planSelected);
      }
      if (Object.keys(updateAccountBody).length) {
        const customerResult = await updateUserAccount(user.account_id, updateAccountBody);
        if (customerResult) {
          console.log(`Current ${this.state.activeForm}, + 1`);
          if (scheduleCall) {
            this.handleScheduleCall();
          } else {
            onClickNext();
          }
        }
      } else {
        console.log(`Current ${this.state.activeForm}, + 1`);
        if (scheduleCall) {
          this.handleScheduleCall();
        } else {
          onClickNext();
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  saveAccountDetails = async (scheduleCall = false) => {
    this.setState({ loading: true, formHasBeenSubmitted: true });
    const { selectedBilling, currency } = this.state;
    try {
      await this.onClickNextAccountCreation(selectedBilling, currency.value, scheduleCall);
      window.Intercom('trackEvent', 'register-step-4');
    } catch (error) {
      console.log(error);
      this.setState({ errors: { accountCreation: error.message }, loading: false });
    }
  };

  getPrice = price => {
    const { discount } = this.props;
    if (discount !== 0) {
      return price - (price * discount) / 100;
    }
    return price;
  };

  handleScheduleCall = () => {
    this.setState({ showScheduleCall: true }, () => {
      const existingScript = document.getElementById('calendy-script');
      if (!existingScript) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.id = 'calendy-script';
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://assets.calendly.com/assets/external/widget.css';
        document.body.appendChild(script);
        document.body.appendChild(link);
      }
    });
  };

  render() {
    const {
      selectedPlan,
      selectedBilling,
      loading,
      billingOptions,
      errors,
      paymentMethod,
      proClicks,
      currency,
      showScheduleCall
    } = this.state;

    const { conversionRates, couponError, discount } = this.props;
    return (
      <div className={`${styles.slideLeft} ${styles.planBox} ${styles.active}`}>
        {!showScheduleCall ? (
          !!billingOptions.length &&
          selectedBilling.id && (
            <>
              <h2 className={styles.headingPlan}>
                Select a <span className={styles.mHide}>fraud protection</span> plan{' '}
              </h2>
              <div className={styles.currencyWrap}>
                <Dropdown
                  options={currencyOptions}
                  value={currency}
                  selectClass={styles.currencySelect}
                  name="currency"
                  onOptionChange={value => this.onSelectOption('currency', value)}
                />
              </div>
              <div className={styles.planSection}>
                <div className={styles.planValue}>
                  <div className={styles.label}>Plans</div>
                  <div className={styles.selectPlan}>
                    <div className={styles.multiCheckbox}>
                      <div>
                        <input
                          type="radio"
                          name="plan"
                          value="Starter"
                          checked={selectedBilling.plan === 'Starter'}
                          onChange={() =>
                            this.changePlan(
                              billingOptions.find(
                                option =>
                                  option.plan === 'Starter' &&
                                  option.interval === selectedBilling.interval &&
                                  option.interval_count === 1
                              )
                            )
                          }
                        />
                        <label>
                          <span className={styles.imgContainer}>
                            <img src={STATER} alt="Starter" />
                          </span>
                          <span className={styles.labelContent}>
                            <span className={styles.planHeading}>Starter</span>
                            <span className={styles.planSubHeading}>
                              Great for small businesses
                            </span>
                          </span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="plan"
                          value="Pro"
                          checked={selectedBilling.plan === 'Pro'}
                          onChange={() =>
                            this.changePlan(
                              billingOptions.find(
                                option =>
                                  option.plan === 'Pro' &&
                                  option.interval === selectedBilling.interval &&
                                  option.interval_count === 1 &&
                                  option.clicks === proClicks
                              )
                            )
                          }
                        />
                        <label>
                          <span className={styles.imgContainer}>
                            <img src={PRO} alt="Pro" />
                          </span>
                          <span className={styles.labelContent}>
                            <span className={styles.planHeading}>Pro</span>
                            <span className={styles.planSubHeading}>
                              Designed for marketers and agencies
                            </span>
                          </span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="plan"
                          value="Enterprise"
                          checked={selectedBilling.plan === 'Enterprise'}
                          onChange={() =>
                            this.changePlan(
                              billingOptions.find(
                                option =>
                                  option.plan === 'Enterprise' &&
                                  option.interval === selectedBilling.interval &&
                                  option.interval_count === 1
                              )
                            )
                          }
                        />
                        <label>
                          <span className={styles.imgContainer}>
                            <img src={ENTERPRISE} alt="Enterprise" />
                          </span>
                          <span className={styles.labelContent}>
                            <span className={styles.planHeading}>Enterprise</span>
                            <span className={styles.planSubHeading}>
                              High-volume solutions for corporations
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={styles.label}>Term</div>
                  <div className={styles.selectTerm}>
                    <div className={styles.multiCheckbox}>
                      <div>
                        <input
                          type="radio"
                          name="term"
                          value="Monthly"
                          checked={
                            selectedBilling.interval === 'month' &&
                            selectedBilling.interval_count === 1
                          }
                          onChange={() =>
                            this.onSelectOption(
                              'selectedBilling',
                              billingOptions &&
                              billingOptions.find(
                                item =>
                                  item.plan === selectedPlan &&
                                  (['Starter', 'Enterprise'].includes(selectedPlan) ||
                                    item.clicks === proClicks) &&
                                  item.interval === 'month' &&
                                  item.interval_count === 1
                              )
                            )
                          }
                        />
                        <label>Monthly</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="term"
                          value="Quarterly"
                          checked={
                            selectedBilling.interval === 'month' &&
                            selectedBilling.interval_count === 3
                          }
                          onChange={() =>
                            this.onSelectOption(
                              'selectedBilling',
                              billingOptions &&
                              billingOptions.find(
                                item =>
                                  item.plan === selectedPlan &&
                                  (['Starter', 'Enterprise'].includes(selectedPlan) ||
                                    item.clicks === proClicks) &&
                                  item.interval === 'month' &&
                                  item.interval_count === 3
                              )
                            )
                          }
                        />
                        <label>
                          Quarterly <span className={styles.info}>save 12%</span>
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="term"
                          value="Annual"
                          checked={selectedBilling.interval === 'year'}
                          onChange={() =>
                            this.onSelectOption(
                              'selectedBilling',
                              billingOptions &&
                              billingOptions.find(
                                item =>
                                  item.plan === selectedPlan &&
                                  (['Starter', 'Enterprise'].includes(selectedPlan) ||
                                    item.clicks === proClicks) &&
                                  item.interval === 'year'
                              )
                            )
                          }
                        />
                        <label>
                          Annual <span className={styles.info}>save 20%</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={styles.label}>Method</div>
                  <div className={styles.selectMethod}>
                    <div className={styles.multiCheckbox}>
                      <div>
                        <input
                          type="radio"
                          name="method"
                          disabled={selectedBilling.plan === 'Enterprise' && 'disabled'}
                          // className={showEnterprise && `${styles.disabled}`}
                          value="Credit Card"
                          checked={paymentMethod === 'CreditCard'}
                          onChange={() => this.onSelectOption('paymentMethod', 'CreditCard')}
                        />
                        <label>
                          <img src={CREDITCARD} alt="Credit Card" /> Credit Card
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          disabled
                          name="method"
                          value="Paypal"
                          checked={paymentMethod === 'Paypal'}
                          onChange={() => { }}
                        />
                        <label>
                          <img src={PAYPAL} alt="Paypal" /> Paypal{' '}
                          <span className={styles.info}>Coming soon</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {(errors.accountCreation || couponError) && (
                    <ErrorBox
                      errorStyle={{ marginRight: '35px' }}
                      error={errors.accountCreation || couponError}
                    />
                  )}
                  <div className={styles.stepsButton}>
                    {selectedBilling.plan === 'Enterprise' ? (
                      <Button
                        title="Schedule a call"
                        type="button"
                        customClassNames="acctCreation__nextBtn"
                        onClick={() => this.saveAccountDetails(true)}
                        loading={loading}
                        color="new-green"
                      />
                    ) : (
                      <Button
                        title="Next"
                        type="submit"
                        onClick={this.saveAccountDetails}
                        customClassNames="acctCreation__nextBtn"
                        loading={loading}
                        color="new-green"
                      />
                    )}
                  </div>
                </div>
                <div className={styles.planDesc}>
                  <div className={styles.planInfo}>
                    {selectedBilling.plan === 'Enterprise' ? (
                      <>
                        <div className={`${styles.planAmount} ${styles.contactHeading}`}>
                          Contact Us
                        </div>
                        <div className={styles.demoShedule}>
                          Please schedule a call to view a demo and discuss our advanced plan
                          options.
                        </div>
                        <div className={styles.planFeature}>
                          <div className={styles.label}>Key plan features</div>
                          <ul>
                            <li>250,000+ ad clicks /month</li>

                            <li>Unlimited websites</li>
                            <li>Unlimited team members</li>
                            <li>12-month data retention</li>
                            <li>Ad protection for Google & Facebook</li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <>
                        {discount !== 0 && (
                          <div className={`${styles.discountApplied} ${styles.discountSticky}`}>
                            <UnlockDiscountIcon />
                            {discount}% discount applied!
                          </div>
                        )}
                        <div className={styles.planAmount}>
                          {billingOptions.length &&
                            selectedBilling &&
                            selectedBilling.id &&
                            Utils.convertToCurrency(
                              conversionRates,
                              this.getPrice(
                                billingOptions.find(
                                  item =>
                                    item.plan === selectedBilling.plan &&
                                    item.interval === selectedBilling.interval &&
                                    item.interval_count === selectedBilling.interval_count &&
                                    (selectedBilling.plan === 'Pro'
                                      ? item.clicks === proClicks
                                      : true)
                                ).price
                              ),
                              currency.value
                            )}
                          <span>
                            /
                            {selectedBilling.interval_count === 3
                              ? 'quarter'
                              : selectedBilling.interval}
                          </span>
                        </div>
                        {selectedBilling.plan === 'Pro' && (
                          <div className={styles.planAmountSlider}>
                            <div className={styles.label}>Adjust plan options</div>
                            <RangeSlider
                              id="proClicksInput"
                              onInit={this.onRangeSliderLoaded}
                              onUpdate={this.onRangeSliderLoaded}
                              handleChange={this.updateClicks}
                              markers={[10000, 20000, 30000, 40000, 50000]}
                              value={clicksValueMap[proClicks]}
                            />
                          </div>
                        )}
                        <div className={styles.planFeature}>
                          <div className={styles.label}>Key plan features</div>
                          <ul>
                            <li>
                              {this.getPlanClicks(selectedBilling.plan).toLocaleString('en-US', {
                                maximumFractionDigits: 1
                              })}{' '}
                              ad clicks /month
                            </li>

                            <li>
                              {billingOptions.length && selectedBilling && selectedBilling.id ? (
                                <>
                                  {billingOptions.find(
                                    item =>
                                      item.plan === selectedBilling.plan &&
                                      item.interval === selectedBilling.interval &&
                                      item.interval_count === selectedBilling.interval_count &&
                                      (['Starter', 'Enterprise'].includes(selectedBilling.plan) ||
                                        item.clicks === proClicks)
                                  ).domains !== 'unlimited'
                                    ? billingOptions.find(
                                      item =>
                                        item.plan === selectedBilling.plan &&
                                        item.interval === selectedBilling.interval &&
                                        item.interval_count === selectedBilling.interval_count &&
                                        (['Starter', 'Enterprise'].includes(
                                          selectedBilling.plan
                                        ) ||
                                          item.clicks === proClicks)
                                    ).domains
                                    : 'Unlimited'}{' '}
                                  websites
                                </>
                              ) : null}
                            </li>
                            <li>3 team members</li>
                            <li>90-day data retention</li>
                            <li>Ad protection for Google & Facebook</li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={styles.planIncludes}>
                    <div className={styles.label}>All plans also include</div>
                    <ul>
                      <li>Automated blocking</li>

                      <li>Fraud traffic scoring</li>
                      <li>Device fingerprinting</li>
                      <li>VPN & proxy blocking</li>
                      <li>Customizable blocking rules</li>
                      <li>User management controls</li>
                      <li>Exportable IPs to .csv</li>
                      <li>Premium support</li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          <>
            <h2 className={styles.headingPlan}>Schedule a call today</h2>
            <div className={styles.scheduleCallWrap}>
              <div className={styles.scheduleCallFounder}>
                <img src={BRANDON} alt="Brandon" />
                <div className={styles.scheduleCallName}>Brandon Tome</div>
                <div className={styles.scheduleCallTitle}>Co-founder & CGO, Fraud Blocker</div>
                <div className={styles.scheduleCallMessage}>👋 Hi there.</div>{' '}
                <div className={`${styles.scheduleCallMessage} ${styles.scheduleCallGap}`}>
                  Thanks for checking out Fraud Blocker, a leader in ad fraud protection.
                </div>
                <div className={styles.scheduleCallMessage}>
                  We have a number of custom options for clients with high-volume needs so please
                  schedule a call with me so I can show you a demo and discuss what works best for
                  you.
                </div>
              </div>
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/fraud-blocker/demo?hide_event_type_details=1&hide_gdpr_banner=1&text_color=222222&primary_color=17d384"
                style={{ minWidth: '320px', height: '700px' }}
              ></div>
            </div>
          </>
        )}
      </div>
    );
  }
}

AccountCreationForm.propTypes = {
  onClickNext: PropTypes.func,
  onClickBack: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserAccount: PropTypes.func,
  user: PropTypes.object,
  accounts: PropTypes.object,
  currency: PropTypes.string,
  conversionRates: PropTypes.any,
  discount: PropTypes.number,
  couponError: PropTypes.string
};

export default AccountCreationForm;

import React, { PureComponent } from 'react';
import styles from './Account.module.scss';

class Terms extends PureComponent {
  render() {
    return (
      <div className={styles.content}>
        <h1>Terms & Policies</h1>
      </div>
    );
  }
}

export default Terms;

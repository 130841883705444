import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './ResultTable.module.scss';

export class FilterSelect extends PureComponent {
  render() {
    const { filter, onChange, options } = this.props;
    return (
      <div className={styles.gridDropdownFilterWrap}>
        <select
          onChange={event => onChange(event.target.value)}
          className={styles.gridDropdownFilter}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Show All</option>
          {options.map(option => {
            return (
              <option key={option.value || option} value={option.value || option}>
                {option.label || option}
              </option>
            );
          })}
        </select>
        <svg
          className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiNativeSelect-icon MuiNativeSelect-iconStandard ${styles.triangleIcon}`}
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="ArrowDropDownIcon"
        >
          <path d="M7 10l5 5 5-5z"></path>
        </svg>
      </div>
    );
  }
}

FilterSelect.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array
};

export const GridDropdownFilter = props => {
  const { item, applyValue, options } = props;

  const handleFilterChange = value => {
    console.log(value);
    applyValue({ ...item, value: value === 'all' ? '' : value });
  };

  return <FilterSelect filter={item} onChange={handleFilterChange} options={options} />;
};

GridDropdownFilter.propTypes = {
  applyValue: PropTypes.func.isRequired,
  item: PropTypes.shape({
    columnField: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    operatorValue: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  options: PropTypes.any
};

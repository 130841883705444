/* eslint-disable react/prop-types */
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class RouteChangeHandler extends PureComponent {
  componentWillMount() {
    this.unlisten = this.props.history.listen(() => {
      window.Intercom('update');
      window.scrollTo(0, 0);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(RouteChangeHandler);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Constants from '../../utils/Constants';
import styles from './IpPopup.module.scss';

import ABOUT_DATA_ICON from '../../assets/aboutdata.svg';

const { countryNameMapping } = Constants;

class IpPopup extends PureComponent {
  getFraudLevel = avg => {
    if (avg >= 7) {
      return 'high';
    }
    if (avg > 3) {
      return 'medium';
    }
    if (avg > 0) {
      return 'low';
    }
    return 'clean';
  };

  onMouseLeave = e => {
    setTimeout(() => {
      this.props.handlePopoverClose(e);
    }, 100);
  };

  render() {
    const { details, targetElem, handlePopoverClose, isOpen } = this.props;
    const riskContributors =
      details && details.riskContributors ? JSON.parse(details.riskContributors) : null;
    return (
      <Popover
        id="ip-popup"
        className={styles.ipPopover}
        open={isOpen}
        anchorEl={targetElem}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={styles.ipPopupContainer} onMouseLeave={this.onMouseLeave}>
          {details && (
            <div className={styles.ipPopupContent}>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <th>IP Address</th>
                    <th>Location</th>
                    <th>Fraud Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={styles.boldValue}>{details.ip}</td>
                    {details && details.country ? (
                      <td>
                        <img
                          style={{ width: '20px', height: '14px' }}
                          src={`flags/${details.country.toLowerCase()}.svg`}
                        />{' '}
                        {countryNameMapping[details.country] || details.country}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td className={styles.boldValue}>{details.riskScore.toFixed(1)}</td>
                  </tr>
                </tbody>
              </table>
              <div className={styles.stats}>
                <div className={styles.leftStats}>
                  <div>
                    <article className={styles.label}>VPN / Datacenter</article>
                    <article
                      className={`${styles.value} ${
                        styles[this.getFraudLevel(riskContributors.dcRiskPoints)]
                      }`}
                    >
                      {this.getFraudLevel(riskContributors.dcRiskPoints)}
                    </article>
                  </div>
                  <div>
                    <article className={styles.label}>Risky Device</article>
                    <article
                      className={`${styles.value} ${
                        styles[this.getFraudLevel(riskContributors.deviceRiskPoints)]
                      }`}
                    >
                      {this.getFraudLevel(riskContributors.deviceRiskPoints)}
                    </article>
                  </div>
                  <div>
                    <article className={styles.label}>Excessive Clicks</article>
                    <article
                      className={`${styles.value} ${
                        styles[this.getFraudLevel(riskContributors.fastClickersRiskPoints)]
                      }`}
                    >
                      {this.getFraudLevel(riskContributors.fastClickersRiskPoints)}
                    </article>
                  </div>
                  <div>
                    <article className={styles.label}>Poor IP History</article>
                    <article
                      className={`${styles.value} ${
                        styles[this.getFraudLevel(riskContributors.historicalClicksRiskPoints)]
                      }`}
                    >
                      {this.getFraudLevel(riskContributors.historicalClicksRiskPoints)}
                    </article>
                  </div>
                </div>
                <div className={styles.rightStats}>
                  <div>
                    <article className={styles.label}>Accidental Clicks</article>
                    <article
                      className={`${styles.value} ${
                        styles[this.getFraudLevel(riskContributors.accidentalClicksRiskPoints)]
                      }`}
                    >
                      {this.getFraudLevel(riskContributors.accidentalClicksRiskPoints)}
                    </article>
                  </div>
                  <div>
                    <article className={styles.label}>Abuse / Very High Risk</article>
                    <article
                      className={`${styles.value} ${
                        styles[this.getFraudLevel(riskContributors.headlessBrowserRiskPoints)]
                      }`}
                    >
                      {this.getFraudLevel(riskContributors.headlessBrowserRiskPoints)}
                    </article>
                  </div>
                  <div>
                    <article className={styles.label}>Risky Geo</article>
                    <article
                      className={`${styles.value} ${
                        styles[this.getFraudLevel(riskContributors.geoRiskPoints)]
                      }`}
                    >
                      {this.getFraudLevel(riskContributors.geoRiskPoints)}
                    </article>
                  </div>
                  <div>
                    <article className={styles.label}>Other Risk</article>
                    <article className={`${styles.value} ${styles.clean}`}>clean</article>
                  </div>
                </div>
              </div>
              <div className={styles.aboutLink}>
                <a
                  href="https://help.fraudblocker.com/en/articles/8127810-what-is-the-ip-clarity-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ABOUT_DATA_ICON} alt="about" />
                  About This Data
                </a>
              </div>
            </div>
          )}
        </div>
      </Popover>
    );
  }
}

IpPopup.propTypes = {
  details: PropTypes.object,
  targetElem: PropTypes.any,
  isOpen: PropTypes.bool,
  handlePopoverClose: PropTypes.func
};

export default IpPopup;

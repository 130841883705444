import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import styles from './InviteUserModal.module.scss';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Validation from '../../utils/Validation';
import Users from '../../api/Users';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  content: {
    position: 'initial',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: 501,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff'
  },
  btn: {
    alignSelf: 'center',
    marginBottom: '35px'
  },
  saved: {
    color: '#0caf1d',
    fontSize: '14px',
    fontWeight: '500',
    textAlign: 'center'
  }
};

class InviteUserModal extends PureComponent {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    loading: false,
    errors: {},
    saved: false
  };

  handleCloseModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  };

  onRoleChange = selectedOption => {
    this.setState({ role: selectedOption });
  };

  onInputChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onClickSendInvitation = async () => {
    const { getUsers, account, allRoles, auth } = this.props;
    const { email, role, firstName, lastName } = this.state;
    this.setState({ loading: true, saved: false });

    let data = {
      email,
      first_name: firstName,
      last_name: lastName,
      role
    };

    const newErrors = Validation.validateForm(data);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }

    const selectedRole = allRoles.find(r => r.name === role.value);

    data = { ...data, ...{ account_id: account.id, role: selectedRole.id, user: auth.user } };
    try {
      // API call to send email to invitee
      const result = await Users.inviteUser(data);

      if (result) {
        this.setState({ saved: true });
        getUsers();
        this.setState({
          loading: false,
          email: '',
          firstName: '',
          lastName: '',
          role: '',
          errors: {}
        });
        // toggleModal();
      }
    } catch (error) {
      console.log(error.message);
      this.setState({ loading: false, errors: { invite: error.message } });
    }
  };

  render() {
    const { email, firstName, lastName, role, loading, errors, saved } = this.state;
    const { isOpen, roleDropdownOptions } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Invite User Modal"
      >
        <div className={styles.container}>
          <span className={styles.closeBtn} onClick={this.handleCloseModal} aria-hidden="true">
            ×
          </span>
          <div className={styles.content}>
            <p className={styles.headerText}>Invite others to this account</p>
            <p className={styles.descriptionText}>
              {`After your invitee accepts the emailed invitation, they'll have access to this
              account.`}
            </p>

            <div className={styles.nameContainer}>
              <Input
                onChange={this.onInputChange}
                value={firstName}
                name="firstName"
                error={errors.first_name}
                placeholder="First Name"
              />
              <Input
                onChange={this.onInputChange}
                value={lastName}
                name="lastName"
                error={errors.last_name}
                placeholder="Last Name"
              />
            </div>
            <Input
              onChange={this.onInputChange}
              value={email}
              name="email"
              error={errors.email}
              placeholder="Email Address"
            />
            <div className={styles.accessLevelContainer}>
              <p>Access Level</p>
              <Dropdown
                options={roleDropdownOptions.values}
                value={role}
                name="role"
                onOptionChange={this.onRoleChange}
                placeholder="Select"
                error={errors.role}
                isSearchable={false}
              />
            </div>
            {errors.invite && <ErrorBox error={errors.invite} />}
            <Button
              onClick={this.onClickSendInvitation}
              title="Send Invitation"
              color="green"
              loading={loading}
              style={customStyles.btn}
            />
            {saved && <span style={customStyles.saved}>✓ Invitation sent</span>}
          </div>
        </div>
      </Modal>
    );
  }
}

InviteUserModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  getUsers: PropTypes.func,
  account: PropTypes.object,
  allRoles: PropTypes.array,
  roleDropdownOptions: PropTypes.object,
  auth: PropTypes.object
};

export default InviteUserModal;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './ResetPassword.module.scss';
// import Footer from '../../components/Footer/Footer';
import { ReactComponent as MAINLOGO } from '../../assets/main-logo.svg';
// import { ReactComponent as ARROW_LEFT } from '../../assets/arrow-left.svg';
import FacebookIcon from '../../assets/facebook-icon.svg';
import InstagramIcon from '../../assets/instagram-icon.svg';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Validation from '../../utils/Validation';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Users from '../../api/Users';
import LinkButton from '../../components/LinkButton/LinkButton';

const customStyle = {
  input: {
    fontWeight: 'bold'
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: '600',
    color: '#666666'
  },
  availBtn: {
    display: 'inline-flex',
    textDecoration: 'none'
  }
};
class ResetPassword extends PureComponent {
  state = {
    email: '',
    errors: {},
    success: false,
    loading: false
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.submitReset();
    }
  };

  submitResetPassword = async () => {
    const { email } = this.state;
    const data = {
      email
    };
    const newErrors = Validation.validateForm(data);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }

    this.setState({ loading: true });

    try {
      const result = await Users.generatePasswordReset(data);
      if (result) {
        this.setState({ success: true, errors: {}, loading: false });
      }
    } catch (error) {
      this.setState({ errors: { resetPassword: error.message }, loading: false });
    }
  };

  onClickBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  onClickCreateAccount = () => {
    const { history } = this.props;
    history.push('/register');
  };

  render() {
    const { email, errors, success, loading } = this.state;
    return (
      <div className={styles.all}>
        {success ? (
          <div className={styles.loginBox}>
            <div className={styles.loginBoxInner}>
              <div className={styles.logo}>
                <MAINLOGO />
              </div>
              <h1 className={styles.headerText}>Email Sent!</h1>
              <p className={styles.descriptionText}>
                Password reset instructions have been sent to the email address you have provided.
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.loginBox}>
              <div className={styles.loginBoxInner}>
                <div className={styles.logo}>
                  <MAINLOGO />
                </div>
                <h1 className={styles.headerText}>Forgot Password</h1>
                {/* <p className={styles.descriptionText}>
                  To reset your password, please provide your FraudBlocker email address.
                </p> */}

                <div className={styles.loginForm}>
                  <Input
                    type="email"
                    value={email}
                    name="email"
                    label="Email"
                    labelStyle={customStyle.inputLabel}
                    placeholder="Enter Email"
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    containerStyle={customStyle.inputContainer}
                    style={customStyle.input}
                    error={errors.email || null}
                  />
                </div>
                {errors.resetPassword && <ErrorBox error={errors.resetPassword} />}
                <div className={styles.twoInputsContainer}>
                  {/* <div
                    className={`${styles.goBackContainer} ${styles.backToLogin}`}
                    onClick={this.onClickBack}
                  >
                    <ARROW_LEFT />
                    <p>Go Back</p>
                  </div> */}
                  <Button
                    title="Send Me A Reset Password Email"
                    loading={loading}
                    onClick={this.submitResetPassword}
                    color="green"
                  />
                </div>
                {/* <p
                  onClick={this.onClickCreateAccount}
                  className={`green ${styles.createAccountText}`}
                >
                  {`Create an account`}
                </p> */}
              </div>
            </div>
          </>
        )}

        <div className={styles.loginRight}>
          <div className={styles.loginInfo}>
            <div className={styles.socialIcons}>
              <div>
                <img src={FacebookIcon} alt="Facebook" />
              </div>
              <div>
                <img src={InstagramIcon} alt="Instagram" />
              </div>
            </div>
            <div className={styles.btnAvail}>
              <Button title="Now Available" color="blue" style={customStyle.availBtn} />
            </div>
            <h2>
              Ad fraud protection for <span className={styles.facebook}>Facebook</span> and{' '}
              <span className={styles.instagram}>Instagram</span>
            </h2>
            <div className={styles.readBtn}>
              <LinkButton
                isExternal={true}
                to="https://fraudblocker.com/articles/fraud-blocker-2-0"
                title="Read Announcement"
                color="outline-white"
                style={customStyle.availBtn}
                anchorProps={{ target: '_blank' }}
              />
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.object
};

export default ResetPassword;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../../components/Button/Button';
import styles from './AccountExistModal.module.scss';
import AccountExistIcon from '../../../assets/account-exists.svg';
import Users from '../../../api/Users';
import ErrorBox from '../../../components/ErrorBox/ErrorBox';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        overflow: 'auto',
        padding: '40px 0'
    },
    content: {
        width: 600,
        top: 20,
        right: 0,
        left: 0,
        bottom: 0,
        height: 'auto',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        padding: '30px',
        position: 'relative'
    }
};

const AccountExistModal = props => {
    const { isOpen, email, appSumoPlanId, redirectUser, planId, invoiceId } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    console.log(isLoading);

    const addAppSumoPlan = async () => {
        setIsLoading(true);
        try {
            const response = await Users.linkAppSumoAccount({ email, appSumoPlanId, planId, invoiceId });
            if (response) {
                redirectUser();
                window.Intercom('update', {
                    email,
                    user_id: response.id,
                    appsumo: true
                });
                window.Intercom('trackEvent', 'appsumo-register', {
                    domain: response.domain,
                    appsumo: true,
                    email,
                    user_id: response.id,
                    plan: planId
                });
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err.message);
        }
    };

    return (
        <Modal isOpen={isOpen} style={customStyles} contentLabel="Alert" ariaHideApp={false}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.imgContain}>
                            <img src={AccountExistIcon} className={styles.icon} />
                        </div>
                        <p className={styles.headerText}>An Account Already Exists</p>
                        <p className={styles.headerSubText}>for the email {email}</p>
                        <p className={styles.descriptionText}>By pressing this button, your new AppSumo plan will be added to this account. Once completed, you will then be directed to login to begin using our services.</p>
                        {error && <ErrorBox
                            error={
                                <div>
                                    <strong>Whoops!</strong>{' '}This account already has an active plan. Please contact us.
                                </div>
                            }
                        />}
                        <div className={styles.btnContainer}>
                            <Button
                                onClick={addAppSumoPlan}
                                title="Add AppSumo Plan"
                                color="lt-blue"
                                loading={isLoading}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

AccountExistModal.propTypes = {
    email: PropTypes.string,
    appSumoPlanId: PropTypes.string,
    planId: PropTypes.string,
    invoiceId: PropTypes.string,
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    redirectUser: PropTypes.func
};

export default AccountExistModal;

import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Button from '../../components/Button/Button';
import styles from './UnprotectedAccountModal.module.scss';
import ErrorImage from '../../assets/robot.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    width: 551,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '40px 45px',
    position: 'relative'
  }
};

class UnprotectedAccountModal extends PureComponent {
  onClick = () => {
    const { onSelectPlanClick, isAppSumo } = this.props;
    if (!isAppSumo) {
      onSelectPlanClick();
    } else {
      window.open('https://appsumo.com/account/products/');
    }
  };

  render() {
    const { isOpen, isAppSumo } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        style={customStyles}
        className={styles.modalWrapper}
        ariaHideApp={false}
        contentLabel="Notifications"
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.imgWrapper}>
              <img src={ErrorImage} />
            </div>
            <p className={styles.headerText}>{isAppSumo ? 'Warning!' : 'Alert!'}</p>
            {isAppSumo ? (
              <div className={styles.gridContent}>
                <p>
                  You have canceled your AppSumo plan and{' '}
                  <a className={styles.noLink} href={null}>
                    your website is unprotected.
                  </a>
                </p>
                <p>
                  You must upgrade your plan to continue using Fraudblocker and protect your account
                  from click fraud and wasted ad spend.
                </p>
              </div>
            ) : (
              <div className={styles.gridContent}>
                <p>
                  Your plan was canceled and{' '}
                  <a className={styles.noLink} href={null}>
                    your ads are unprotected from click fraud.
                  </a>
                </p>
                <p>You must select a new plan to continue fraud monitoring.</p>
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <Button
                onClick={this.onClick}
                title={!isAppSumo ? 'Select A Plan' : 'Update My Plan'}
                color="green"
                className={styles.updateButton}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

UnprotectedAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  onSelectPlanClick: PropTypes.func,
  isAppSumo: PropTypes.bool
};

export default UnprotectedAccountModal;

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import styles from './ActionSuccessModal.module.scss';
import BadgeIcon from '../../assets/badge.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    width: 600,
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '30px',
    position: 'relative'
  }
};

const ActionSuccessModal = props => {
  const { isOpen, toggleModal, buttons, description, isClosable = true } = props;

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Alert" ariaHideApp={false}>
      <div className={styles.container}>
        {isClosable && <span className={styles.closeBtn} onClick={() => toggleModal()} aria-hidden="true">
          ×
        </span>}
        <div className={styles.content}>
          <div className={styles.contentWrapper}>
            <div className={styles.imgContain}>
              <img src={BadgeIcon} className={styles.icon} />
            </div>
            <p className={styles.headerText}>You&apos;re All Set</p>
            <p className={styles.descriptionText}>{description}</p>

            <div className={styles.btnContainer}>
              {buttons.map(item => (
                <Button
                  key={item.title}
                  onClick={item.action}
                  title={item.title}
                  color={item.color}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ActionSuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  description: PropTypes.string,
  buttons: PropTypes.array,
  isClosable: PropTypes.bool
};

export default ActionSuccessModal;

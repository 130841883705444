import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import styles from './CancelReasonSolutionModal.module.scss';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import DiscountIcon from '../../assets/discount-icon.svg';
import CallIcon from '../../assets/call-icon.svg';
import BackBtnIcon from '../../assets/back-btn.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    width: 600,
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '30px',
    position: 'relative'
  }
};

const CancelReasonSolutionModal = props => {
  const { reason, isOpen, isLoading, handleSolutionAction, toggleModal, error, goBack } = props;

  const getActionIcon = () => {
    if (['tech-issue', 'no-results'].includes(reason)) {
      return CallIcon;
    }
    return DiscountIcon;
  };

  const getHeading = () => {
    if (['too-expensive', 'paused-ppc'].includes(reason)) {
      return 'Would You Like 50% Off Your Plan?';
    }
    if (reason === 'no-results') {
      return 'Schedule A Call To Review Results';
    }
    return 'Schedule A Call With Support';
  };

  const getDescription = () => {
    if (['too-expensive', 'paused-ppc'].includes(reason)) {
      return 'We can immediately offer you 50% off your monthly plan for the next 3 months to continue trying out service.';
    }
    if (reason === 'no-results') {
      return 'You may be able to improve your results with changes to your customization settings.';
    }
    return 'Would you like to speak to one of our technical specialists to help with your issue?';
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Alert" ariaHideApp={false}>
      <div className={styles.container}>
        <span className={styles.closeBtn} onClick={() => toggleModal()} aria-hidden="true">
          ×
        </span>
        <span className={styles.backBtn} onClick={() => goBack()} aria-hidden="true">
          <img src={BackBtnIcon} /> Go Back
        </span>
        <div className={styles.content}>
          <div className={styles.contentWrapper}>
            <div className={styles.imgContain}>
              <img src={getActionIcon()} className={styles.icon} />
            </div>
            <p className={styles.headerText}>{getHeading()}</p>
            <p className={styles.descriptionText}>{getDescription()}</p>

            <div className={styles.btnContainer}>
              <Button
                onClick={() => handleSolutionAction(true)}
                disabled={isLoading}
                title={
                  ['too-expensive', 'paused-ppc'].includes(reason)
                    ? 'Yes, I’ll Take The Deal!'
                    : 'Yes, Schedule A Call'
                }
                color="lt-blue"
              />
              <Button
                onClick={() => handleSolutionAction(false)}
                title="No, Just Cancel My Plan"
                color="transparent"
              />
            </div>
            {error && <ErrorBox error={error} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

CancelReasonSolutionModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  toggleModal: PropTypes.func,
  error: PropTypes.any,
  reason: PropTypes.string,
  handleSolutionAction: PropTypes.func,
  goBack: PropTypes.func
};

export default CancelReasonSolutionModal;

import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import RangeSlider from '../../components/RangeSlider/RangeSlider';
import Button from '../../components/Button/Button';
import styles from './BoosterPlanModal.module.scss';
import Utils from '../../utils/Utils';
import TacoImage from '../../assets/taco1.svg';
import Taco0BoosterImage from '../../assets/taco0.svg';
import Taco1BoosterImage from '../../assets/taco2.svg';
import Taco2BoosterImage from '../../assets/taco3.svg';
import Taco3BoosterImage from '../../assets/taco4.svg';
import RocketImage from '../../assets/rocket.svg';
// import AppSumoImage from '../../assets/appsumo.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    overflow: 'auto',
    padding: '40px 0'
  },
  currentBtn: {
    backgroundColor: 'transparent',
    color: '#9093A5',
    border: '1px solid #9093A5'
  },
  content: {
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    width: 551,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '40px 45px',
    position: 'relative'
  }
};

class BoosterPlanModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      boosterClicks: 10000,
      rangeMap: {
        10000: 1,
        50000: 2,
        200000: 3,
        500000: 4
      },
      clicksMap: {
        1: 10000,
        2: 50000,
        3: 200000,
        4: 500000
      },
      billingOptions: [],
      defaultBoosterClicks: 10000
    };
  }

  handleCloseModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  };

  setBillingOptions = () => {
    const { currentPlan, billingOptions } = this.props;
    let defaultClicks = 10000;
    if (currentPlan && billingOptions.length && this.checkIfBooster()) {
      const selectedItem = billingOptions.find(item => item.id === currentPlan.id);
      if (selectedItem) {
        defaultClicks = selectedItem.clicks;
      }
    }
    this.setState({
      defaultBoosterClicks: defaultClicks,
      boosterClicks: defaultClicks
    });
  };

  componentDidMount = () => {
    if (this.props.currentPlan) {
      this.setBillingOptions();
    }
  };

  getBoosterWebsites = () => {
    const { billingOptions, boosterClicks } = this.state;
    if (billingOptions.length) {
      const found = billingOptions.find(
        item => item.plan.toLowerCase().includes('booster') && item.clicks === boosterClicks
      );
      if (found) {
        return found.domains === 'unlimited' ? 'Unlimited' : found.domains;
      }
    }
    return 'Unlimited';
  };

  componentDidUpdate = preProps => {
    if (this.props.billingOptions.length && !preProps.billingOptions.length) {
      this.setBillingOptions();
    }
    if (this.props.currentPlan && !preProps.currentPlan) {
      this.setBillingOptions();
    }
    if (!this.props.currentPlan && preProps.currentPlan) {
      this.setBillingOptions();
    }
  };

  updateClicks = val => {
    let clicks = val;
    if (clicks > 0 && clicks < 1) {
      clicks = 1;
    } else if (clicks > 1 && clicks < 2) {
      clicks = 2;
    } else if (clicks > 2 && clicks < 3) {
      clicks = 3;
    } else if (clicks > 3) {
      clicks = 4;
    }
    this.setState({
      boosterClicks: this.state.clicksMap[clicks]
    });
  };

  changePlan = plan => {
    this.props.toggleConfirmPlanModal(plan);
  };

  checkIfBooster = () => {
    const { currentPlan } = this.props;
    return currentPlan && currentPlan.nickname.toLowerCase().includes('boost');
  };

  getAppSumoPlanDetails = () => {
    const { subscription, currentPlan, billingOptions } = this.props;
    if (!subscription || !billingOptions.length || !currentPlan) {
      return null;
    }
    if (subscription.appSumoSubscription) {
      return {
        ...subscription.appSumoSubscription.plan,
        amount: billingOptions.find(item => item.id === subscription.appSumoSubscription.plan.id)
          .price
      };
    }
    return {
      ...currentPlan,
      amount: billingOptions.find(item => item.id === currentPlan.id).price
    };
  };

  getPrice = price => {
    const { currentPlan, discount, currentDiscount } = this.props;
    const { defaultBoosterClicks, boosterClicks } = this.state;
    if (
      (currentPlan.nickname.toLowerCase().includes('booster') &&
        defaultBoosterClicks === boosterClicks) ||
      !discount
    ) {
      return price;
    }
    if (!discount && currentDiscount) {
      return price - currentDiscount;
    }
    return price - (price * discount) / 100;
  };

  render() {
    const {
      isOpen,
      toggleCancelPlan,
      isCancelable,
      subscription,
      conversionRates,
      currency,
      currentPlan,
      billingOptions = []
    } = this.props;
    const { boosterClicks, defaultBoosterClicks, rangeMap } = this.state;
    const boosterPlan =
      (billingOptions.length &&
        billingOptions.find(
          item => item.plan.toLowerCase().includes('booster') && item.clicks === boosterClicks
        )) ||
      null;
    return (
      <>
        <Modal
          isOpen={isOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Compare Plans"
        >
          <div className={styles.container}>
            <span className={styles.closeBtn} onClick={this.handleCloseModal} aria-hidden="true">
              ×
            </span>
            {billingOptions.length && currentPlan && currentPlan.id && (
              <div className={styles.content}>
                <p className={styles.headerText}>
                  Change Your Plan
                  <div className={styles.headerSubText}>No contracts. Downgrade anytime.</div>
                </p>
                <div className={styles.gridContent}>
                  <div className={styles.gridCell}>
                    <div className={`${styles.planHeading} ${styles.light}`}>APPSUMO PLAN</div>
                    <div className={styles.wrapIcon}>
                      <img src={TacoImage} alt="taco" />
                    </div>
                    <div className={styles.planValue}>
                      {currentPlan.id &&
                        this.getAppSumoPlanDetails() &&
                        Utils.convertToCurrency(
                          conversionRates,
                          this.getAppSumoPlanDetails().amount,
                          currency
                        )}{' '}
                      <span> / lifetime</span>
                    </div>
                    <div className={styles.planAdv}>
                      <div className={styles.clicksDigit}>
                        {this.getAppSumoPlanDetails() &&
                          Number(
                            this.getAppSumoPlanDetails().metadata.clicks
                          ).toLocaleString('en-US', { maximumFractionDigits: 1 })}{' '}
                        Ad Clicks
                      </div>
                      <div className={styles.clicksDigit}>
                        {this.getAppSumoPlanDetails() &&
                          (this.getAppSumoPlanDetails().metadata.domains === 'unlimited'
                            ? 'Unlimited'
                            : this.getAppSumoPlanDetails().metadata.domains)}{' '}
                        Websites
                      </div>
                      <div className={styles.btnOuter}>
                        <Button
                          onClick={() => { }}
                          disabled
                          style={customStyles.currentBtn}
                          title="Current Plan"
                          color="lt-blue-auto"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.gridCell} ${styles.activeCell}`}>
                    <div className={styles.planHeading}>BOOSTER PLAN</div>
                    <div className={styles.wrapIcon}>
                      {boosterClicks === 10000 ? (
                        <img src={Taco0BoosterImage} alt="taco" />
                      ) : boosterClicks === 50000 ? (
                        <img src={Taco1BoosterImage} alt="taco" />
                      ) : boosterClicks === 200000 ? (
                        <img src={Taco2BoosterImage} alt="taco" />
                      ) : (
                        <img src={Taco3BoosterImage} alt="taco" />
                      )}
                      <div className={styles.boostIcon}>
                        <img src={RocketImage} alt="boost" />
                      </div>
                    </div>
                    <div className={styles.rangeSlider}>
                      <RangeSlider
                        min={1}
                        max={4}
                        step={1}
                        markers={[1, 2, 3, 4]}
                        value={rangeMap[boosterClicks]}
                        rangeClicksMap={this.state.clicksMap}
                        handleChange={this.updateClicks}
                      />
                    </div>
                    <div className={styles.planValue}>
                      {boosterPlan &&
                        Utils.convertToCurrency(
                          conversionRates,
                          this.getPrice(boosterPlan.price),
                          currency
                        )}{' '}
                      <span> / month</span>
                    </div>
                    <div className={styles.planAdv}>
                      <div className={styles.clicksDigit}>
                        <span>
                          +
                          {Number(boosterClicks).toLocaleString('en-US', {
                            maximumFractionDigits: 1
                          })}
                        </span>{' '}
                        Ad Clicks
                      </div>
                      <div className={styles.clicksDigit}>{this.getBoosterWebsites()} Websites</div>
                      <div className={styles.btnOuter}>
                        {currentPlan.nickname.toLowerCase().includes('booster') &&
                          defaultBoosterClicks === boosterClicks ? (
                          <Button
                            onClick={() => { }}
                            disabled
                            title="Current Plan"
                            color="lt-blue-auto"
                            style={customStyles.currentBtn}
                          />
                        ) : (
                          <Button
                            onClick={() => this.changePlan(boosterPlan)}
                            title={
                              !this.checkIfBooster() || boosterPlan.clicks > defaultBoosterClicks
                                ? 'Upgrade'
                                : 'Downgrade'
                            }
                            color="lt-blue-auto"
                          />
                        )}
                      </div>
                      {isCancelable &&
                        currentPlan &&
                        currentPlan.nickname.toLowerCase().includes('boost') && (
                          <div onClick={() => toggleCancelPlan(true)} className={styles.cancelAcc}>
                            <span>Cancel My Booster Plan</span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {subscription &&
                  (!subscription.appSumoSubscription ||
                    subscription.appSumoSubscription.status !== 'canceled') && (
                    <div>
                      <a href="https://appsumo.com/account/products/" className={styles.cancelAcc}>
                        <span>Cancel All Plans</span>
                      </a>
                    </div>
                  )}
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

BoosterPlanModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  toggleCancelPlan: PropTypes.func,
  toggleConfirmPlanModal: PropTypes.func,
  billingOptions: PropTypes.any,
  isCancelable: PropTypes.bool,
  currentPlan: PropTypes.object,
  subscription: PropTypes.object,
  switchPlan: PropTypes.func,
  currency: PropTypes.string,
  discount: PropTypes.number,
  conversionRates: PropTypes.any,
  currentDiscount: PropTypes.number
};

export default BoosterPlanModal;

import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';
import qs from 'qs';
import 'react-dates/initialize';

import 'react-table/react-table.css';
import './App.scss';
import Store from './redux/Store';
import Utils from './utils/Utils';
import Router from './router/Router';
import User from './redux/actions/User';
import Accounts from './redux/actions/Account';

class App extends PureComponent {
  componentDidMount = () => {
    console.log('App CDM - R');
    LicenseInfo.setLicenseKey(
      '7d0b0f329d249b7605217c65a9908bdcTz03ODM4NCxFPTE3MzExNjg3MzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
    User.startAuthListener();
    Store.dispatch(Accounts.getConversionRates());

    const { offer_id: coupon, afmc: otherCoupon } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if (coupon) {
      Utils.setCookie('offer_id', coupon);
    }

    if (otherCoupon) {
      Utils.setCookie('afmc', otherCoupon);
    }

    if (typeof window !== 'undefined') {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'naand0j2'
      });
    }
  };

  componentWillUnmount = () => {
    User.stopAuthListener();
  };

  render() {
    return (
      <Provider store={Store}>
        <Router />
      </Provider>
    );
  }
}

export default App;

import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import styles from './BoosterPaymentModal.module.scss';
import Button from '../../components/Button/Button';
import InputContainer from '../../components/InputContainer/InputContainer';
import Payments from '../../api/Payments';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Input from '../../components/Input/Input';
import Utils from '../../utils/Utils';
import CardSSL from '../../assets/card_ssl.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  content: {
    position: 'initial',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: 501,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff'
  },
  btn: {
    alignSelf: 'center'
  },
  nameInput: {
    marginBottom: 20
  },
  errorBox: {
    marginTop: '20px',
    marginBottom: '-20px'
  },
  fieldError: {
    color: '#fc584e',
    marginTop: '5px',
    textAlign: 'right'
  },
  zipInput: {
    width: 100,
    marginBottom: 25
  }
};

class BoosterPaymentModal extends PureComponent {
  state = {
    firstName: '',
    lastName: '',
    loading: false,
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    errors: {}
  };

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleCloseModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  };

  handleChange = change => {
    // console.log('[change]', change);
    if (change.complete) {
      this.setState({ [change.elementType]: true });
    }
  };

  handleReady = () => {
    // console.log('[ready]');
  };

  onClickUpdateCard = async () => {
    const { firstName, lastName, cardNumber, cardExpiry, cardCvc, zip } = this.state;
    const { stripe, accounts, proceed } = this.props;
    this.setState({ loading: true, errors: {} });
    if (cardNumber && cardExpiry && cardCvc && firstName.length > 0 && lastName.length) {
      try {
        const { token } = await stripe.createToken({ name: `${firstName} ${lastName}` });
        if (token) {
          const data = {
            source: token.id
          };
          if (zip) {
            data.address = {
              postal_code: zip
            };
          }
          const result = await Payments.updateCustomer(accounts.subscription.id, data);
          if (result) {
            this.setState({ loading: false, errors: {} }, () =>
              // fetchLatestSubscriptionInfo(),
              proceed()
            );
          }
        } else {
          throw Error('Please enter a valid credit card.');
        }
      } catch (error) {
        console.log(error);
        this.setState({ errors: { update: error.message }, loading: false });
      }
    } else {
      const err = {};
      if (!cardNumber) {
        err.cardNumber = 'Please enter card number';
      }
      if (!cardExpiry) {
        err.cardExpiry = 'Please enter card expiry';
      }
      if (!cardCvc) {
        err.cardCvc = 'Please enter card cvv';
      }
      if (!firstName) {
        err.firstName = 'Please enter first name';
      }
      if (!lastName) {
        err.firstName = 'Please enter last name';
      }
      this.setState({ errors: err, loading: false });
    }
  };

  getAmountToBePaid = () => {
    const { selectedPlan, currentPlan } = this.props;
    return selectedPlan.plan.toLowerCase().includes('boost') &&
      currentPlan.nickname.toLowerCase().includes('appsumo tier')
      ? selectedPlan.price
      : selectedPlan.price - currentPlan.amount > 0
        ? selectedPlan.price - currentPlan.amount
        : 0;
  };

  render() {
    const { loading, errors, firstName, lastName, zip } = this.state;
    const { isOpen, selectedPlan, currency, conversionRates } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Confirm Your Payment"
      >
        <div className={styles.container}>
          <span className={styles.closeBtn} onClick={this.handleCloseModal} aria-hidden="true">
            ×
          </span>
          <div className={styles.content}>
            <p className={styles.headerText}>Confirm Your Payment</p>
            <p className={styles.headerSubText}>
              {selectedPlan && (
                <div className={styles.pricing}>
                  {Utils.convertToCurrency(conversionRates, this.getAmountToBePaid(), currency)}
                  <span>/ {selectedPlan.interval}</span>
                </div>
              )}
              <div className={styles.info}>Your account will be charged monthly</div>
            </p>
            <div className={styles.inputWrap}>
              <Input
                name="firstName"
                value={firstName}
                onChange={this.onChangeText}
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.nameInput}
                label="First Name on Card"
                error={errors.firstName}
              />
              <Input
                name="lastName"
                value={lastName}
                onChange={this.onChangeText}
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.nameInput}
                label="Last Name on Card"
                error={errors.lastName}
              />
            </div>
            <InputContainer
              labelStyle={customStyles.inputLabel}
              containerStyle={customStyles.input}
              label="Card Number"
            >
              <div className={errors.cardNumber ? styles.errorWrap : ''}>
                <CardNumberElement onChange={this.handleChange} onReady={this.handleReady} />
              </div>
              {errors.cardNumber && <p style={customStyles.fieldError}>{errors.cardNumber}</p>}
            </InputContainer>
            <div className={styles.twoInputsContainer}>
              <InputContainer
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.input}
                label="Expiry Date"
              >
                <div className={errors.cardExpiry ? styles.errorWrap : ''}>
                  <CardExpiryElement onChange={this.handleChange} onReady={this.handleReady} />
                </div>
                {errors.cardExpiry && <p style={customStyles.fieldError}>{errors.cardExpiry}</p>}
              </InputContainer>
              <InputContainer
                labelStyle={customStyles.inputLabel}
                containerStyle={customStyles.input}
                label="CVV"
              >
                <div className={errors.cardCvc ? styles.errorWrap : ''}>
                  <CardCVCElement onChange={this.handleChange} onReady={this.handleReady} />
                </div>
                {errors.cardCvc && <p style={customStyles.fieldError}>{errors.cardCvc}</p>}
              </InputContainer>
              <Input
                name="zip"
                value={zip}
                pattern="[A-Za-z\d\s]+"
                onChange={this.onChangeText}
                containerStyle={customStyles.zipInput}
                labelStyle={customStyles.inputLabel}
                label="ZIP / Postal Code"
              />
            </div>

            {errors.update && <ErrorBox errorStyle={customStyles.errorBox} error={errors.update} />}

            <Button
              onClick={this.onClickUpdateCard}
              title="Place Order"
              color="lt-green"
              loading={loading}
              style={customStyles.btn}
            />
            <p className={styles.disclaimer}>
              By clicking the “Place Order” button, you confirm that you have read, understand and
              accept our Terms of Use and Privacy Policy.
            </p>
            <p className={styles.cardSSL}>
              <img src={CardSSL} alt="ssl" />
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}

BoosterPaymentModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  stripe: PropTypes.any,
  accounts: PropTypes.object,
  source: PropTypes.object,
  selectedPlan: PropTypes.object,
  currentPlan: PropTypes.object,
  fetchLatestSubscriptionInfo: PropTypes.func,
  currency: PropTypes.string,
  conversionRates: PropTypes.any,
  proceed: PropTypes.func
};

export default injectStripe(BoosterPaymentModal);

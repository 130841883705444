import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import styles from './Register.module.scss';
import LOGO from '../../assets/small-logo.svg';
import QUOTES from '../../assets/techtimesgoogle.svg';
import STARS from '../../assets/stars.svg';
import TESTIMONIAL from '../../assets/testimonial.png';
import TWO_FOUR from '../../assets/247.svg';
import CODE from '../../assets/ez.svg';
import SignUpForm from '../../containers/Register/oldSteps/SignUpForm';
import AccountCreationForm from '../../containers/Register/oldSteps/AccountCreationForm';
import PaymentForm from '../../containers/Register/oldSteps/PaymentForm';
import FinishedRegistration from '../../containers/Register/oldSteps/FinishedRegistration';
// import ThankYou from '../../containers/Register/ThankYou';
import Footer from '../../components/Footer/Footer';
import User from '../../redux/actions/User';
import Account from '../../redux/actions/Account';
import Validation from '../../utils/Validation';
import Payments from '../../api/Payments';
import Users from '../../api/Users';
import Constants from '../../utils/Constants';
import Utils from '../../utils/Utils';

let leadHandled = false;
class Register extends PureComponent {
  state = {
    email: '',
    discount: 0,
    couponError: '',
    activeForm: 0,
    toDashboard: false,
    toSignIn: false
  };

  componentDidMount = async () => {
    console.log('PKDEBUG', process.env.REACT_APP_STRIPE_KEY);
    console.log('PKNEW', Constants.stripePublicKey);
    window.addEventListener('beforeunload', () => {
      this.handleLeads();
    });

    // For browsers other than Chrome;
    window.addEventListener('unload', () => {
      this.handleLeads();
    });

    document.getElementById('favicon').href = 'signup-favicon.ico';

    const { offer_id: couponIdFromQuery, afmc: otherCouponIdFromQuery } = qs.parse(
      window.location.search,
      {
        ignoreQueryPrefix: true
      }
    );

    const afmcCoupon = otherCouponIdFromQuery || Utils.getCookie('afmc');
    const offerCoupon = couponIdFromQuery || Utils.getCookie('offer_id');

    const couponRes = { error: '', discount: 0 };

    if (afmcCoupon) {
      try {
        const coupon = await Payments.getCouponDetails(afmcCoupon);
        if (coupon) {
          Utils.setCookie('offer_id', '', -1);
          couponRes.discount = coupon.percent_off;
          this.setState({ discount: coupon.percent_off });
        }
      } catch (error) {
        Utils.setCookie('afmc', '', -1);
        couponRes.error = error.message;
      }
    }

    if (offerCoupon && !couponRes.discount) {
      try {
        const otherCoupon = await Payments.getCouponDetails(offerCoupon);
        if (otherCoupon) {
          Utils.setCookie('afmc', '', -1);
          couponRes.discount = otherCoupon.percent_off;
          this.setState({ discount: otherCoupon.percent_off });
        }
      } catch (err) {
        Utils.setCookie('offer_id', '', -1);
        couponRes.error = err.message;
      }
    }

    if ((afmcCoupon || offerCoupon) && !couponRes.discount) {
      this.setState({ couponError: couponRes.error });
    }

    const { accounts, auth } = this.props;
    console.log('componentDidMount', auth.user, accounts.data);
    if (auth.user && accounts.data) {
      console.log('Checking for current reg step');
      if (!accounts.data.initial_plan && this.state.activeForm !== 1) {
        console.log('STEP - to 1');
        this.setState({ activeForm: 1 });
      } else if (
        accounts.data.initial_plan &&
        this.state.activeForm !== 2 &&
        !Validation.userHasSubscription(accounts.subscription)
      ) {
        console.log('STEP - to 2');
        this.setState({ activeForm: 2 });
      } else if (
        Validation.userHasAllRequiredFields(auth.user, accounts.data, accounts.subscription)
      ) {
        console.log('STEP - to dashboard');
        this.setState({ toDashboard: true });
      } else {
        console.log('STEP - No step move to perform');
      }
    }
  };

  handleLeads = () => {
    const { email, activeForm } = this.state;
    // User has left Registration on SignUpForm
    if (activeForm === 0 && !leadHandled) {
      leadHandled = true; // Check to make sure its not called twice.
      const data = {
        email
      };

      const invalidEmail = Validation.validateForm(data);
      if (invalidEmail) {
        return;
      }

      User.createLead(data);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.handleLeads);
    window.removeEventListener('unload', this.handleLeads);
  };

  componentDidUpdate = prevProps => {
    const { accounts, auth } = this.props;
    const { accounts: prevAccounts } = prevProps;
    console.log('componentDidUpdate', auth.user, accounts.data);
    if (!prevAccounts.data && auth.user && accounts.data) {
      console.log('Checking for current reg step');
      if (!accounts.data.initial_plan && this.state.activeForm !== 1) {
        console.log('STEP - to 1');
        this.setState({ activeForm: 1 });
      } else if (
        accounts.data.initial_plan &&
        this.state.activeForm !== 2 &&
        !Validation.userHasSubscription(accounts.subscription)
      ) {
        console.log('STEP - to 2');
        this.setState({ activeForm: 2 });
      } else if (
        Validation.userHasAllRequiredFields(auth.user, accounts.data, accounts.subscription)
      ) {
        console.log('STEP - to dashboard');
        this.setState({ toDashboard: true });
      } else {
        console.log('STEP - No step move to perform');
      }
    }
  };

  setEmail = email => {
    this.setState({ email });
  };

  onClickNextSignupClick = () => {
    this.setState({ activeForm: 1 });
  };

  onClickNextAccountCreation = async (planSelected, currency = 'USD') => {
    console.log(1, planSelected, currency);
    const { auth, updateUser, updateUserAccount, accounts } = this.props;
    let createCustomerResult = null;
    const updateAccountBody = {};
    try {
      if (auth.user.currency !== currency) {
        await updateUser(auth.user.id, { currency });
      }
      if (!accounts.data || !accounts.data.stripe_token) {
        const createCustomerBody = {
          account_id: auth.user.account_id,
          email: auth.user.email,
          name: auth.user.email,
          metadata: {
            account_id: auth.user.account_id
          },
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            postal_code: ''
          }
        };
        createCustomerResult = await Payments.createCustomer(createCustomerBody);
      }

      if (createCustomerResult) {
        updateAccountBody.stripe_token = createCustomerResult.id;
      }

      if (
        !accounts.data ||
        !accounts.data.initial_plan ||
        planSelected.id !== accounts.data.initial_plan.id
      ) {
        updateAccountBody.initial_plan = JSON.stringify(planSelected);
      }
      if (Object.keys(updateAccountBody).length) {
        const customerResult = await updateUserAccount(auth.user.account_id, updateAccountBody);
        if (customerResult) {
          console.log(`Current ${this.state.activeForm}, + 1`);
          this.setState({ activeForm: 2 });
        }
      } else {
        console.log(`Current ${this.state.activeForm}, + 1`);
        this.setState({ activeForm: 2 });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  /**
   * 1. Creating Customer in Stripe onNextClick with all User's information
   * 2. Updating User's Account with Stripe Customer ID token - tok_123456abcde
   */
  onSubmitPayment = async (token, zip, name) => {
    const { accounts } = this.props;

    try {
      if (token) {
        const data = {
          source: token.id
        };
        if (zip) {
          data.address = {
            postal_code: zip
          };
        }
        if (name) {
          data.name = name;
        }
        await Payments.updateCustomer(accounts.data.stripe_token, data);
        window.Intercom('trackEvent', 'credit-card', {
          timestamp: new Date(),
          added: true,
          account: accounts.data.id
        });
      }
      this.setState({ activeForm: this.state.activeForm + 1 });
    } catch (error) {
      this.setState({ error: error.message });
      throw error;
    }
  };

  onClickBack = () => {
    this.setState({ activeForm: this.state.activeForm - 1 });
  };

  onClickAlreadyHaveAccount = () => {
    this.setState({ toSignIn: true });
  };

  welcomeUser = () => {
    const { auth } = this.props;
    Users.welcomeUser({ email: auth.user.email });
  };

  render() {
    const { activeForm, email, discount, couponError } = this.state;
    const {
      updateUser,
      updateUserAccount,
      createUser,
      auth,
      accounts,
      fetchLatestAccount,
      getUserAccounts
    } = this.props;

    if (this.state.toDashboard === true) {
      console.log('going to dashboard!');
      return <Redirect to="/integrations/fraud-blocker-tracker" />;
    }

    if (this.state.toSignIn === true) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={styles.all}>
        <Helmet>
          <title>Register | Fraud Blocker</title>
        </Helmet>
        <div className={`${styles.content} ${activeForm === 2 ? styles.contentCenter : ''}`}>
          {activeForm <= 3 && (
            <div className={styles.leftContentContainer}>
              <img src={LOGO} className={styles.logo} />
              <div className={styles.testimonialContainer}>
                <div className={styles.testimonialLogo}>
                  <img src={TESTIMONIAL} alt="testimonial" />
                </div>
                <div>
                  <div className={styles.stars}>
                    <img src={STARS} alt="stars" />
                  </div>
                  <p className={styles.testimonialText}>
                    “They greatly reduced my ad fraud and improved my campaign performance. Highly
                    recommend!”
                  </p>
                  <p className={styles.testimonialAuthor}>Matthew C., Sr. Marketing Manager</p>
                </div>
              </div>
              <img src={QUOTES} className={styles.techtimes} />
              <div className={styles.checkFeatures}>
                <img src={TWO_FOUR} className={styles.icon} />
                <div>
                  <div className={styles.checkTitle}>24/7 Account Support</div>
                  <div className={styles.checkText}>Email, call or chat with our team anytime</div>
                </div>
              </div>
              <div className={styles.checkFeatures}>
                <img src={CODE} className={styles.icon} />
                <div>
                  <div className={styles.checkTitle}>Easy Installation - No developer needed</div>
                  <div className={styles.checkText}>
                    Install in minutes and start blocking fraud today
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.rightContentContainer}>
            <div className={styles.topContainer}>
              {/* {activeForm <= 2 && (
                <div className={styles.bubbleContainer}>
                  {[0, 1, 2].map(item => (
                    <div
                      key={item}
                      style={activeForm === item ? customStyles.activeBubble : customStyles.bubble}
                    />
                  ))}
                </div>
              )} */}
              {activeForm === 0 && (
                <p>
                  Already have an account?{' '}
                  <span onClick={this.onClickAlreadyHaveAccount} className={styles.signInNowText}>
                    Sign in now
                  </span>
                </p>
              )}
            </div>
            {activeForm === 0 && (
              <div>
                <SignUpForm
                  setEmail={this.setEmail}
                  email={email}
                  createUser={createUser}
                  onClickNext={this.onClickNextSignupClick}
                />
              </div>
            )}

            {activeForm === 1 && (
              <AccountCreationForm
                onClickBack={this.onClickBack}
                onClickNext={this.onClickNextAccountCreation}
                updateUser={updateUser}
                updateUserAccount={updateUserAccount}
                user={auth.user}
                discount={discount}
                couponError={couponError}
                accounts={accounts}
                currency={auth.user.currency}
                conversionRates={accounts.conversionRates}
              />
            )}

            {activeForm === 2 && (
              <div>
                <StripeProvider apiKey={Constants.stripePublicKey}>
                  <Elements>
                    <PaymentForm
                      onSubmit={this.onSubmitPayment}
                      onClickBack={this.onClickBack}
                      currency={auth.user.currency}
                      user={auth.user}
                      updateUser={updateUser}
                      accounts={accounts}
                      discount={discount}
                      conversionRates={accounts.conversionRates}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            )}

            {activeForm > 2 && auth && auth.user && (
              <div>
                <FinishedRegistration
                  accounts={accounts}
                  user={auth.user}
                  fetchLatestAccount={fetchLatestAccount}
                  getUserAccounts={getUserAccounts}
                  welcomeUser={this.welcomeUser}
                />
              </div>
            )}

            {/* {activeForm > 2 &&
              !(auth && auth.user && Validation.userHasAllRequiredFields(auth.user)) && (
                <div>
                  <ThankYou
                    welcomeUser={this.welcomeUser}
                    onClickHomepage={this.onClickHomepage}
                    history={this.props.history}
                  />
                </div>
              )} */}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Register.propTypes = {
  auth: PropTypes.object,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  accounts: PropTypes.object,
  updateUserAccount: PropTypes.func,
  getUserAccounts: PropTypes.func,
  fetchLatestAccount: PropTypes.func,
  activeDomain: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  activeDomain: state.activeDomain
});

const mapDispatchToProps = dispatch => {
  return {
    createUser: data => dispatch(User.createUser(data)),
    updateUser: (id, data) => dispatch(User.updateUser(id, data)),
    updateUserAccount: (accountId, data) => dispatch(Account.updateUserAccount(accountId, data)),
    getUserAccounts: id => dispatch(Account.getUserAccounts(id)),
    fetchLatestAccount: id => dispatch(Account.fetchLatestAccount(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);

import React, { PureComponent } from 'react';
import StatsContainer from '../../containers/Stats/Stats';

class Stats extends PureComponent {
  render() {
    return <StatsContainer />;
  }
}

export default Stats;

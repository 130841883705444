/**
 * USER ACTIONS
 */

export const AUTHENTICATING = 'AUTHENTICATING';
export const AUTHENTICATION_FAIL = 'AUTHENTICATION_FAIL';

export const CREATING_USER_SUCCESS = 'CREATING_USER_SUCCESS';
export const CREATING_USER_FAIL = 'CREATING_USER_FAIL';

export const FETCHING_USER = 'FETCHING_USER';
export const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS';
export const FETCHING_USER_FAIL = 'FETCHING_USER_FAIL';

export const UPDATING_USER = 'UPDATING_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const SET_USER_DELETED = 'SET_USER_DELETED';

export const LOGOUT_USER = 'LOGOUT_USER';

/**
 * ACCOUNT ACTIONS
 */

export const FETCHING_ACCOUNTS = 'FETCHING_ACCOUNTS';
export const FETCHING_ACCOUNTS_SUCCESS = 'FETCHING_ACCOUNTS_SUCCESS';
export const FETCHING_ACCOUNTS_FAIL = 'FETCHING_ACCOUNTS_FAIL';

export const FETCHING_ACCOUNTS_SUBSCRIPTION = 'FETCHING_ACCOUNTS_SUBSCRIPTION';
export const FETCHING_ACCOUNTS_SUBSCRIPTION_SUCCESS = 'FETCHING_ACCOUNTS_SUBSCRIPTION_SUCCESS';
export const FETCHING_ACCOUNTS_SUBSCRIPTION_FAIL = 'FETCHING_ACCOUNTS_SUBSCRIPTION_FAIL';
export const SUBSCRIPTION_INVALID = 'SUBSCRIPTION_INVALID';
export const SUBSCRIPTION_VALID = 'SUBSCRIPTION_VALID';

export const UPDATING_ACCOUNTS = 'UPDATING_ACCOUNTS';
export const UPDATE_ACCOUNTS_SUCCESS = 'UPDATE_ACCOUNTS_SUCCESS';
export const UPDATE_ACCOUNTS_FAIL = 'UPDATE_ACCOUNTS_FAIL';
export const UPDATE_ACCOUNT_DOMAINS = 'UPDATE_ACCOUNT_DOMAINS';

export const SET_CONVERSION_RATES = 'SET_CONVERSION_RATES';

export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';

/**
 * DOMAIN ACTIONS
 */

export const SET_DOMAIN_ACTIVE = 'SET_DOMAIN_ACTIVE';
export const UPDATING_DOMAIN = 'UPDATING_DOMAIN';
export const UPDATE_DOMAIN_SUCCESS = 'UPDATE_DOMAIN_SUCCESS';
export const UPDATE_DOMAIN_FAIL = 'UPDATE_DOMAIN_FAIL';
export const RESET_DOMAIN_ACTIVE = 'RESET_DOMAIN_ACTIVE';

/**
 * IP BLOCK LIST
 */

export const FETCHING_BLOCKLIST = 'FETCHING_BLOCKLIST';
export const FETCHING_BLOCKLIST_SUCCESS = 'FETCHING_BLOCKLIST_SUCCESS';
export const FETCHING_BLOCKLIST_FAIL = 'FETCHING_BLOCKLIST_FAIL';
export const RESET_BLOCKLIST = 'RESET_BLOCKLIST';

import React, { PureComponent } from 'react';
import AdvertsingContainer from '../../containers/Advertising/Advertising';

class Advertising extends PureComponent {
  render() {
    return <AdvertsingContainer />;
  }
}

export default Advertising;

import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { JsonToCsv } from 'react-json-csv';
import styles from './Stats.module.scss';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import StatsTable, { getPrimaryFraudType, getStatus } from '../ResultTable/StatsTableNew';
import AdSelector from '../../components/AdSelector/AdSelector';
import Data from '../../api/Data';
import DatesSelector from '../DatesSelector/DatesSelector';
import GoogleAds from '../../api/GoogleAds';
import IPBlockList from '../../redux/actions/IpBlockList';
import TOOLTIP from '../../assets/tooltip.svg';
import SYNC from '../../assets/sync.svg';
import ALERT from '../../assets/alert.png';
// import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import Utils from '../../utils/Utils';
import NoteIcon from '../../assets/note-icon.png';

class Stats extends PureComponent {
  state = {
    fraudTypes: [
      { title: 'Excessive Clicks', key: 'excessiveClickerClicks' },
      { title: 'VPN / Datacenter / Tor', key: 'botDatacenterClicks' },
      { title: 'Blacklist / Abuse / Poor IP History', key: 'abuseHighRiskClicks' },
      { title: 'Risky Geo', key: 'riskyGeoClicks' },
      { title: 'Accidental Clicks', key: 'accidentalClickerClicks' },
      { title: 'Risky Device', key: 'riskyDeviceClicks' }
    ],
    records: [],
    adType: 'gclid',
    allBlockedIPAddresses: [],
    fetchingData: true,
    fetchingStats: true,
    errors: {},
    startDate: null,
    endDate: null,
    statsError: {},
    stats: {},
    riskScore: 0,
    csv: {
      fields: {
        ip: 'IP Address',
        status: 'Status',
        riskScore: 'Fraud Score',
        primaryFraudType: 'Primary Fraud Type',
        lastSeen: 'Last Seen'
      },
      data: []
    }
  };

  componentDidMount = async () => {
    const {
      ipBlocklist,
      ipWhitelist
      // accounts
    } = this.props;
    try {
      await this.fetchData(
        {
          startDate:
            localStorage.getItem('start_date') ||
            moment()
              .subtract(3, 'days')
              .format('YYYYMMDD'),
          endDate: localStorage.getItem('end_date') || moment().format('YYYYMMDD')
        },
        null
      );
      if (ipBlocklist.length === 0 || ipWhitelist.length === 0) {
        await this.fetchBlockedIPs();
      }

      this.setState({ fetchingData: false });
    } catch (error) {
      this.setState({ fetchingData: false });
    }
  };

  componentDidUpdate = async preProps => {
    const {
      ipBlocklist,
      ipWhitelist
      // accounts
    } = this.props;
    if (
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      preProps.activeDomain &&
      preProps.activeDomain.data &&
      preProps.activeDomain.data.id !== this.props.activeDomain.data.id
    ) {
      try {
        await this.fetchData({
          startDate:
            localStorage.getItem('start_date') ||
            moment()
              .subtract(3, 'days')
              .format('YYYYMMDD'),
          endDate: localStorage.getItem('end_date') || moment().format('YYYYMMDD')
        });
        if (ipBlocklist.length === 0 || ipWhitelist.length === 0) {
          await this.fetchBlockedIPs();
        }
        this.setState({ fetchingData: false });
      } catch (error) {
        this.setState({ fetchingData: false });
      }
    }
    ReactTooltip.rebuild();
  };

  setCSVData = data => {
    const { ipBlocklist, ipWhitelist } = this.props;
    const formatted = [];
    const listedIPs = ipBlocklist.concat(ipWhitelist);
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      formatted.push({
        ...item,
        lastSeen: item.lastSeen.value,
        primaryFraudType: getPrimaryFraudType(item),
        status: getStatus(
          listedIPs.find(ip => ip.address.includes(item.ip)),
          item.riskScore
        )
      });
    }
    this.setState({ csv: { ...this.state.csv, data: formatted } });
  };

  /**
   * Fetch Data for Result Table
   * @param {Object} body {
   * startDate: 'YYYYMMDD',
   * endDate: 'YYYYMMDD'
   * }
   */
  fetchData = async body => {
    try {
      if (
        !this.props.activeDomain ||
        !this.props.activeDomain.data ||
        !this.props.activeDomain.data.id
      ) {
        return;
      }
      const { timezone } = this.props.auth.user;
      this.setState({
        startDate: body.startDate || this.state.startDate,
        endDate: body.endDate || this.state.endDate,
        adType: body.adType || this.state.adType
      });
      const query = {
        startDate: moment(body.startDate || this.state.startDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        endDate: moment(body.endDate || this.state.endDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        sid: this.props.activeDomain.data.id,
        isAggressive: this.props.activeDomain.data.aggressive_blocking,
        timezone: Utils.sanitizeTimezoneString(timezone || '(GMT-07:00) America/Los_Angeles'),
        adType:
          (body.adType || this.state.adType) === 'all'
            ? undefined
            : body.adType || this.state.adType
      };
      this.fetchStats(query);
      this.setState({ fetchingData: true });
      const result = await Data.getAdReports(query);
      if (result && !result.errno) {
        if (result.length) {
          for (let i = 0; i < result.length; i += 1) {
            result[i].index = i;
          }
        }
        const parsedResults = Utils.formatTimeAndAddRowIdInReports(
          result,
          timezone || '(GMT-07:00) America/Los_Angeles'
        );
        this.setState({ records: parsedResults, errors: {} });
        this.setCSVData(parsedResults);
      } else {
        this.setState({ records: [], errors: {} });
        this.setCSVData([]);
      }
      this.setState({ fetchingData: false });
    } catch (error) {
      console.log(error);
      this.setState({ errors: error });
      this.setState({ fetchingData: false });
    }
  };

  fetchBlockedIPs = async () => {
    const { activeDomain, fetchLatestBlocklist } = this.props;
    if (!activeDomain || !activeDomain.data || !activeDomain.data.id) {
      return;
    }
    try {
      await fetchLatestBlocklist(activeDomain.data.id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  fetchStats = async query => {
    try {
      this.setState({ fetchingStats: true, statsError: {} });
      const result = await Data.getStats(query);
      if (result && !result.errno && result.length) {
        this.setState({ stats: result[0] });
        const riskScore = Utils.calcFraudScore((result[0].fraudClicks * 100) / result[0].clicks, 1);
        this.setState({ riskScore });
      } else {
        this.setState({ stats: {}, riskScore: 0 });
      }

      this.setState({ fetchingStats: false }, () => {
        const stats = document.querySelectorAll('.roll-in');
        stats.forEach(stat => {
          // pattern used to seperate input number from html into an array of numbers and non numbers. EX $65.3M -> ["$65.3M", "$", "65", ".", "3", "M"]
          const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?/;
          const time = 1000;
          let result1 = [...patt.exec(stat.textContent)];
          let fresh = true;

          // Remove first full match from result array (we dont need the full match, just the individual match groups).
          result1.shift();
          // Remove undefined values from result array where they didnt have a match in one of the optional regex groups
          result1 = result1.filter(res => res != null);

          while (stat.firstChild) {
            stat.removeChild(stat.firstChild);
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const res of result1) {
            if (Number.isNaN(parseInt(res, 10))) {
              stat.insertAdjacentHTML('beforeend', `<span>${res}</span>`);
            } else {
              for (let i = 0; i < res.length; i += 1) {
                stat.insertAdjacentHTML(
                  'beforeend',
                  `<span data-value="${res[i]}">
						<span>&ndash;</span>
						${Array(parseInt(res[i], 10) + 1)
              .join(0)
              .split(0)
              .map(
                (x, j) => `
							<span>${j}</span>
						`
              )
              .join('')}
					</span>`
                );
              }
            }
          }

          const ticks = [...stat.querySelectorAll('span[data-value]')];
          const activate = () => {
            const { top } = stat.getBoundingClientRect();
            const offset = window.innerHeight * 0.8;

            setTimeout(() => {
              fresh = false;
            }, time);

            if (top < offset) {
              setTimeout(
                () => {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const tick of ticks) {
                    const dist = parseInt(tick.getAttribute('data-value'), 10) + 1;
                    tick.style.transform = `translateY(-${dist * 100}%)`;
                  }
                  const avgRisk = this.state.riskScore;
                  const gauge = document.getElementById('circleInner');
                  const gaugeValue = avgRisk * 10 * 1.8 - 45;
                  if (gauge && gauge.style) {
                    gauge.style.transform = `rotate(${gaugeValue}deg)`;
                  }
                },
                fresh ? time : 0
              );
              window.removeEventListener('scroll', activate);
            }
          };
          window.addEventListener('scroll', activate);
          activate();
        });
      });
    } catch (error) {
      console.log(error);
      this.setState({ statsError: error, fetchingStats: false });
    }
  };

  onStatusChange = async (index, currentStatus) => {
    const { records } = this.state;
    const { ipBlocklist, ipWhitelist } = this.props;
    const listedIp = ipBlocklist.find(ipBlock => ipBlock.address.includes(records[index].ip));
    const listedWhite = ipWhitelist.find(ipBlock => ipBlock.address.includes(records[index].ip));

    if (currentStatus === 'Auto Blocked') {
      this.autoBlockIp(records[index].ip, false);
    } else if (currentStatus === 'Unblocked' && !listedWhite) {
      this.autoBlockIp(records[index].ip, true);
    } else if (currentStatus === 'Unblocked' && listedWhite) {
      await this.removeIPFromBlockList(records[index].ip, false);
      this.autoBlockIp(records[index].ip, true);
    } else if (!listedIp) {
      this.autoBlockIp(records[index].ip, true);
    } else {
      this.removeIPFromBlockList(records[index].ip, true);
    }
  };

  /**
   * Add Ip Address to all campaigns blocklist linked with user's google ads account
   */
  autoBlockIp = async (ipAddress, isBlocked = true) => {
    const { accounts, activeDomain } = this.props;
    try {
      const data = {
        account_id: accounts.data.id,
        address: ipAddress,
        is_blocked: isBlocked,
        domain_id: activeDomain.data.id
      };
      await GoogleAds.addIpToBlocklist({ ips: [data] });
      // fetch latest
      this.fetchBlockedIPs();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Remove Ip Address from all campaigns linked with User's google ads account blocklists
   */
  removeIPFromBlockList = async (ipAddress, isBlocked) => {
    const { ipBlocklist, ipWhitelist } = this.props;
    try {
      let selectedIp = null;
      if (isBlocked) {
        selectedIp = ipBlocklist.find(ipBlock => ipBlock.address === ipAddress);
      } else {
        selectedIp = ipWhitelist.find(ipBlock => ipBlock.address === ipAddress);
      }
      await GoogleAds.removeIpFromBlocklist({ ids: [selectedIp.id] });
      // fetch latest
      this.fetchBlockedIPs();
    } catch (error) {
      console.log(error);
    }
  };

  downloadResults = () => {};

  getFraudLevel = avg => {
    if (avg >= 7) {
      return 'high';
    }
    if (avg > 3) {
      return 'medium';
    }
    return 'low';
  };

  render() {
    const { ipBlocklist, accounts, ipWhitelist, activeDomain } = this.props;
    const { fetchingData, records, fraudTypes, stats, fetchingStats, riskScore } = this.state;

    return (
      <div className={styles.content}>
        <ReactTooltip id="latestScore" multiline={true} className={styles.tooltipContent}>
          <div>
            This score represents the average amount of activity from bots and other invalid
            activity to your advertising campaigns.
          </div>
        </ReactTooltip>
        <ReactTooltip id="ipsFromAds" multiline={true} className={styles.tooltipContent}>
          <div>
            These IP addresses are only those we detected to your site from advertising campaigns
            during the period you selected.
          </div>
        </ReactTooltip>
        <ReactTooltip
          id="excessiveClickerClicks"
          multiline={true}
          className={styles.tooltipContent}
        >
          <div>
            We block users that visit your site repeatedly in a short period of time (such as 5
            times within a minute). We avoid blocking &quot;real&quot; users you may be retargeting.
          </div>
        </ReactTooltip>
        <ReactTooltip
          id="accidentalClickerClicks"
          multiline={true}
          className={styles.tooltipContent}
        >
          <div>
            We block users that click your ad and bounce in less than 2 seconds. Our data indicates
            these are very often accidental clicks.
          </div>
        </ReactTooltip>
        <div className={styles.header}>
          <h1 className={styles.title}>Fraud Score</h1>
        </div>
        <div className={styles.topFiltersWrap}>
          <AdSelector showAll={false} handleAdChange={this.fetchData} />
          <DatesSelector handleDateChange={this.fetchData} />
        </div>
        {this.state.adType === 'msclkid' && (
          <div className={styles.info}>
            <div>
              <img src={NoteIcon} alt="alert" />
              IPs cannot be blocked automatically for Microsoft Ads.{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.fraudblocker.com/en/articles/8224653-can-i-protect-my-microsoft-ads-campaigns"
              >
                Learn More
              </a>
              .
            </div>
          </div>
        )}

        {!fetchingStats ? (
          <div className={styles.fraudThreatContainer}>
            <div className={styles.fraudThreatScoreContainer}>
              <div>
                <p className={styles.containerTitle}>
                  Threat Level
                  <a data-tip data-for="latestScore">
                    <img className={styles.tooltip} src={TOOLTIP} />
                  </a>
                </p>
              </div>

              <div className={styles.scoreContainer}>
                <div className={styles.gaugeHolder}>
                  <div className={styles.circleWrap}>
                    <div className={styles.circle}>
                      <div
                        id="circleInner"
                        className={`${styles.circleInner} ${
                          riskScore <= 4.5
                            ? styles.circleBlue
                            : riskScore > 4.5 && riskScore <= 7
                            ? styles.circleYellow
                            : styles.circleRed
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>

                <h1 className={`${styles.scoreText} roll-in`}>
                  {riskScore ? parseFloat(riskScore).toFixed(1) : 0}
                </h1>
                <p className={styles.scoreDescription}>
                  Your site received a{' '}
                  <span
                    className={`${
                      riskScore <= 4.5
                        ? styles.lowLevel
                        : riskScore > 4.5 && riskScore <= 7
                        ? styles.medLevel
                        : styles.highLevel
                    }`}
                  >
                    {this.getFraudLevel(riskScore)}
                  </span>{' '}
                  level of fraud threats
                </p>
              </div>

              <div className={styles.scoreFeatureContainer}>
                <div className={styles.scoreFeature}>
                  <p className={styles.scoreFeatureTitle}>Clean</p>
                  <p>
                    {(stats.cleanClicks || 0).toLocaleString('en-US', { maximumFractionDigits: 1 })}{' '}
                    clicks,{' '}
                    <span className={`${styles.rollWrap} roll-in`}>
                      {stats.cleanClicks
                        ? ((stats.cleanClicks * 100) / stats.clicks).toLocaleString('en-US', {
                            maximumFractionDigits: 1
                          })
                        : 0}
                      %
                    </span>
                  </p>
                </div>
                <div className={styles.scoreFeature}>
                  <p className={styles.scoreFeatureTitle}>Suspected</p>
                  <p>
                    {(stats.suspectedClicks || 0).toLocaleString('en-US', {
                      maximumFractionDigits: 1
                    })}{' '}
                    clicks,{' '}
                    <span className={`${styles.rollWrap} roll-in`}>
                      {stats.suspectedClicks
                        ? ((stats.suspectedClicks * 100) / stats.clicks).toLocaleString('en-US', {
                            maximumFractionDigits: 1
                          })
                        : 0}
                      %
                    </span>
                  </p>
                </div>
                <div className={styles.scoreFeature}>
                  <p className={styles.scoreFeatureTitle}>Invalid</p>
                  <p>
                    {(stats.fraudClicks || 0).toLocaleString('en-US', { maximumFractionDigits: 1 })}{' '}
                    clicks,{' '}
                    <span className={`${styles.rollWrap} roll-in`}>
                      {stats.fraudClicks
                        ? ((stats.fraudClicks * 100) / stats.clicks).toLocaleString('en-US', {
                            maximumFractionDigits: 1
                          })
                        : 0}
                      %
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.horizontalDivider} />

            <div className={styles.blockedFraudTypeContainer}>
              <div style={{ display: 'inline' }} className={styles.containerTitle}>
                Blocked Fraud Type
                {activeDomain.data &&
                  activeDomain.data.last_sync_time &&
                  this.state.adType !== 'msclkid' && (
                    <div className={styles.lastSyncDate}>
                      <img src={SYNC} atl="sync" />
                      <span>Last Sync Time</span>
                      {moment(activeDomain.data.last_sync_time).format('MMM DD, YYYY')} at{' '}
                      {moment(activeDomain.data.last_sync_time).format('HH:mm')}
                    </div>
                  )}
                {activeDomain.data && this.state.adType === 'msclkid' && (
                  <div className={styles.lastSyncDate}>
                    <img src={ALERT} atl="info" />
                    <span>
                      Microsoft Ads Does Not Sync Automatically.{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.fraudblocker.com/en/articles/8224653-can-i-protect-my-microsoft-ads-campaigns"
                      >
                        Learn More
                      </a>
                    </span>
                  </div>
                )}
              </div>

              {fraudTypes.map((type, index) => {
                return (
                  <div key={index}>
                    <div className={styles.percentageTextContainer}>
                      <p>
                        {type.title}
                        {(type.key === 'excessiveClickerClicks' ||
                          type.key === 'accidentalClickerClicks') && (
                          <a data-tip data-for={type.key}>
                            <img className={styles.tooltip} src={TOOLTIP} />
                          </a>
                        )}
                      </p>
                      <p>
                        {stats[type.key] || 0} clicks,{' '}
                        {stats[type.key]
                          ? ((stats[type.key] * 100) / stats.fraudClicks).toFixed(1)
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      color="#FC584E"
                      percentage={stats[type.key] ? (stats[type.key] * 100) / stats.fraudClicks : 0}
                    />
                  </div>
                );
              })}

              <div className={styles.vpnContainer}>
                {/* <p>
                  <span className={styles.vpn}>VPN</span>{' '}
                  {activeDomain.data.vpn_blocking === false ? 'Not Enabled' : 'Enabled'}
                </p> */}
                <Link to="customizations/detection-rules" className={styles.link}>
                  Customize
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.chartLoading}>
            <div className={styles.graph}>
              <div className={styles.title} />
              <div className={styles.rainbow} />
              <div className={styles.bottom}>
                <div />
                <div />
                <div />
              </div>
            </div>
            <div className={styles.border} />
            <div className={styles.progress}>
              <div className={styles.title} />
              <div className={styles.bars}>
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={styles.vpn}>
                <div className={styles.enable} />
                <div className={styles.settings} />
              </div>
            </div>
          </div>
        )}

        {fetchingData ? (
          <div className={styles.tableStatsLoading}>
            <div className={styles.numbers}>
              <div className={styles.title} />
              <div className={styles.downloads} />
            </div>
            <div className={styles.table} />
            <div className={styles.footer} />
          </div>
        ) : (
          <div className={styles.detailsContainer}>
            <div className={styles.detailsContainerHeader}>
              <p className={`${styles.containerTitle} ${styles.gridTitle}`}>
                IPs from Advertising
                <a data-tip data-for="ipsFromAds">
                  <img className={styles.tooltip} src={TOOLTIP} />
                </a>
              </p>
              {/* {auth.user.role !== 'Viewer' && (
                <JsonToCsv
                  data={csv.data}
                  filename="stats"
                  style={{ background: 'none', border: 'none' }}
                  fields={csv.fields}
                  text={
                    <div className={styles.download}>
                      <DownloadIcon className={styles.downloadIcon} />
                      Download Results
                    </div>
                  }
                />
              )} */}
            </div>

            <StatsTable
              loading={fetchingData}
              results={records}
              maxHeight={400}
              ipBlocklist={ipBlocklist.concat(ipWhitelist)}
              onStatusChange={this.onStatusChange}
              accounts={accounts}
              activeDomain={activeDomain}
            />
          </div>
        )}
      </div>
    );
  }
}

Stats.propTypes = {
  auth: PropTypes.object,
  accounts: PropTypes.object,
  ipBlocklist: PropTypes.array,
  ipWhitelist: PropTypes.array,
  fetchLatestBlocklist: PropTypes.func,
  activeDomain: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  ipBlocklist: state.ipBlocklist.data,
  activeDomain: state.activeDomain,
  ipWhitelist: state.ipBlocklist.whiteIPs
});

const mapDispatchToProps = dispatch => {
  return {
    fetchLatestBlocklist: accountId => dispatch(IPBlockList.fetchLatestBlocklist(accountId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);

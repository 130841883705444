import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../containers/Loading/Loading';
import DashboardLoading from '../containers/Loading/DashboardLoading';
import StatsLoading from '../containers/Loading/StatsLoading';
import ReportLoading from '../containers/Loading/ReportLoading';
import Validation from '../utils/Validation';

const AuthedRoute = ({ component: Component, accounts, auth, ...rest }) => {
  const { user, isFetching } = auth;
  const { isFetching: accountsFetching, fetchingSubscription, subscription } = accounts;
  if (isFetching || accountsFetching || fetchingSubscription) {
    if (rest.location.pathname === '/dashboard') {
      return <DashboardLoading />;
    }
    if (rest.location.pathname === '/stats') {
      return <StatsLoading />;
    }
    if (rest.location.pathname === '/advertising') {
      return <ReportLoading />;
    }
    return <Loading />;
  }

  if (!user) {
    return (
      <Route
        {...rest}
        render={() => <Redirect to={`/login?destination=${rest.location.pathname}`} />}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        Validation.userHasAllRequiredFields(user, accounts.data, subscription) ? (
          <Component {...props} />
        ) : (
          <Redirect {...rest} to="/register" />
        )
      }
    />
  );
};

AuthedRoute.propTypes = {
  component: PropTypes.any,
  auth: PropTypes.object,
  accounts: PropTypes.object
};

export default AuthedRoute;

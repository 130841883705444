import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { parseDomain, fromUrl } from 'parse-domain';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'qs';
import Input from '../../components/Input/Input';
import styles from './AppSumoRegister.module.scss';
import LOGO from '../../assets/main-logo.svg';
import LOGO_SMALL from '../../assets/small-logo.svg';
import User from '../../redux/actions/User';
import Account from '../../redux/actions/Account';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Validation from '../../utils/Validation';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';
import LockIcon from '../../assets/lock_icon_email.svg';
import Achievements from '../../assets/achievements.svg';
import SumoWelcome from '../../assets/welcome-appsumo.svg';
import SumoLings from '../../assets/sumo-ling.svg';
import Taco from '../../assets/taco-sumo.svg';
import Separator from '../../assets/separator.svg';
import AccountReadyModal from './components/AccountReady';
import AccountExistModal from './components/AccountExistModal';
// import UserApi from '../../api/Users';
// import Payments from '../../api/Payments';
// import Users from '../../api/Users';

const customStyles = {
  input: {
    marginBottom: 25
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: '#666666'
  }
};

class AppSumoRegister extends PureComponent {
  state = {
    email: '',
    domain: '',
    password: '',
    errors: {},
    toDashboard: false,
    toSignIn: false,
    loading: false,
    isAccountExistModalOpen: false,
    isAccountReadyModalOpen: false,
    appSumoPlanId: '',
    planId: '',
    invoiceId: ''
  };

  componentDidMount = () => {
    ReactTooltip.rebuild();
    document.getElementById('favicon').href = 'signup-favicon.ico';
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    this.setState({ email: query.email, appSumoPlanId: query.appSumoPlanId, planId: query.planId, invoiceId: query.invoiceId });
  };

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    this.setState({ loading: true });
    const { domain, password, email, appSumoPlanId, planId } = this.state;
    const { completeAppSumoUser } = this.props;
    const data = {
      domain,
      email,
      password
    };

    const newErrors = Validation.validateForm(data);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }
    try {
      const result = await User.createUserWithEmailAndPassword(
        this.state.email,
        this.state.password
      );
      if (result) {
        const parseResult = parseDomain(fromUrl(data.domain));
        let parsedDomain = `${parseResult.icann.domain}.${parseResult.icann.topLevelDomains.join(
          '.'
        )}`;
        if (parseResult.icann.subDomains && parseResult.icann.subDomains.length) {
          const subDomains = parseResult.icann.subDomains.filter(
            name => name.toLocaleLowerCase() !== 'www'
          );
          if (subDomains.length) {
            parsedDomain = `${subDomains.join('.')}.${parsedDomain}`;
          }
        }
        const userData = {
          domain: parsedDomain,
          email: data.email,
          id: result.user.uid,
          appSumoPlanId
        };

        const createUserInDBResponse = await completeAppSumoUser(userData, planId);
        if (createUserInDBResponse) {
          this.setState({ toDashboard: true });
        } else {
          throw new Error('Error creating user account');
        }
      } else {
        throw new Error('Error creating user account');
      }
    } catch (error) {
      console.log(error);
      if (error.message.includes('already') || (error.code && error.code.includes('already'))) {
        this.setState({ isAccountExistModalOpen: true, loading: false });
        return;
      }
      this.setState({ errors: { signUp: error.message }, loading: false });
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onClickNext();
    }
  };

  goToLogin = () => {
    this.setState({ toSignIn: true });
  };

  toggleAccountExist = () => {
    this.setState({ isAccountExistModalOpen: false });
  }

  toggleAccountReady = () => {
    this.setState({ isAccountReadyModalOpen: false });
  }

  onAccountLinked = () => {
    this.setState({ isAccountExistModalOpen: false, isAccountReadyModalOpen: true });
  };

  render() {
    const { email, domain, password, errors, loading, isAccountReadyModalOpen, isAccountExistModalOpen, appSumoPlanId, invoiceId, planId } = this.state;
    // const {
    //   updateUser,
    //   updateUserAccount,
    //   createUser,
    //   auth,
    //   accounts,
    //   fetchLatestAccount,
    //   getUserAccounts
    // } = this.props;

    if (this.state.toDashboard === true) {
      console.log('going to dashboard!');
      return <Redirect to="/integrations/fraud-blocker-tracker" />;
    }

    if (this.state.toSignIn === true) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={`${styles.all} ${styles.appSumoReg}`}>
        <Helmet>
          <title>Register | Fraud Blocker</title>
        </Helmet>
        <div className={styles.content}>
          <div className={styles.rightContentContainer}>
            <div className={styles.regForm}>
              <img src={LOGO} className={styles.logo} />
              <img src={LOGO_SMALL} className={styles.logoSmall} />
              <ReactTooltip className={styles.tooltip} id="moreWebsites">
                You can add more websites or change them later
              </ReactTooltip>
              <div className={styles.formContainer}>
                <h1 className={styles.headerText}>Create your account</h1>
                <Input
                  name="domain"
                  value={domain}
                  onChange={this.onChangeText}
                  className={styles.inputContainer}
                  labelStyle={customStyles.inputLabel}
                  containerStyle={customStyles.input}
                  label={
                    <span>
                      Website to Protect{' '}
                      <TooltipIcon className={styles.registerHelpTip} data-tip data-for="moreWebsites" />
                    </span>
                  }
                  placeholder="example.com"
                  error={errors.domain || null}
                />
                <Input
                  name="email"
                  value={email}
                  disabled
                  onChange={() => { }}
                  onKeyPress={this.handleKeyPress}
                  labelStyle={customStyles.inputLabel}
                  containerStyle={customStyles.input}
                  label="Email"
                  placeholder="joanna@example.com"
                  error={errors.email || null}
                  icon={LockIcon}
                />
                <Input
                  name="password"
                  value={password}
                  type="password"
                  onChange={this.onChangeText}
                  onKeyPress={this.handleKeyPress}
                  labelStyle={customStyles.inputLabel}
                  label="Password"
                  showEye
                  error={errors.password || null}
                />
                <p className={styles.passwordInfo}>
                  Your password must be at least 8 characters. We recommend at least 1 lowercase, 1
                  uppercase, and 1 number.
                </p>
                {errors.signUp && <ErrorBox error={errors.signUp} />}
                <div className={styles.formFooterContainer}>
                  <Button
                    title="Next"
                    onClick={() => this.onSubmit()}
                    style={customStyles.nextBtn}
                    loading={loading}
                    customClassNames="signUpForm__nextBtn"
                    color="new-green"
                  />
                  <p>
                    By clicking this button you agree to Fraud Blocker&apos;s{' '}
                    <a
                      href="https://fraudblocker.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`blue ${styles.link}`}
                    >
                      Terms of Service
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.leftContentContainer}>
            <div className={styles.sumoLings}>
              <div className={styles.tacoIcon}><img src={Taco} /></div>
              <div className={styles.welcomeIcon}><img src={SumoWelcome} /></div>
              <div className={styles.sumoIcon}><img src={SumoLings} /></div>
              <div className={styles.thanksForPurchase}>
                Thanks for purchasing Fraud Blocker
              </div>
              <div className={styles.fraudProtectLeader}>the leader in click fraud protection</div>
              <div className={styles.sharpSeparator}><img src={Separator} /></div>

              <div className={styles.customerStats}>
                <div className={styles.customStatsBlock}>
                  <div className={styles.customStatsNumbers}>4,000</div>
                  <div className={styles.customStatsDesc}>Client accounts</div>
                </div>
                <div className={styles.customStatsBlock}>
                  <div className={styles.customStatsNumbers}>12 Million</div>
                  <div className={styles.customStatsDesc}>IPs analyzed per month</div>
                </div>
                <div className={styles.customStatsBlock}>
                  <div className={styles.customStatsNumbers}>11,000</div>
                  <div className={styles.customStatsDesc}>Domain names on our Platform</div>
                </div>
              </div>
              <div className={styles.achievements}><img src={Achievements} /></div>
            </div>
          </div>
        </div>
        {isAccountReadyModalOpen && <AccountReadyModal isOpen={isAccountReadyModalOpen} buttons={[{
          title: 'Login to Dashboard',
          color: 'lt-blue',
          action: this.goToLogin
        }]} toggleModal={this.toggleAccountReady} />}
        {isAccountExistModalOpen && <AccountExistModal appSumoPlanId={appSumoPlanId} planId={planId} invoiceId={invoiceId} redirectUser={this.onAccountLinked} isOpen={isAccountExistModalOpen} email={email} />}
        {/* <Footer /> */}
      </div>
    );
  }
}

AppSumoRegister.propTypes = {
  auth: PropTypes.object,
  completeAppSumoUser: PropTypes.func,
  updateUser: PropTypes.func,
  accounts: PropTypes.object,
  updateUserAccount: PropTypes.func,
  getUserAccounts: PropTypes.func,
  fetchLatestAccount: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.any
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts
});

const mapDispatchToProps = dispatch => {
  return {
    completeAppSumoUser: (data, planId) => dispatch(User.completeAppSumoUser(data, planId)),
    updateUser: (id, data) => dispatch(User.updateUser(id, data)),
    updateUserAccount: (accountId, data) => dispatch(Account.updateUserAccount(accountId, data)),
    getUserAccounts: id => dispatch(Account.getUserAccounts(id)),
    fetchLatestAccount: id => dispatch(Account.fetchLatestAccount(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSumoRegister);

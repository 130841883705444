import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import styles from './RangeSlider.module.scss';

const rangeClicksMap = {
  '10000': 10000,
  '20000': 25000,
  '30000': 50000,
  '40000': 75000,
  '50000': 100000
};

class RangeSlider extends PureComponent {
  componentDidMount() {
    if (this.props.onInit) {
      this.props.onInit();
    }
  }

  componentDidUpdate() {
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  handleChange(_, newValue) {
    this.props.handleChange(Number(newValue));
  }

  labelFormatter(value) {
    const rangeClickMap = this.props.rangeClicksMap || rangeClicksMap;
    return rangeClickMap[value.toString()].toLocaleString('en-US', {
      maximumFractionDigits: 1
    });
  }

  render() {
    return (
      <form className={styles.form}>
        <Slider
          sx={{
            color: '#17d384',
            '.MuiSlider-rail': {
              backgroundColor: '#c8d0da',
              opacity: 1
            },
            '.MuiSlider-thumb': {
              width: '18px',
              height: '18px',
              top: '44%'
            },
            '.MuiSlider-mark': {
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: '#c8d0da',
              opacity: 1
            },
            '.MuiSlider-markActive': {
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: '#17d384',
              opacity: 1
            }
          }}
          aria-label="Clicks"
          disabled={this.props.disabled || false}
          defaultValue={this.props.value}
          value={this.props.value}
          getAriaValueText={this.labelFormatter.bind(this)}
          // valueLabelFormat={this.labelFormatter.bind(this)}
          onChange={(_, newVal) => this.handleChange(_, newVal)}
          step={this.props.step || 5000}
          marks={this.props.markers.map(mark => ({
            value: mark,
            label: ''
          }))}
          min={this.props.min || 10000}
          max={this.props.max || 50000}
        />
        {/* <input
          disabled={this.props.disabled || false}
          onChange={this.props.handleChange}
          type="range"
          id={this.props.id || 'vol'}
          name="vol"
          step={this.props.step || '5000'}
          min={this.props.min || '10000'}
          max={this.props.max || '100000'}
          value={this.props.value}
          list="markers"
        /> */}
      </form>
    );
  }
}

RangeSlider.propTypes = {
  disabled: PropTypes.bool,
  onInit: PropTypes.func,
  onUpdate: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  markers: PropTypes.array,
  rangeClicksMap: PropTypes.object
};

RangeSlider.defaultProps = {
  disabled: false
};

export default RangeSlider;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import styles from './AuthAction.module.scss';
import { ReactComponent as MAINLOGO } from '../../assets/main-logo.svg';
import Footer from '../../components/Footer/Footer';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Validation from '../../utils/Validation';
import User from '../../redux/actions/User';

const customStyle = {
  input: {
    fontWeight: 'bold',
    height: 45
  },
  inputContainer: {
    marginBottom: 40
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: '#666666'
  }
};

class AuthAction extends PureComponent {
  constructor(props) {
    super(props);

    const query = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    });

    this.state = {
      email: '',
      mode: query.mode,
      oobCode: query.oobCode,
      password: '',
      redirectToLogin: false,
      errors: {},
      loading: false
    };
  }

  componentDidMount = () => {
    this.verifyPasswordResetCode();
  };

  verifyPasswordResetCode = async () => {
    const { oobCode } = this.state;
    try {
      const result = await User.verifyPasswordResetCode(oobCode);
      this.setState({ email: result });
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/expired-action-code') {
        this.setState({
          errors: { resetError: 'Password reset action has expired. Please request a new link.' }
        });
      } else {
        this.setState({ errors: { resetError: error.message } });
      }
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onClickSetPassword();
    }
  };

  onClickSetPassword = async () => {
    const { password, oobCode } = this.state;
    const data = {
      password
    };

    const newErrors = Validation.validateForm(data);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }

    this.setState({ loading: true });

    try {
      const result = await User.confirmPasswordReset(oobCode, password);
      if (result) {
        this.setState({ redirectToLogin: true, errors: {}, loading: false });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({ errors: { setPassword: error.message }, loading: false });
    }
  };

  render() {
    const { email, password, errors, redirectToLogin, loading } = this.state;

    if (redirectToLogin) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={styles.all}>
        {errors.resetError ? (
          <div className={styles.content}>
            <MAINLOGO />
            <h1 className={styles.headerText}>Set Password</h1>
            <p style={customStyle.inputLabel} className={styles.descriptionText}>
              {errors.resetError}
            </p>
          </div>
        ) : (
          <div className={styles.content}>
            <MAINLOGO />
            <h1 className={styles.headerText}>Set Password</h1>
            <p
              style={customStyle.inputLabel}
              className={styles.descriptionText}
            >{`Email: ${email}`}</p>

            <div className={styles.form}>
              <Input
                type="password"
                value={password}
                name="password"
                label="Password"
                labelStyle={customStyle.inputLabel}
                placeholder="Enter Password"
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                containerStyle={customStyle.inputContainer}
                style={customStyle.input}
                error={errors.password}
              />
            </div>
            {errors.setPassword && <ErrorBox error={errors.setPassword} />}
            <div className={styles.formFooterContainer}>
              <Button
                title="Set Password"
                loading={loading}
                onClick={this.onClickSetPassword}
                color="green"
              />
              <div className={styles.empty} />
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

AuthAction.propTypes = {
  location: PropTypes.any
};

export default AuthAction;

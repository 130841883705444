import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import styles from './CancelReasonModal.module.scss';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    width: 600,
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '30px',
    position: 'relative'
  }
};

class CancelReasonModal extends PureComponent {
  state = {
    reason: ''
  };

  onReasonChange = e => {
    this.setState({ reason: e.target.value });
  };

  render() {
    const { toggleModal, isOpen, handleCancelReason } = this.props;
    return (
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Alert" ariaHideApp={false}>
        <div className={styles.container}>
          <span className={styles.closeBtn} onClick={() => toggleModal()} aria-hidden="true">
            ×
          </span>
          <div className={styles.content}>
            <div className={styles.contentWrapper}>
              <p className={styles.headerText}>Cancelation Reason</p>
              <p className={styles.descriptionText}>
                Please tell us the reason for your cancellation
              </p>

              <section className={styles.reasons}>
                <div onClick={() => this.onReasonChange({ target: { value: 'too-expensive' } })}>
                  Too expensive{' '}
                  <input
                    type="radio"
                    name="reason"
                    value="too-expensive"
                    onChange={this.onReasonChange}
                    checked={this.state.reason === 'too-expensive'}
                  />
                </div>
                <div onClick={() => this.onReasonChange({ target: { value: 'tech-issue' } })}>
                  I&apos;m having technical issues{' '}
                  <input
                    type="radio"
                    name="reason"
                    value="tech-issue"
                    checked={this.state.reason === 'tech-issue'}
                    onChange={this.onReasonChange}
                  />
                </div>
                <div onClick={() => this.onReasonChange({ target: { value: 'no-results' } })}>
                  I&apos;m not seeing results{' '}
                  <input
                    type="radio"
                    name="reason"
                    value="no-results"
                    checked={this.state.reason === 'no-results'}
                    onChange={this.onReasonChange}
                  />
                </div>
                <div onClick={() => this.onReasonChange({ target: { value: 'paused-ppc' } })}>
                  I&apos;ve paused my PPC campaigns{' '}
                  <input
                    type="radio"
                    name="reason"
                    value="paused-ppc"
                    onChange={this.onReasonChange}
                    checked={this.state.reason === 'paused-ppc'}
                  />
                </div>
                <div onClick={() => this.onReasonChange({ target: { value: 'other-reasons' } })}>
                  I&apos;m cancelling for other reasons{' '}
                  <input
                    type="radio"
                    name="reason"
                    value="other-reasons"
                    checked={this.state.reason === 'other-reasons'}
                    onChange={this.onReasonChange}
                  />
                </div>
              </section>

              <div className={styles.btnContainer}>
                <Button
                  onClick={() => handleCancelReason(this.state.reason)}
                  title="Continue With Cancelation"
                  color="lt-blue"
                  disabled={!this.state.reason}
                />
              </div>
              <div className={styles.btnContainer}>
                <a href="#/" onClick={() => toggleModal()}>
                  Do Not Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

CancelReasonModal.propTypes = {
  isOpen: PropTypes.bool,
  isBooster: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleCancelReason: PropTypes.func
};

export default CancelReasonModal;

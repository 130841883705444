import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import styles from './SetPassword.module.scss';
import { ReactComponent as MAINLOGO } from '../../assets/main-logo.svg';
// import Footer from '../../components/Footer/Footer';
import FacebookIcon from '../../assets/facebook-icon.svg';
import InstagramIcon from '../../assets/instagram-icon.svg';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Validation from '../../utils/Validation';
import Users from '../../api/Users';
import LinkButton from '../../components/LinkButton/LinkButton';

const customStyle = {
  input: {
    fontWeight: 'bold'
  },
  inputContainer: {
    marginBottom: 0
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: '#666666'
  },
  availBtn: {
    display: 'inline-flex',
    textDecoration: 'none'
  }
};

class SetPassword extends PureComponent {
  constructor(props) {
    super(props);

    console.log('SET PASS CONSTRUCTOR');
    this.state = {
      email: this.props.match.params.email || '',
      invitation_id: this.props.match.params.invitation_id || '',
      password: '',
      redirectToLogin: false,
      errors: {},
      invitationExpired: false
    };
  }

  componentDidMount = () => {
    const { invitation_id: invitationId } = this.state;
    this.checkInvitationExpiration(invitationId);
  };

  checkInvitationExpiration = async invitationId => {
    try {
      const isExpired = await Users.checkInvitationExpiration(invitationId);
      if (isExpired) {
        this.setState({ invitationExpired: true });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({ invitationExpired: true });
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onClickSetPassword();
    }
  };

  onClickSetPassword = async () => {
    const { email, password, invitation_id: invitationId } = this.state;
    console.log(password);
    const data = {
      email,
      password,
      invitation_id: invitationId
    };

    const newErrors = Validation.validateForm(data);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }

    try {
      const result = await Users.setPassword(data);
      if (result) {
        this.setState({ redirectToLogin: true, errors: {} });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({ errors: { setPassword: error.message } });
    }
  };

  render() {
    const { password, errors, redirectToLogin, invitationExpired } = this.state;

    if (redirectToLogin) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={styles.all}>
        {invitationExpired ? (
          <div className={styles.loginBox}>
            <div className={styles.loginBoxInner}>
              <div className={styles.logo}>
                <MAINLOGO />
              </div>
              <h1 className={styles.headerText}>Set Your New Password</h1>
              <p
                style={customStyle.inputLabel}
                className={styles.descriptionText}
              >{`Invitation has expired, please ask your account owner to resend an invitation.`}</p>
            </div>
          </div>
        ) : (
          <div className={styles.loginBox}>
            <div className={styles.loginBoxInner}>
              <div className={styles.logo}>
                <MAINLOGO />
              </div>
              <h1 className={styles.headerText}>Set Your New Password</h1>
              {/* <p
                style={customStyle.inputLabel}
                className={styles.descriptionText}
              >{`Email: ${email}`}</p> */}
              <div className={`${styles.loginForm} ${styles.setPassForm}`}>
                <Input
                  type="password"
                  value={password}
                  name="password"
                  label="Password"
                  labelStyle={customStyle.inputLabel}
                  placeholder="Enter Password"
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  containerStyle={customStyle.inputContainer}
                  style={customStyle.input}
                  error={errors.password}
                  showEye
                />
                <div className={styles.passwordInfo}>
                  Your password must be at least 8 characters. We recommend at least 1 lowercase, 1
                  uppercase, and 1 number.
                </div>
              </div>
              {errors.setPassword && <ErrorBox error={errors.setPassword} />}
              <div className={styles.twoInputsContainer}>
                <Button title="Set Password" onClick={this.onClickSetPassword} color="green" />
              </div>{' '}
              <p>
                By clicking this button you agree to Fraud Blocker&apos;s{' '}
                <a
                  href="https://fraudblocker.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`blue ${styles.link}`}
                >
                  Terms of Service
                </a>
              </p>
            </div>
          </div>
        )}
        <div className={styles.loginRight}>
          <div className={styles.loginInfo}>
            <div className={styles.socialIcons}>
              <div>
                <img src={FacebookIcon} alt="Facebook" />
              </div>
              <div>
                <img src={InstagramIcon} alt="Instagram" />
              </div>
            </div>
            <div className={styles.btnAvail}>
              <Button title="Now Available" color="blue" style={customStyle.availBtn} />
            </div>
            <h2>
              Ad fraud protection for <span className={styles.facebook}>Facebook</span> and{' '}
              <span className={styles.instagram}>Instagram</span>
            </h2>
            <div className={styles.readBtn}>
              <LinkButton
                isExternal={true}
                to="https://fraudblocker.com/articles/fraud-blocker-2-0"
                title="Read Announcement"
                color="outline-white"
                style={customStyle.availBtn}
                anchorProps={{ target: '_blank' }}
              />
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

SetPassword.propTypes = {
  match: PropTypes.any
};

export default SetPassword;

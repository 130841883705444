/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Login, Register, ResetPassword, SetPassword, AuthAction, AppSumoRegister } from '../pages';
import RegisterNew from '../pages/RegisterNew/RegisterNew';
import AuthedRoute from './AuthedRoute';
import DefaultLayout from '../containers/DefaultLayout/DefaultLayout';
import RouteChangeHandler from './RouteChangeHandler';

class Router extends PureComponent {
  render() {
    const { auth, accounts } = this.props;
    return (
      <BrowserRouter>
        <RouteChangeHandler>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route
              exact
              path="/reset-password"
              name="Reset Password Page"
              component={ResetPassword}
            />

            <Route
              exact
              path="/set-password/:email/:invitation_id"
              name="Set Password"
              component={SetPassword}
            />

            <Route exact path="/auth/action" name="Auth Action" component={AuthAction} />
            <Route exact path="/register-legacy" name="Register Page" component={Register} />
            <Route exact path="/appsumo" name="AppSumo Registration" component={AppSumoRegister} />
            <Route exact path="/register" name="Register Page" component={RegisterNew} />
            <AuthedRoute
              accounts={accounts}
              auth={auth}
              path="/"
              name="Default"
              component={DefaultLayout}
            />
          </Switch>
        </RouteChangeHandler>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  auth: PropTypes.object,
  accounts: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts
});

export default connect(mapStateToProps)(Router);

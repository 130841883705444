import { ReactComponent as AndroidOS } from '../assets/os-icons/android.svg';
import { ReactComponent as ChromiumOS } from '../assets/os-icons/chromium.svg';
import { ReactComponent as GoogleChromeOS } from '../assets/os-icons/google-chrome.svg';
import { ReactComponent as iOS } from '../assets/os-icons/iOS.svg';
import { ReactComponent as MacOS } from '../assets/os-icons/mac.svg';
import { ReactComponent as LinuxOS } from '../assets/os-icons/linux.svg';
import { ReactComponent as WindowsOS } from '../assets/os-icons/windows.svg';
import { ReactComponent as OtherOS } from '../assets/os-icons/other.svg';
import { ReactComponent as SamsungOS } from '../assets/os-icons/samsung.svg';
import { ReactComponent as AlohaBrowser } from '../assets/browser-icons/Aloha.svg';
import { ReactComponent as BingBrowser } from '../assets/browser-icons/Bing.svg';
import { ReactComponent as BraveBrowser } from '../assets/browser-icons/Brave.svg';
import { ReactComponent as ChromiumBrowser } from '../assets/browser-icons/Chromium.svg';
import { ReactComponent as DuckDuckGoBrowser } from '../assets/browser-icons/DuckDuckGo.svg';
import { ReactComponent as ExplorerBrowser } from '../assets/browser-icons/Explorer.svg';
import { ReactComponent as FirefoxBrowser } from '../assets/browser-icons/Firefox.svg';
import { ReactComponent as GoogleChromeBrowser } from '../assets/browser-icons/Google-Chrome.svg';
import { ReactComponent as MaxthronBrowser } from '../assets/browser-icons/Maxthron.svg';
import { ReactComponent as OperaGXBrowser } from '../assets/browser-icons/Opera-GX.svg';
import { ReactComponent as OperaBrowser } from '../assets/browser-icons/Opera.svg';
import { ReactComponent as SafariBrowser } from '../assets/browser-icons/Safari.svg';
import { ReactComponent as OtherBrowser } from '../assets/browser-icons/Other.svg';
import { ReactComponent as TorBrowser } from '../assets/browser-icons/Tor.svg';
import { ReactComponent as VivaldiBrowser } from '../assets/browser-icons/Vivaldi.svg';
import { ReactComponent as WaterfoxBrowser } from '../assets/browser-icons/Waterfox.svg';
import { ReactComponent as YandexBrowser } from '../assets/browser-icons/Yandex.svg';
import { ReactComponent as SamsungBrowser } from '../assets/browser-icons/Samsung.svg';

export const BROWSER_ICONS = {
    Aloha: AlohaBrowser,
    Chrome: GoogleChromeBrowser,
    Chromium: ChromiumBrowser,
    MobileSafari: SafariBrowser,
    Safari: SafariBrowser,
    Edge: ExplorerBrowser,
    Firefox: FirefoxBrowser,
    Waterfor: WaterfoxBrowser,
    ChromeWebView: ChromiumBrowser,
    Opera: OperaBrowser,
    Brave: BraveBrowser,
    Other: OtherBrowser,
    Tor: TorBrowser,
    Yandex: YandexBrowser,
    Vivaldi: VivaldiBrowser,
    OperaGX: OperaGXBrowser,
    Bing: BingBrowser,
    DuckDuckGo: DuckDuckGoBrowser,
    Maxthron: MaxthronBrowser,
    Samsung: SamsungBrowser
};

export const OS_ICONS = {
    iOS,
    MacOS,
    Windows: WindowsOS,
    ChromiumOS,
    Android: AndroidOS,
    GoogleChrome: GoogleChromeOS,
    Linux: LinuxOS,
    Other: OtherOS,
    Samsung: SamsungOS
};
import React, { PureComponent } from 'react';
import styles from './Notifications.module.scss';

class Notifications extends PureComponent {
  render() {
    return (
      <div className={styles.content}>
        <h1>Notifications</h1>
      </div>
    );
  }
}

export default Notifications;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import User from '../../redux/actions/User';
import Account from '../../redux/actions/Account';
import Users from '../../api/Users';
import styles from './RegisterSteps.module.scss';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import Constants from '../../utils/Constants';
import PaymentForm from './components/StepForms/PaymentForm';
import AccountCreationForm from './components/StepForms/AccountCreationForm';
import PersonalDetailsForm from './components/StepForms/PersonalDetailsForm';
import CompanyDetailsForm from './components/StepForms/CompanyDetailsForm';
import FinishedRegistration from './components/StepForms/FinishedRegistration';

class RegisterSteps extends Component {
  onClickBack = () => {
    this.props.updatedActiveForm(this.props.activeForm - 1);
  };

  onClickInstallPixel = () => {
    this.setState({ toDashboard: true });
  };

  welcomeUser = () => {
    const { auth } = this.props;
    Users.welcomeUser({ email: auth.user.email });
  };

  onClickHomepage = () => {
    return <Redirect to="/" />;
  };

  // Function to handle next step
  handleNext = () => {
    this.props.updatedActiveForm(this.props.activeForm + 1);
  };

  handleStepChange = step => {
    this.props.updatedActiveForm(step);
  };

  render() {
    const {
      activeForm,
      auth,
      accounts,
      updateUser,
      discount,
      couponError,
      updateUserAccount,
      fetchLatestAccount,
      getUserAccounts
    } = this.props;

    // Render step components based on current step
    let stepComponent;
    switch (activeForm) {
      case 0:
        stepComponent = <div className={styles.slideLeft}></div>;
        break;
      case 1:
        stepComponent = (
          <PersonalDetailsForm
            user={auth.user}
            updateUser={updateUser}
            onClickNext={this.handleNext}
          />
        );
        break;
      case 2:
        stepComponent = (
          <CompanyDetailsForm
            user={auth.user}
            onClickBack={this.onClickBack}
            updateUser={updateUser}
            onClickNext={this.handleNext}
          />
        );
        break;
      case 3:
        stepComponent = (
          <AccountCreationForm
            onClickBack={this.onClickBack}
            onClickNext={this.handleNext}
            updateUser={updateUser}
            updateUserAccount={updateUserAccount}
            user={auth.user}
            discount={discount}
            couponError={couponError}
            accounts={accounts}
            currency={auth.user.currency}
            conversionRates={accounts.conversionRates}
          />
        );
        break;
      case 4:
        stepComponent = (
          <StripeProvider apiKey={Constants.stripePublicKey}>
            <Elements>
              <PaymentForm
                onClickNext={this.handleNext}
                onClickBack={this.onClickBack}
                currency={auth.user.currency}
                user={auth.user}
                updateUser={updateUser}
                accounts={accounts}
                discount={discount}
                conversionRates={accounts.conversionRates}
              />
            </Elements>
          </StripeProvider>
        );
        break;
      // Add more cases for additional steps
      default:
        stepComponent = null;
    }

    return activeForm === 5 ? (
      <div className={styles.newRegisterFormSteps}>
        <FinishedRegistration
          accounts={accounts}
          user={auth.user}
          fetchLatestAccount={fetchLatestAccount}
          getUserAccounts={getUserAccounts}
          welcomeUser={this.welcomeUser}
        />
      </div>
    ) : (
      <div className={styles.newRegisterFormSteps}>
        <Breadcrumbs onStepChange={this.handleStepChange} currentStep={activeForm} />
        <div className={styles.stepsContainer}>{stepComponent}</div>
      </div>
    );
  }
}
// Prop type validation
RegisterSteps.propTypes = {
  activeForm: PropTypes.number.isRequired,
  discount: PropTypes.number,
  couponError: PropTypes.string,
  updatedActiveForm: PropTypes.func.isRequired,
  auth: PropTypes.object,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  accounts: PropTypes.object,
  updateUserAccount: PropTypes.func,
  getUserAccounts: PropTypes.func,
  fetchLatestAccount: PropTypes.func,
  activeDomain: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  activeDomain: state.activeDomain
});

const mapDispatchToProps = dispatch => {
  return {
    createUser: data => dispatch(User.createUser(data)),
    updateUser: (id, data) => dispatch(User.updateUser(id, data)),
    updateUserAccount: (accountId, data) => dispatch(Account.updateUserAccount(accountId, data)),
    getUserAccounts: id => dispatch(Account.getUserAccounts(id)),
    fetchLatestAccount: id => dispatch(Account.fetchLatestAccount(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSteps);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import styles from './UpgradePlanDeclineModal.module.scss';
import Decline from '../../assets/decline.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    overflow: 'auto',
    padding: '40px 0'
  },
  content: {
    top: 20,
    right: 0,
    left: 0,
    bottom: 0,
    width: 577,
    height: 'auto',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    padding: '75px',
    position: 'relative'
  }
};

class UpgradePlanDeclineModal extends PureComponent {
  handleCloseModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  };

  goToDashboard = () => {
    const { history } = this.props;
    history.push('/dashboard');
  };

  render() {
    const { isOpen = [] } = this.props;
    return (
      <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} contentLabel="Compare Plans">
        <div className={styles.container}>
          <span className={styles.closeBtn} onClick={this.handleCloseModal} aria-hidden="true">
            ×
          </span>
          <div className={styles.content}>
            <div className={styles.headerText}>
              <div className={styles.imgSec}>
                <img src={Decline} className={styles.icon} />
              </div>
              <div className={styles.textSecHead}>
                We’re sorry!
                <span>Something went wrong and your adjustment couldn’t be completed.</span>
              </div>
            </div>
            <div className={styles.btnWrapper}>
              <Button
                onClick={() => this.goToDashboard()}
                title="Back To Dashboard"
                color="lt-blue"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

UpgradePlanDeclineModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  history: PropTypes.any
};

export default UpgradePlanDeclineModal;

import React from 'react';
import PropTypes from 'prop-types';
import { AddressElement } from '@stripe/react-stripe-js';

const AddressForm = props => {
  return (
    <AddressElement
      options={{
        mode: 'billing',
        defaultValues: props.values
      }}
      onChange={props.onAddressChange}
    />
  );
};

AddressForm.propTypes = {
  values: PropTypes.object,
  onAddressChange: PropTypes.func
};

export default AddressForm;

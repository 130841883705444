import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './InputContainer.module.scss';

class InputContainer extends PureComponent {
  render() {
    const { containerStyle, labelStyle, label, children } = this.props;
    return (
      <div style={containerStyle} className={styles.container}>
        {label && (
          <p className={styles.label} style={labelStyle}>
            {label}
          </p>
        )}
        {children}
      </div>
    );
  }
}

InputContainer.propTypes = {
  containerStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.node
};

export default InputContainer;

import React from 'react';
// import QUOTES from '../../assets/techtimesgoogle.svg';
import STARS from '../../../../assets/stars-new.svg';
import GOOGLEAPI from '../../../../assets/g-api.svg';
import FACEBOOK from '../../../../assets/fb-icon.svg';
import INSTAGRAM from '../../../../assets/insta-icon.svg';
import TECHTIMES from '../../../../assets/tech-times.svg';
import TESTIMONIAL from '../../../../assets/testimonial.png';
import TWO_FOUR from '../../../../assets/247.svg';
import CODE from '../../../../assets/ez.svg';
import styles from './RegisterTestimonial.module.scss';

const RegisterTestimonial = () => {
  return (
    <div className={styles.registerTestimonialContainer}>
      <div className={styles.registerTestimonialContent}>
        <div className={styles.testimonialContainer}>
          <div className={styles.testimonialLogo}>
            <img src={TESTIMONIAL} alt="testimonial" />
          </div>
          <div>
            <div className={styles.stars}>
              <img src={STARS} alt="stars" />
            </div>
            <p className={styles.testimonialText}>
              “They greatly reduced my ad fraud and improved my campaign performance. Highly
              recommend!”
            </p>
            <p className={styles.testimonialAuthor}>Matthew C., Sr. Marketing Manager</p>
          </div>
        </div>
        <div className={styles.techSec}>
          <div className={styles.techImg}>
            <img src={TECHTIMES} alt="tech times" />
          </div>
          <div className={styles.apiApp}>
            <div>
              <img src={GOOGLEAPI} alt="google api" />
              <img src={FACEBOOK} alt="facebook" />
              <img src={INSTAGRAM} alt="instagram" />
            </div>
            <div className={styles.gApi}>Google & Meta API approved</div>
          </div>
        </div>
        <div className={styles.checkFeatures}>
          <img src={TWO_FOUR} className={styles.icon} />
          <div>
            <div className={styles.checkTitle}>24/7 Account Support</div>
            <div className={styles.checkText}>Email, call or chat with our team anytime</div>
          </div>
        </div>
        <div className={styles.checkFeatures}>
          <img src={CODE} className={styles.icon} />
          <div>
            <div className={styles.checkTitle}>Easy Installation - No developer needed</div>
            <div className={styles.checkText}>
              Install in minutes and start blocking fraud today
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterTestimonial;

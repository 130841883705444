import React, { PureComponent } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { JsonToCsv } from 'react-json-csv';
import styles from './Advertising.module.scss';
import ReportsTableNew, { getPrimaryFraudType, getStatus } from '../ResultTable/ReportsTableNew';
import AdSelector from '../../components/AdSelector/AdSelector';
import Data from '../../api/Data';
import Utils from '../../utils/Utils';
import DatesSelector from '../DatesSelector/DatesSelector';
import GoogleAds from '../../api/GoogleAds';
import IPBlockList from '../../redux/actions/IpBlockList';
// import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import NoteIcon from '../../assets/note-icon.png';

/**
 * Currently has the same functionality of Stats Component - If that doesnt change with data
 * May be beneficial to combine logic into one component - ResultTable that both share in common
 */
class Advertising extends PureComponent {
  state = {
    records: [],
    fetchingData: true,
    errors: {},
    startDate: null,
    adType: 'all',
    endDate: null,
    csv: {
      fields: {
        ip: 'IP Address',
        status: 'Status',
        riskScore: 'Fraud Score',
        primaryFraudType: 'Primary Fraud Type',
        trafficSource: 'Traffic Source',
        lastSeen: 'Last Seen',
        firstClicks: 'First Click',
        lastClicks: 'Last Click',
        clicks: 'Total Clicks',
        country: 'Country',
        os: 'Operating System',
        device: 'Device',
        gclid: 'Click ID'
      },
      data: []
    }
  };

  componentDidMount = async () => {
    const {
      ipBlocklist,
      ipWhitelist
      // accounts
    } = this.props;
    try {
      await this.fetchData({
        startDate:
          localStorage.getItem('start_date') ||
          moment()
            .subtract(3, 'days')
            .format('YYYYMMDD'),
        endDate: localStorage.getItem('end_date') || moment().format('YYYYMMDD')
      });
      if (ipBlocklist.length === 0 || ipWhitelist.length === 0) {
        await this.fetchBlockedIPs();
      }
      this.setState({ fetchingData: false });
    } catch (error) {
      this.setState({ fetchingData: false });
    }
  };

  componentDidUpdate = async preProps => {
    const {
      ipBlocklist,
      ipWhitelist
      // accounts
    } = this.props;
    if (
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      preProps.activeDomain &&
      preProps.activeDomain.data &&
      preProps.activeDomain.data.id !== this.props.activeDomain.data.id
    ) {
      try {
        await this.fetchData({
          startDate:
            localStorage.getItem('start_date') ||
            moment()
              .subtract(3, 'days')
              .format('YYYYMMDD'),
          endDate: localStorage.getItem('end_date') || moment().format('YYYYMMDD')
        });
        if (ipBlocklist.length === 0 || ipWhitelist.length === 0) {
          await this.fetchBlockedIPs();
        }
        this.setState({ fetchingData: false });
      } catch (error) {
        this.setState({ fetchingData: false });
      }
    }
  };

  setCSVData = data => {
    const { ipBlocklist, ipWhitelist } = this.props;
    const formatted = [];
    const listedIPs = ipBlocklist.concat(ipWhitelist);
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      formatted.push({
        ...item,
        lastSeen: item.lastSeen.value,
        primaryFraudType: getPrimaryFraudType(item),
        status: getStatus(
          listedIPs.find(ip => ip.address.includes(item.ip)),
          item.riskScore
        )
      });
    }
    this.setState({ csv: { ...this.state.csv, data: formatted } });
  };

  /**
   * Fetch Data for Result Table
   * @param {Object} body {
   * startDate: 'YYYYMMDD',
   * endDate: 'YYYYMMDD'
   * }
   */
  fetchData = async body => {
    try {
      if (
        !this.props.activeDomain ||
        !this.props.activeDomain.data ||
        !this.props.activeDomain.data.id
      ) {
        return;
      }
      const { timezone } = this.props.auth.user;
      this.setState({
        fetchingData: true,
        startDate: body.startDate || this.state.startDate,
        endDate: body.endDate || this.state.endDate,
        adType: body.adType || this.state.adType
      });
      const result = await Data.getReports({
        startDate: moment(body.startDate || this.state.startDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        endDate: moment(body.endDate || this.state.endDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        sid: this.props.activeDomain.data.id,
        isAggressive: this.props.activeDomain.data.aggressive_blocking,
        timezone: Utils.sanitizeTimezoneString(timezone || '(GMT-07:00) America/Los_Angeles'),
        adType:
          (body.adType || this.state.adType) === 'all'
            ? undefined
            : body.adType || this.state.adType
      });
      if (result && !result.errno) {
        if (result.length) {
          for (let i = 0; i < result.length; i += 1) {
            result[i].index = i;
          }
        }
        const parsedResults = Utils.formatTimeAndAddRowIdInReports(
          result,
          timezone || '(GMT-07:00) America/Los_Angeles'
        );
        this.setState({ records: parsedResults, errors: {} });
        this.setCSVData(parsedResults);
      } else {
        this.setState({ records: [], errors: {} });
        this.setCSVData([]);
      }
      this.setState({ fetchingData: false });
    } catch (error) {
      console.log(error);
      this.setState({ errors: error });
      this.setState({ fetchingData: false });
    }
  };

  fetchBlockedIPs = async () => {
    const { activeDomain, fetchLatestBlocklist } = this.props;
    if (!activeDomain || !activeDomain.data || !activeDomain.data.id) {
      return;
    }
    try {
      await fetchLatestBlocklist(activeDomain.data.id);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  onStatusChange = async (index, currentStatus) => {
    const { records } = this.state;
    const { ipBlocklist, ipWhitelist } = this.props;
    const listedIp = ipBlocklist.find(ipBlock => ipBlock.address.includes(records[index].ip));
    const listedWhite = ipWhitelist.find(ipBlock => ipBlock.address.includes(records[index].ip));

    if (currentStatus === 'Auto Blocked') {
      this.autoBlockIp(records[index].ip, false);
    } else if (currentStatus === 'Unblocked' && !listedWhite) {
      this.autoBlockIp(records[index].ip, true);
    } else if (currentStatus === 'Unblocked' && listedWhite) {
      await this.removeIPFromBlockList(records[index].ip, false);
      this.autoBlockIp(records[index].ip, true);
    } else if (!listedIp) {
      this.autoBlockIp(records[index].ip, true);
    } else {
      this.removeIPFromBlockList(records[index].ip, true);
    }
  };

  /**
   * Add Ip Address to all campaigns blocklist linked with user's google ads account
   */
  autoBlockIp = async (ipAddress, isBlocked = true) => {
    const { accounts, activeDomain } = this.props;
    try {
      const data = {
        account_id: accounts.data.id,
        address: ipAddress,
        is_blocked: isBlocked,
        domain_id: activeDomain.data.id
      };
      await GoogleAds.addIpToBlocklist({ ips: [data] });
      // fetch latest
      this.fetchBlockedIPs();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Remove Ip Address from all campaigns linked with User's google ads account blocklists
   */
  removeIPFromBlockList = async (ipAddress, isBlocked) => {
    const { ipBlocklist, ipWhitelist } = this.props;
    try {
      let selectedIp = null;
      if (isBlocked) {
        selectedIp = ipBlocklist.find(ipBlock => ipBlock.address === ipAddress);
      } else {
        selectedIp = ipWhitelist.find(ipBlock => ipBlock.address === ipAddress);
      }
      await GoogleAds.removeIpFromBlocklist({ ids: [selectedIp.id] });
      // fetch latest
      this.fetchBlockedIPs();
    } catch (error) {
      console.log(error);
    }
  };

  downloadResults = () => {};

  render() {
    const { ipBlocklist, accounts, ipWhitelist, activeDomain } = this.props;
    const { fetchingData, records } = this.state;
    return (
      <div className={styles.content}>
        <div className={styles.header}>
          <h1 className={styles.title}>Reports</h1>
        </div>
        <div className={styles.topFiltersWrap}>
          <AdSelector showAll={true} handleAdChange={this.fetchData} />
          <DatesSelector handleDateChange={this.fetchData} />
        </div>
        <div className={styles.info}>
          <div>This report shows the activity for each of the IPs visiting your website.</div>
          <div>
            <img src={NoteIcon} alt="alert" />
            IPs from sources other than Google Ads and Meta Ads <strong>are not</strong> Auto
            Blocked or counted towards your <Link to="/stats">Fraud Score</Link>.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.fraudblocker.com/en/articles/6732598-how-is-my-fraud-score-calculated"
            >
              Learn More
            </a>
            .
          </div>
        </div>

        {fetchingData ? (
          <div className={styles.detailsContainer}>
            <div className={styles.tableStatsLoading}>
              <div className={styles.numbers}>
                <div className={styles.results} />
                <div className={styles.downloads} />
              </div>
              <div className={styles.table} />
              <div className={styles.footer} />
            </div>
          </div>
        ) : (
          <div className={styles.detailsContainer}>
            <div className={styles.detailsContainerHeader}>
              <p className={styles.containerTitle}>Details</p>
              {/* {auth.user.role !== 'Viewer' && (
                <JsonToCsv
                  data={csv.data}
                  filename="reports"
                  fields={csv.fields}
                  style={{ background: 'none', border: 'none' }}
                  text={
                    <div className={styles.download}>
                      <DownloadIcon className={styles.downloadIcon} />
                      Download Results
                    </div>
                  }
                />
              )} */}
            </div>

            <ReportsTableNew
              results={records || []}
              loading={fetchingData}
              ipBlocklist={ipBlocklist.concat(ipWhitelist)}
              maxHeight={700}
              onStatusChange={this.onStatusChange}
              accounts={accounts}
              activeDomain={activeDomain}
            />
          </div>
        )}
      </div>
    );
  }
}

Advertising.propTypes = {
  auth: PropTypes.object,
  accounts: PropTypes.object,
  ipBlocklist: PropTypes.array,
  ipWhitelist: PropTypes.array,
  fetchLatestBlocklist: PropTypes.func,
  activeDomain: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  accounts: state.accounts,
  activeDomain: state.activeDomain,
  ipBlocklist: state.ipBlocklist.data,
  ipWhitelist: state.ipBlocklist.whiteIPs
});

const mapDispatchToProps = dispatch => {
  return {
    fetchLatestBlocklist: accountId => dispatch(IPBlockList.fetchLatestBlocklist(accountId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Advertising);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './Domain.module.scss';
import AddDomainModal from '../../containers/AddDomainModal/AddDomainModal';
import AnalyticsDashboard from '../../containers/Dashboard/Dashboard';

class Domain extends PureComponent {
  render() {
    const { location } = this.props;
    const showModal = location.state ? location.state.modal : false;
    return (
      <div className={styles.content}>
        <AddDomainModal isOpen={showModal} />
        <AnalyticsDashboard />
      </div>
    );
  }
}

Domain.propTypes = {
  showModal: PropTypes.bool,
  location: PropTypes.object.isRequired
};

Domain.defaultProps = {
  showModal: false
};

export default Domain;

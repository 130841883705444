import React, { PureComponent } from 'react';
import { parseDomain, fromUrl } from 'parse-domain';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import styles from '../../RegisterSteps.module.scss';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import Validation from '../../../../utils/Validation';
import User from '../../../../redux/actions/User';
import UserApi from '../../../../api/Users';
import { ReactComponent as MAINLOGO } from '../../../../assets/main-logo.svg';
import { ReactComponent as UnlockDiscountIcon } from '../../../../assets/unlock-discount.svg';
import { ReactComponent as TooltipIcon } from '../../../../assets/tooltip.svg';
import ErrorBox from '../../../../components/ErrorBox/ErrorBox';

const customStyles = {
  input: {
    marginBottom: 25
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: '#666666'
  }
};

class SignUpForm extends PureComponent {
  state = {
    domain: '',
    toSignIn: false,
    password: '',
    errors: {},
    loading: false
  };

  onChangeText = event => {
    const { value, name } = event.target;
    const { setEmail } = this.props;

    if (name === 'email') {
      setEmail(value);
      return;
    }

    this.setState({ [name]: value });
  };

  componentDidMount = () => {
    // window.scrollTo(0, 0);
    ReactTooltip.rebuild();
  };

  onClickNext = async () => {
    this.setState({ loading: true });
    const { domain, password } = this.state;
    const { onClickNext, createUser, email } = this.props;
    const data = {
      domain,
      email,
      password
    };

    const newErrors = Validation.validateForm(data);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }

    try {
      // let domainWithProtocol = data.domain;
      // if (!domainWithProtocol.includes('http')) {
      //   domainWithProtocol = `http://${domainWithProtocol}`;
      // }
      // const domainResponse = await UserApi.checkIfDomainActive(domainWithProtocol);
      // if (!domainResponse) {
      //   throw new Error('Error creating user account');
      // }
      const emailValidateResponse = await UserApi.validateEmail(data.email);
      console.log(emailValidateResponse);
      const result = await User.createUserWithEmailAndPassword(data.email, data.password);
      if (result) {
        const parseResult = parseDomain(fromUrl(data.domain));
        let parsedDomain = `${parseResult.icann.domain}.${parseResult.icann.topLevelDomains.join(
          '.'
        )}`;
        if (parseResult.icann.subDomains && parseResult.icann.subDomains.length) {
          const subDomains = parseResult.icann.subDomains.filter(
            name => name.toLocaleLowerCase() !== 'www'
          );
          if (subDomains.length) {
            parsedDomain = `${subDomains.join('.')}.${parsedDomain}`;
          }
        }
        const userData = {
          domain: parsedDomain,
          email: data.email,
          id: result.user.uid
        };

        const createUserInDBResponse = await createUser(userData);
        if (createUserInDBResponse) {
          onClickNext(userData);
        } else {
          throw new Error('Error creating user account');
        }
      } else {
        throw new Error('Error creating user account');
      }
    } catch (error) {
      console.log(error);
      this.setState({ errors: { signUp: error.message }, loading: false });
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onClickNext();
    }
  };

  onClickAlreadyHaveAccount = () => {
    this.setState({ toSignIn: true });
  };

  /** TODO:
   * Add Validation for domain */

  render() {
    const { domain, password, errors, loading } = this.state;
    const { email, discount } = this.props;

    if (this.state.toSignIn === true) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={styles.newRegisterFormSteps}>
        <div className={styles.formContainer}>
          <ReactTooltip className={styles.tooltip} id="moreWebsites">
            You can add more websites or change them later
          </ReactTooltip>
          <div className={styles.logoImg}>
            <MAINLOGO />
          </div>
          <h1 className={`${styles.headerText} ${styles.signupTitle}`}>Create your free account</h1>
          <p className={styles.alreadyHaveAccount}>
            Already have an account?{' '}
            <span onClick={this.onClickAlreadyHaveAccount} className={styles.signInNowText}>
              Sign in now
            </span>
          </p>
          {discount !== 0 && (
            <div className={styles.discountApplied}>
              <UnlockDiscountIcon />
              {discount}% discount applied!
            </div>
          )}
          <Input
            name="domain"
            value={domain}
            onChange={this.onChangeText}
            className={styles.inputContainer}
            labelStyle={customStyles.inputLabel}
            containerStyle={customStyles.input}
            label={
              <span>
                Website to Protect{' '}
                <TooltipIcon className={styles.registerHelpTip} data-tip data-for="moreWebsites" />
              </span>
            }
            placeholder="example.com"
            error={errors.domain || null}
          />
          <Input
            name="email"
            value={email}
            onChange={this.onChangeText}
            onKeyPress={this.handleKeyPress}
            labelStyle={customStyles.inputLabel}
            containerStyle={customStyles.input}
            label="Email"
            placeholder="joanna@example.com"
            error={errors.email || null}
          />
          <Input
            name="password"
            value={password}
            type="password"
            onChange={this.onChangeText}
            onKeyPress={this.handleKeyPress}
            labelStyle={customStyles.inputLabel}
            label="Password"
            showEye
            error={errors.password || null}
          />
          <p className={styles.passwordInfo}>
            Your password must be at least 8 characters. We recommend at least 1 lowercase, 1
            uppercase, and 1 number.
          </p>
          {errors.signUp && <ErrorBox error={errors.signUp} />}
          <div className={styles.formFooterContainer}>
            <Button
              title="Next"
              onClick={this.onClickNext}
              style={customStyles.nextBtn}
              customClassNames="signUpForm__nextBtn"
              loading={loading}
              color="new-green"
            />
            <p>
              By clicking this button you agree to Fraud Blocker&apos;s{' '}
              <a
                href="https://fraudblocker.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className={`blue ${styles.link}`}
              >
                Terms of Service
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  onClickNext: PropTypes.func,
  createUser: PropTypes.func,
  setEmail: PropTypes.func,
  email: PropTypes.string,
  discount: PropTypes.number
};

export default SignUpForm;

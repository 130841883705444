import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Customizations.module.scss';
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import Switch from '../../components/Switch/Switch';
import ActiveDomain from '../../redux/actions/ActiveDomain';
import Constants from '../../utils/Constants';
import ArrowRight from '../../assets/dropdown-arrow.svg';
import OrbitIcon from '../../assets/orbit.svg';
import TargetIcon from '../../assets/target.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import SuccessBox from '../../components/SuccessBox/SuccessBox';

const customStyles = {
  input: {
    width: 100,
    height: 36,
    marginLeft: 15,
    marginRight: 15
  },
  dropdown: {
    width: 100,
    height: 36
  },
  addThresholdBtn: {
    minWidth: 140,
    marginRight: 15,
    border: 'none',
    fontWeight: 'normal',
    color: '#286cff',
    marginBottom: '20px'
  },
  saveThresholdsBtn: {
    height: '41px'
  },
  deleteBtn: {
    minWidth: 0,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer'
  },
  saved: {
    maxWidth: '200px',
    marginLeft: '10px',
    fontWeight: '600',
    padding: '10px 15px',
    marginTop: 0,
    marginBottom: 0,
  }
};

const { countryNameMapping } = Constants;

const dropdownOptions = [
  { value: '5m', label: '5 minutes' },
  { value: '1h', label: '1 hour' },
  { value: '24h', label: '1 day' }
];

const countryOptions = Object.entries(countryNameMapping)
  .map(([key, val]) => ({
    label: val,
    value: key,
    icon: `/flags/${key.toLowerCase()}.svg`
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

class DetectionRules extends PureComponent {
  state = {
    clickFraudThresholds: { '5m': '' },
    detectIPs: true,
    vpnBlocking: true,
    monitorOnlyMode: false,
    aggressiveBlocking: false,
    blockAccidental: true,
    abusiveIPs: true,
    saved: false,
    removeThresholdClass: false,
    isSaveClicked: false,
    isThresholdOpen: false,
    isGeoBlockingOpen: false,
    geoToggleState: 'allowed',
    geoStateSaveClicked: false,
    allowedCountries: [],
    blockedCountries: []
  };

  setStateData = () => {
    const {
      activeDomain: {
        data: {
          click_fraud_thresholds: clickFraudThresholds,
          detect_ips: detectIPs,
          vpn_blocking: vpnBlocking,
          monitoring_only: monitorOnlyMode,
          aggressive_blocking: aggressiveBlocking,
          block_accidental: blockAccidental,
          abusive_ips: abusiveIPs,
          allowed_countries: allowedCountries,
          blocked_countries: blockedCountries
        }
      }
    } = this.props;
    this.setState({
      clickFraudThresholds:
        !clickFraudThresholds ||
          Array.isArray(clickFraudThresholds) ||
          (!Array.isArray(clickFraudThresholds) &&
            typeof clickFraudThresholds === 'object' &&
            !Object.keys(clickFraudThresholds).length)
          ? { '5m': '' }
          : clickFraudThresholds,
      detectIPs: typeof detectIPs === 'undefined' ? true : detectIPs,
      vpnBlocking: typeof vpnBlocking === 'undefined' ? true : vpnBlocking,
      blockAccidental: typeof blockAccidental === 'undefined' ? true : blockAccidental,
      abusiveIPs: typeof abusiveIPs === 'undefined' || abusiveIPs === null ? true : abusiveIPs,
      allowedCountries: allowedCountries ? allowedCountries.split('_') : [],
      blockedCountries: blockedCountries ? blockedCountries.split('_') : [],
      monitorOnlyMode,
      aggressiveBlocking,
      saved: false,
      removeThresholdClass: false,
      isSaveClicked: false,
      geoStateSaveClicked: false,
      geoToggleState: blockedCountries ? 'blocked' : 'allowed'
    });
  };

  componentDidMount = () => {
    if (this.props.activeDomain && this.props.activeDomain.data) {
      this.setStateData();
    }
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.activeDomain &&
      prevProps.activeDomain.isUpdating &&
      !this.props.activeDomain.isUpdating &&
      !this.props.activeDomain.error &&
      !this.state.saved
    ) {
      this.setState({ saved: true });
    }
    if (
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      prevProps.activeDomain &&
      prevProps.activeDomain.data &&
      prevProps.activeDomain.data.id !== this.props.activeDomain.data.id
    ) {
      this.setStateData();
    }
  };

  onInputChange = (value, key) => {
    const { clickFraudThresholds } = this.state;

    const newClickThresholds = { ...clickFraudThresholds };
    newClickThresholds[key] = value;

    this.setState({ clickFraudThresholds: newClickThresholds });
  };

  onOptionChange = (selected, key) => {
    const { clickFraudThresholds } = this.state;
    if (clickFraudThresholds[selected.value]) {
      return;
    }
    const newClickThresholds = { ...clickFraudThresholds };
    delete newClickThresholds[key];
    newClickThresholds[selected.value] = '';

    this.setState({ clickFraudThresholds: newClickThresholds });
  };

  onSwitchChange = name => {
    if (!this.props.accounts.subscriptionValid) {
      return;
    }
    this.setState(
      {
        [name]: !this.state[name],
        isSaveClicked: false
      },
      () => {
        if (this.props.activeDomain.data.id) {
          this.props.updateDomain(
            this.props.activeDomain.data.id,
            {
              id: this.props.activeDomain.data.id,
              detect_ips: this.state.detectIPs,
              vpn_blocking: this.state.vpnBlocking,
              monitoring_only: this.state.monitorOnlyMode,
              aggressive_blocking: this.state.aggressiveBlocking,
              block_accidental: this.state.blockAccidental,
              abusive_ips: this.state.abusiveIPs
            },
            true
          );
        }
      }
    );
  };

  onAddThresholdClick = () => {
    const { clickFraudThresholds } = this.state;
    const newClickThresholds = { ...clickFraudThresholds };
    if (newClickThresholds['5m'] === undefined) {
      newClickThresholds['5m'] = '';
    } else if (newClickThresholds['1h'] === undefined) {
      newClickThresholds['1h'] = '';
    } else if (newClickThresholds['5d'] === undefined) {
      newClickThresholds['24h'] = '';
    }
    this.setState({ clickFraudThresholds: newClickThresholds });
  };

  onRemoveThreshold = key => {
    this.setState({ removeThresholdClass: key });
    setTimeout(() => {
      const { clickFraudThresholds } = this.state;
      const newClickThresholds = { ...clickFraudThresholds };
      delete newClickThresholds[key];
      this.setState({ clickFraudThresholds: newClickThresholds, removeThresholdClass: '' });
    }, 200);
  };

  isSaveDisabled = () => {
    return Object.values(this.state.clickFraudThresholds).some(value => !value);
  };

  onSaveThresholds = () => {
    if (this.isSaveDisabled()) {
      return;
    }
    this.setState({ saved: false, isSaveClicked: true });
    if (this.props.activeDomain.data.id) {
      this.props.updateDomain(this.props.activeDomain.data.id, {
        id: this.props.activeDomain.data.id,
        click_fraud_thresholds: this.state.clickFraudThresholds
      });
    }
  };

  handleSaveGeoBlocking = () => {
    this.setState({ geoStateSaveClicked: true, saved: false });
    if (this.props.activeDomain.data.id) {
      this.props.updateDomain(this.props.activeDomain.data.id, {
        id: this.props.activeDomain.data.id,
        allowed_countries: this.state.allowedCountries.length
          ? this.state.allowedCountries.join('_')
          : null,
        blocked_countries: this.state.blockedCountries.length
          ? this.state.blockedCountries.join('_')
          : null
      });
    }
  };

  onGeoStateToggleChange = event => {
    this.setState({
      geoToggleState: event.target.value
    });
  };

  handleCountrySelect = selected => {
    if (this.state.geoToggleState === 'allowed') {
      this.setState(preState => ({
        allowedCountries: preState.allowedCountries.includes(selected.value)
          ? preState.allowedCountries
          : [...preState.allowedCountries, selected.value]
      }));
    }
    if (this.state.geoToggleState === 'blocked') {
      this.setState(preState => ({
        blockedCountries: preState.blockedCountries.includes(selected.value)
          ? preState.blockedCountries
          : [...preState.blockedCountries, selected.value]
      }));
    }
  };

  handleCountryRemove = country => {
    if (this.state.geoToggleState === 'allowed') {
      this.setState(preState => ({
        allowedCountries: preState.allowedCountries.filter(item => item !== country)
      }));
    }
    if (this.state.geoToggleState === 'blocked') {
      this.setState(preState => ({
        blockedCountries: preState.blockedCountries.filter(item => item !== country)
      }));
    }
  };

  render() {
    const {
      clickFraudThresholds,
      vpnBlocking,
      monitorOnlyMode,
      aggressiveBlocking,
      saved,
      removeThresholdClass,
      isSaveClicked,
      blockAccidental,
      abusiveIPs,
      isThresholdOpen,
      isGeoBlockingOpen,
      geoToggleState,
      allowedCountries,
      blockedCountries,
      geoStateSaveClicked
    } = this.state;

    const isMaxThresholdAdded =
      clickFraudThresholds['5m'] && clickFraudThresholds['1h'] && clickFraudThresholds['24h'];

    return (
      <div className={styles.content}>
        <h1 className={styles.title}>Manage Detection Rules</h1>
        <h2 className={styles.sectionHead}>
          <img alt="target" src={TargetIcon} />
          TARGETED ADJUSTMENTS
        </h2>
        <h3
          style={{ cursor: 'pointer' }}
          onClick={() =>
            this.setState(prevState => ({
              isGeoBlockingOpen: !prevState.isGeoBlockingOpen
            }))
          }
        >
          Geo-Blocking By Country
        </h3>
        <p
          style={{ cursor: 'pointer' }}
          onClick={() =>
            this.setState(prevState => ({
              isGeoBlockingOpen: !prevState.isGeoBlockingOpen
            }))
          }
          className={`${styles.fraudThresholdDesc} ${styles.switchOptionContainer}`}
        >
          This feature allows you to determine which countries you would like to allow or block from
          your ad campaigns.
          <img
            className={`${styles.expandIcon} ${!isGeoBlockingOpen && styles.expandIconClosed}`}
            src={ArrowRight}
          />
        </p>
        <div
          className={`${styles.thresholdWrapperHidden} ${styles.extraGutter} ${isGeoBlockingOpen ? styles.active : ''
            }`}
        >
          <div className={`${styles.switchOptionContainer} ${styles.geoBlocking}`}>
            <div style={{ width: '100%' }}>
              <div className={styles.geoBlockRadio}>
                <div className={styles.heading}>
                  <div className={styles.radioItem}>
                    <input
                      type="radio"
                      name="geoBlocking"
                      value="blocked"
                      id="radio_geo_blocked"
                      onChange={this.onGeoStateToggleChange}
                      defaultChecked={geoToggleState === 'blocked'}
                      checked={geoToggleState === 'blocked'}
                    />
                  </div>
                  <strong>Block</strong> all ad clicks coming from the countries listed below, or
                </div>
                <div className={styles.heading}>
                  <div className={styles.radioItem}>
                    <input
                      type="radio"
                      name="geoBlocking"
                      value="allowed"
                      id="radio_geo_allowed"
                      onChange={this.onGeoStateToggleChange}
                      defaultChecked={geoToggleState === 'allowed'}
                      checked={geoToggleState === 'allowed'}
                    />
                  </div>
                  <strong>Allow</strong> ad clicks coming from the countries listed below and block
                  all others
                </div>
              </div>
              <div className={styles.geoBlockDesc}>
                <div className={styles.countryDropdown}>
                  <Dropdown
                    options={countryOptions}
                    selectClass={styles.userDomainDropdown}
                    value={null}
                    name="duration"
                    onOptionChange={this.handleCountrySelect}
                    placeholder="Select a country"
                  />
                </div>
                <ul className={styles.selectedCountryList}>
                  {geoToggleState === 'blocked'
                    ? blockedCountries.map(item => (
                      <li key={item}>
                        <span>{countryNameMapping[item]}</span>
                        <span
                          onClick={() => this.handleCountryRemove(item)}
                          className={styles.closeBtn}
                        >
                          X
                        </span>
                      </li>
                    ))
                    : allowedCountries.map(item => (
                      <li key={item}>
                        <span>{countryNameMapping[item]}</span>
                        <span
                          onClick={() => this.handleCountryRemove(item)}
                          className={styles.closeBtn}
                        >
                          X
                        </span>
                      </li>
                    ))}
                </ul>
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={this.handleSaveGeoBlocking}
                    title="Save & Close"
                    color="lt-blue"
                    style={customStyles.saveThresholdsBtn}
                    loading={!this.props.activeDomain || this.props.activeDomain.isUpdating}
                  />
                  {geoStateSaveClicked && saved && (
                    <SuccessBox override={true} style={customStyles.saved} message="✓ Saved successfully" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>VPN Blocking</h3>
        <div className={styles.switchOptionContainer}>
          <p className={styles.switchLabel}>
            When this is enabled, Fraud Blocker will block any IP addresses that originate from a
            VPN (Virtual Private Network) to click your ads. VPNs are often used to hide illicit
            activity. <span className={styles.recommended}>Recommendation: Enabled</span>.
          </p>
          <Switch onChange={this.onSwitchChange} name="vpnBlocking" checked={vpnBlocking} />
        </div>
        <h3>Accidental Clicks</h3>
        <div className={styles.switchOptionContainer}>
          <p className={styles.switchLabel}>
            When this is enabled, Fraud Blocker will block users that click your ad and then leave
            your site in less than 2 seconds, often before your website even fully loads.{' '}
            <span className={styles.recommended}>Recommendation: Enabled</span>.
          </p>
          <Switch onChange={this.onSwitchChange} name="blockAccidental" checked={blockAccidental} />
        </div>
        <h3
          onClick={() =>
            this.setState(prevState => ({
              isThresholdOpen: !prevState.isThresholdOpen
            }))
          }
          style={{ cursor: 'pointer' }}
          className={styles.subTitle}
        >
          Click Fraud Thresholds
        </h3>
        <p
          style={{ cursor: 'pointer' }}
          onClick={() =>
            this.setState(prevState => ({
              isThresholdOpen: !prevState.isThresholdOpen
            }))
          }
          className={styles.fraudThresholdDesc}
        >
          Our system helps detect fraud based on a user’s click frequency (among other detection
          techniques). However, if you’d like to override our system and add your own rules, you may
          do that here.
          <img
            className={`${styles.expandIcon} ${!isThresholdOpen && styles.expandIconClosed}`}
            src={ArrowRight}
          />
        </p>
        <div className={`${styles.thresholdWrapperHidden} ${isThresholdOpen ? styles.active : ''}`}>
          <div className={styles.thresholdWrapper}>
            {Object.entries(clickFraudThresholds).map(([key, threshold], index) => {
              return (
                <div
                  key={index}
                  className={`${styles.thresholdContainer} ${removeThresholdClass === key ? styles.removeThreshold : ''
                    }`}
                >
                  <p>Allow up to </p>
                  <Input
                    index={index}
                    style={customStyles.input}
                    value={threshold}
                    name="clicks"
                    onChange={({ target: { value } }) =>
                      this.onInputChange(value ? parseInt(value, 10) : value, key)
                    }
                  />
                  <p className={styles.adsClickWithin}>ad clicks within </p>
                  <Dropdown
                    options={dropdownOptions}
                    index={key}
                    value={dropdownOptions.find(item => item.value === key)}
                    name="duration"
                    onOptionChange={this.onOptionChange}
                    placeholder="Select"
                    style={customStyles.dropdown}
                  />
                  <p className={styles.deleteAction}>
                    <DeleteIcon
                      index={index}
                      style={customStyles.deleteBtn}
                      onClick={() => this.onRemoveThreshold(key)}
                    />
                  </p>
                </div>
              );
            })}
          </div>
          <div className={styles.thresholdBtnContainer}>
            <Button
              onClick={this.onAddThresholdClick}
              style={customStyles.addThresholdBtn}
              color="outline"
              title="+ Add Threshold"
              disabled={isMaxThresholdAdded}
            />
            <Button
              onClick={this.onSaveThresholds}
              style={customStyles.saveThresholdsBtn}
              color="lt-blue-auto"
              title="Save"
              disabled={this.isSaveDisabled()}
              loading={!this.props.activeDomain || this.props.activeDomain.isUpdating}
            />
            {saved && isSaveClicked && <SuccessBox override={true} style={{ ...customStyles.saved, alignSelf: 'flex-start' }} message="✓ Saved successfully" />}
          </div>
        </div>
        <div className={styles.sectionGap}></div>
        <h2 className={styles.sectionHead}>
          <img alt="target" src={OrbitIcon} />
          LARGE ADJUSTMENTS
        </h2>
        <h3>Aggressive Blocking</h3>
        <div className={styles.switchOptionContainer}>
          <p className={styles.switchLabel}>
            By enabling this feature, Fraud Blocker will reduce its scoring threshold to block
            additional IP sources that are supsected to be fraud (but not confirmed fraud). Be
            aware, this may significantly reduce your overall ad traffic.
          </p>
          <Switch
            onChange={this.onSwitchChange}
            name="aggressiveBlocking"
            checked={aggressiveBlocking}
          />
        </div>
        <h3>Abusive IPs</h3>
        <div className={styles.switchOptionContainer}>
          <p className={styles.switchLabel}>
            When this is enabled, Fraud Blocker will import blacklisted addresses to your Google Ads
            account that are reported globally to be abusive and contain fraudulent. These are
            reported on sites such as firehol. Learn more{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.fraudblocker.com/en/articles/6355256-why-are-there-500-ips-blocked-in-my-google-ads-account"
            >
              here
            </a>
            . <span className={styles.recommended}>Recommendation: Enabled</span>.
          </p>
          <Switch onChange={this.onSwitchChange} name="abusiveIPs" checked={abusiveIPs} />
        </div>
        <h3>Monitoring Only Mode</h3>
        <div className={styles.switchOptionContainer}>
          <p className={styles.switchLabel}>
            When this is enabled, Fraud Blocker will only monitor your click activity. It will no
            longer block any IP addresses and it will remove any IPs in your existing Google Ads IP
            exclusion list. <span className={styles.recommended}>Recommendation: Disabled</span>.
          </p>
          <Switch
            disabled={!this.props.accounts.subscriptionValid}
            onChange={this.onSwitchChange}
            name="monitorOnlyMode"
            checked={monitorOnlyMode}
          />
        </div>
      </div>
    );
  }
}

DetectionRules.propTypes = {
  activeDomain: PropTypes.object,
  updateDomain: PropTypes.func,
  accounts: PropTypes.object
};

const mapStateToProps = state => ({
  activeDomain: state.activeDomain,
  accounts: state.accounts
});

const mapDispatchToProps = dispatch => {
  return {
    updateDomain: (id, payload, noLoader) =>
      dispatch(ActiveDomain.updateDomain(id, payload, noLoader))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetectionRules);

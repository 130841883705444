import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/dropdown-arrow.svg';
import Exclamation from '../../assets/exclamation.png';
import AddDomainSuccessModal from '../AddDomainSuccessModal/AddDomainSuccessModal';
import AddDomainModal from '../AddDomainModal/AddDomainModal';
import styles from './Navigation.module.scss';

class NavItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      domainOptions: [],
      showAddDomainModal: false,
      showDomainSuccessModal: false
    };
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  onClickAddDomain = () => {
    console.log('Add Domain');
    this.setState({ showAddDomainModal: true });
  };

  toggleDomainSuccessModal = () => {
    const { showDomainSuccessModal } = this.state;
    this.setState({ showDomainSuccessModal: !showDomainSuccessModal, showAddDomainModal: false });
  };

  closeDomainCancelModal = () => {
    this.setState({ showAddDomainModal: false });
  };

  handleMenuClick = route => {
    if (route === '/add-domain') {
      this.onClickAddDomain();
    }
  };

  render() {
    const {
      item,
      location,
      isDisabled,
      unread = 0,
      history,
      isExternal,
      activeDomain,
      userRole
    } = this.props;
    const { showAddDomainModal, showDomainSuccessModal } = this.state;
    const isNavActive = location.pathname.includes(item.route);
    const hasOptions = item.options.length > 0;
    const defaultOptionRoute = hasOptions ? item.options[0].route : '/';
    return (
      <div
        className={`${styles.navItem} ${item.isLast ? styles.navBorder : ''} ${isNavActive &&
          styles.navItemActive}`}
      >
        <ReactTooltip id="monitoringWarning">
          &apos;Monitoring only&apos; mode is enabled. Turn off in your Customizations.
        </ReactTooltip>
        {!isExternal ? (
          <Link
            className={`${styles.navItemTitle} ${isDisabled ? styles.disabledForViewer : ''}`}
            to={item.route ? item.route : defaultOptionRoute}
          >
            <item.icon className={styles.icon} />
            <p>{item.title}</p>
            {item.hasSubNav && <img className={styles.rightArrow} src={ArrowRight} />}
            {item.hasBadge && <span className={styles.badge}>{unread}</span>}
          </Link>
        ) : (
          <a
            className={`${styles.navItemTitle} ${isDisabled ? styles.disabledForViewer : ''}`}
            href={item.route ? item.route : defaultOptionRoute}
            target="_blank"
            rel="noopener noreferrer"
          >
            <item.icon className={styles.icon} />
            <p>{item.title}</p>
            {item.hasSubNav && <img className={styles.rightArrow} src={ArrowRight} />}
            {item.hasBadge && <span className={styles.badge}>{unread}</span>}
          </a>
        )}

        {hasOptions && (
          <div className={styles.subNavBlock}>
            {item.options.map(link => {
              const isActive = location.pathname.includes(link.route);
              const isLinkDisabled = !!(
                link.protected &&
                (userRole === 'Viewer' ||
                  (link.blockForRoles && link.blockForRoles.includes(userRole)))
              );
              return (
                <Link
                  className={`${styles.subNavItem} ${isActive ? styles.subNavItemActive : ''} ${isDisabled || isLinkDisabled ? styles.disabledForViewer : ''
                    } `}
                  key={link.route}
                  to={link.route === '/add-domain' ? location.pathname : link.route}
                  onClick={() => this.handleMenuClick(link.route)}
                >
                  {link.text === 'Customizations' &&
                    activeDomain &&
                    activeDomain.data &&
                    activeDomain.data.monitoring_only ? (
                    <span>
                      Customizations{' '}
                      <img
                        data-tip
                        data-for="monitoringWarning"
                        className={styles.monitoringWarning}
                        src={Exclamation}
                        alt="info"
                      />
                    </span>
                  ) : (
                    link.text
                  )}
                  {link.hasSubNav && <img className={styles.rightArrow} src={ArrowRight} />}
                </Link>
              );
            })}
          </div>
        )}

        <AddDomainModal
          onSuccess={this.toggleDomainSuccessModal}
          onCancel={this.closeDomainCancelModal}
          isOpen={showAddDomainModal}
        />
        <AddDomainSuccessModal
          isOpen={showDomainSuccessModal}
          toggleModal={this.toggleDomainSuccessModal}
          history={history}
        />
      </div>
    );
  }
}

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  isDisabled: PropTypes.bool,
  unread: PropTypes.number,
  isExternal: PropTypes.bool,
  activeDomain: PropTypes.object,
  userRole: PropTypes.string
};

NavItem.defaultProps = {
  isDisabled: false,
  unread: 0,
  isExternal: false
};

export default NavItem;
